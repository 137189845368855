/**
 * @param {number} level
 * @returns {number}
 */
export const getDisplayedUserLevel = (level) => level + 1;

export const getFirstLetterFromFullName = (fullName = '') => {
  if (!fullName) {
    return null;
  }

  const [firstName, secondName = ''] = fullName.split(' ');

  return `${firstName.charAt(0).toUpperCase()}${secondName.charAt(0).toUpperCase()}`;
};
