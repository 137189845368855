import React, { useEffect } from 'react';

import { ReactComponent as EmptyIcon } from 'assets/images/icons/empty.svg';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Card, CardBody, CardTitle, Row } from 'reactstrap';

import { getReferrals } from 'store/actions';

import useTranslation from '../../hooks/useTranslation';
import { LEVEL_LABELS } from './enum';
import './sass/notification.scss';

const Referrals = ({ user, referrals, onGetReferrals }) => {
  const userLevel = Number(user.level) + 1;
  const t = useTranslation();

  useEffect(() => {
    onGetReferrals();
  }, [onGetReferrals]);

  const hasReferrals = React.useMemo(() => {
    if (!referrals?.referrals_count.length) {
      return false;
    }

    for (const item of referrals?.referrals_count) {
      if (item.count > 0) {
        return true;
      }
    }

    return false;
  }, [referrals]);

  return (
    <>
      <CardTitle className="mb-0">
        <div className="title">
          <h4 className="dashboard-block-title">{t('dashboard_referrals_title')}</h4>
          <p className="font-size-13 title__count">{t('common_amount')}</p>
        </div>
      </CardTitle>
      <CardBody className="p-0">
        {!hasReferrals && (
          <div className="referrals-zero">
            <div>
              <EmptyIcon />
              <p>{t('dashboard_not_have_referrals')}</p>
            </div>
          </div>
        )}
        {!!userLevel && hasReferrals && (
          <div>
            {referrals?.referrals_count.map(({ level, count }) => {
              const label = t(`referrals_levels.${LEVEL_LABELS[String(level)]}`);
              return (
                <Row className="border-top" key={level}>
                  <div className={level > 0 ? 'referrals' : 'inactive'}>
                    <div className="level-typography">
                      {level !== 0 && <div className="small-level">{level}</div>}
                      <span className="font-weight-600">{label}</span>
                    </div>
                    <span className="active-count">{count}</span>
                  </div>
                </Row>
              );
            })}
            <Row className="border-top">
              <div className="referrals">
                <div className="level-typography">
                  <span className="font-weight-600">{t('common_inactive')}</span>
                </div>
                <span className="active-count">
                  {referrals?.referrals_without_any_deposits_count}
                </span>
              </div>
            </Row>
          </div>
        )}
      </CardBody>
    </>
  );
};

Referrals.propTypes = {
  user: PropTypes.shape({
    level: PropTypes.string,
  }),
  referrals: PropTypes.shape({
    referrals_count: PropTypes.arrayOf(
      PropTypes.shape({
        level: PropTypes.number,
        count: PropTypes.number,
      }),
    ),
    referrals_without_any_deposits_count: PropTypes.number,
  }),
  onGetReferrals: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    referrals: state.Dashboard.referrals,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onGetReferrals: () => dispatch(getReferrals()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Referrals);
