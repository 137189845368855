import { get, post } from 'helpers/api_helper';

export const fetchAccurals = async (payload) => {
  const { pagination } = payload;

  const res = await post(`calculations/${payload.type}`, {
    ...pagination,
    locale: payload?.locale || 'en',
    filters: payload?.filters,
    order_by: payload?.orderBy,
    order: payload?.order,
  });

  if (res.status >= 200 && res.status <= 299) {
    return res.data;
  }
};

export const fetchAccrualsIncome = async () => {
  return await get(`calculations/income-for-period`);
};

export const fetchTotalAccruals = async (payload) => {
  const { pagination } = payload;

  const res = await post(`calculations/accruals`, {
    ...pagination,
    locale: payload?.locale || 'en',
    filters: payload?.filters,
    order_by: payload?.orderBy,
    order: payload?.order,
  });

  if (res.status >= 200 && res.status <= 299) {
    return res.data;
  }
};

export const fetchAccrualsGraphData = async (payload) => {
  return await get(
    `calculations/graphic/accruals?from_year=${payload.fromYear}&${
      payload.toYear ? `to_year=${payload.toYear}` : ''
    }`,
  );
};
