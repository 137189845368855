import React, { useCallback, useEffect, useState } from 'react';

import Logo from 'assets/images/logo-black.png';
import PropTypes from 'prop-types';
import MetaTags from 'react-meta-tags';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Form, Input, Label, Spinner } from 'reactstrap';

import { t } from '../../i18n';
import { resendConfirmCode, sendConfirmCode } from '../../store/users/actions';
import s from './scss/AuthPages.module.scss';
import './scss/email-verification.scss';

const EmailVerification = (props) => {
  const dispatch = useDispatch();
  const [verifyCode, setVerifyCode] = useState('');
  const verifyEmail = localStorage.getItem('verifyEmail');
  const { search } = useLocation();
  const { error, loading } = useSelector((state) => ({
    error: state.Users.confirmedError,
    loading: state.Users.confirmedLoading,
  }));
  const queryParams = new URLSearchParams(search);

  const verificationHandler = useCallback(() => {
    dispatch(
      sendConfirmCode({
        type: 'registration',
        code: verifyCode?.replace('-', ''),
        history: props.history,
        email: verifyEmail,
      }),
    );
  }, [dispatch, verifyCode, props.history, verifyEmail]);

  const resendVerificationCode = useCallback(() => {
    dispatch(
      resendConfirmCode({
        type: 'registration',
        email: verifyEmail,
      }),
    );
  }, [dispatch, verifyEmail]);

  const handleChange = (event) => {
    let value = event.target.value.replace(/\D/g, '');

    if (value.length > 6) {
      value = value.substring(0, 6);
    }

    if (value.length > 3) {
      value = value.substring(0, 3) + '-' + value.substring(3);
    }

    setVerifyCode(value);
  };

  useEffect(() => {
    if (queryParams.get('notConfirmedEmail') === '1') {
      resendVerificationCode();
    }
  }, []);

  return (
    <React.Fragment>
      <MetaTags>
        <title>Проверка Email</title>
      </MetaTags>
      <div className={s.wrapper}>
        <div className={s.formWrapper}>
          <img src={Logo} alt="logo" className={s.logo} />
          <div>
            <h4 className={s.title}>{t('auth_verify_your_email')}</h4>
            <p className={s.subtitle}>
              {t('auth_verify_your_email_text', { address: verifyEmail })}
            </p>
          </div>

          <Form
            className={s.form}
            onSubmit={(e) => {
              e.preventDefault();
              verificationHandler();
            }}>
            {error ? <div className="alert-warning">{`${t(error)}`}</div> : null}

            <div>
              <div className={s.forgotWrapper}>
                <Label className={s.formLabel} htmlFor="password">
                  {`${t('auth_recover_password_confirm_password_code')}`}
                </Label>
                <button className={s.forgotTxt} onClick={resendVerificationCode} type="button">
                  {t('auth_verify_your_email_resend')}
                </button>
              </div>

              <Input
                name="confirmCode"
                className="auth-input"
                placeholder="XXX-XXX"
                type="text"
                onChange={handleChange}
                value={verifyCode}
              />
            </div>

            <div className={s.actions}>
              <button className={s.submitButton} type="submit">
                {loading ? (
                  <div className="d-flex gap-2 justify-content-center align-items-center">
                    <Spinner className="spinner" /> {t('common_complete')}
                  </div>
                ) : (
                  t('common_complete')
                )}
              </button>
            </div>
          </Form>
        </div>
      </div>
    </React.Fragment>
  );
};

EmailVerification.propTypes = {
  history: PropTypes.any,
};

export default EmailVerification;
