import React, { useEffect, useState } from 'react';

import languages from 'common/languages';
import getLanguages from 'common/languages';
import i18next, { t } from 'i18next';
import i18n from 'i18next';
import { get } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';

import { changeLanguage } from '../../../store/layout/actions';

const LanguageDropdown = () => {
  const dispatch = useDispatch();
  const language =
    useSelector((state) => state.Layout.language) || localStorage.getItem('I18N_LANGUAGE') || 'en';
  const [menu, setMenu] = useState(false);
  const lngList = getLanguages(t);

  const changeLanguageAction = (lang) => {
    dispatch(changeLanguage(lang));
    localStorage.setItem('I18N_LANGUAGE', lang);
    i18next.changeLanguage(lang);
  };

  const toggle = () => {
    setMenu(!menu);
  };

  useEffect(() => {
    const lng = i18n.language?.split('-')[0];

    localStorage.setItem('I18N_LANGUAGE', lng);
    i18next.changeLanguage(lng);
    dispatch(changeLanguage(lng));
  }, []);

  return (
    <>
      <Dropdown isOpen={menu} toggle={toggle} className="d-inline-block">
        <DropdownToggle className="btn header-item " tag="button">
          <img src={lngList[language]?.flag} alt={language} height="16" className="me-1" />
        </DropdownToggle>
        <DropdownMenu className="language-switch dropdown-menu-end">
          {Object.keys(lngList).map((key) => (
            <DropdownItem
              key={key}
              onClick={() => changeLanguageAction(key)}
              className={`notify-item ${language === key ? 'active' : 'none'}`}>
              <img src={lngList[key].flag} alt="Skote" className="me-1" />
              <span className="align-middle">{get(lngList, `${key}.label`)}</span>
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    </>
  );
};

export default LanguageDropdown;
