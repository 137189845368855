import React, { useEffect, useState } from 'react';

import DefaultProfileImage from 'assets/images/default-profile-img.png';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';

import { t } from '../../../i18n';
import { getPhoto } from '../../../store/auth/photo/load/actions';
import Icon from '../../Icon';

const ProfileMenu = (props) => {
  const [menu, setMenu] = useState(false);
  const fullName = useSelector((state) => state.Profile.user.fullName ?? '');

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPhoto());
  }, [props.success, props.upload]);

  return (
    <React.Fragment>
      <Dropdown isOpen={menu} toggle={() => setMenu(!menu)} className="d-inline-block">
        <DropdownToggle className="btn header-item " id="page-header-user-dropdown" tag="button">
          <img
            className="rounded-circle header-profile-user"
            src={props.photo ?? DefaultProfileImage}
            alt="Header Avatar"
          />
          <span className="d-none d-xl-inline-block ms-2 me-1">{fullName}</span>
          <Icon name="dropdown" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem tag={Link} to="/profile" className="d-flex align-items-center gap-2">
            {' '}
            <Icon name="profile" size={18} />
            {t('common_profile')}
          </DropdownItem>
          <div className="dropdown-divider" />
          <Link to="/logout" className="dropdown-item d-flex align-items-center gap-2 text-danger">
            <Icon name="logout" size={16} />
            <span>{t('common_logout')}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

ProfileMenu.propTypes = {
  photo: PropTypes.any,
  upload: PropTypes.any,
  success: PropTypes.any,
  t: PropTypes.any,
};

const mapStatetoProps = (state) => {
  const { error, success } = state.Profile;
  const { photo } = state.Photo;
  const { upload } = state.PhotoUpload;
  return { error, success, photo, upload };
};

export default withRouter(connect(mapStatetoProps, {})(withTranslation()(ProfileMenu)));
