import React, { useEffect, useState } from 'react';

import { useFormik } from 'formik';
import { t } from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Label, Spinner } from 'reactstrap';

import PasswordInput from '../../../components/Custom/passwordInput';

import countries from '../../../constants/countries';
import { editProfile } from '../../../store/auth/profile/actions';
import { editPasswordSchema } from '../../../yupshema/editProfile';
import s from './Components.module.scss';

const PasswordSetting = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { fullName, country, mobile, agreement } = useSelector((state) => state.Profile.user);
  const { error, success } = useSelector((state) => ({
    error: state.Profile.error,
    success: state.Profile.success,
  }));

  const validation = useFormik({
    // enableReinitialize: true,

    initialValues: {
      fullName: fullName || '',
      country: countries(t)[0]?.options?.find(({ value }) => value === country),
      mobile: mobile || '',
      agreement: Number(agreement) === 1,
    },
    validationSchema: editPasswordSchema,

    onSubmit: (values) => {
      setLoading(true);
      const updatedValues = {};
      for (const key in values) {
        if (values.hasOwnProperty(key) && values[key]) {
          updatedValues[key] = values[key];
        }
      }

      if (values?.agreement) {
        updatedValues.agreement = '1';
      } else {
        updatedValues.agreement = '0';
      }

      dispatch(editProfile(updatedValues));
    },
  });

  useEffect(() => {
    if (success || error) {
      // validation.resetForm();
      setLoading(false);
    }
  }, [error, success]);

  return (
    <div className={s.settingBlock}>
      <h4 className={s.blockTitle}>{t('user_profile_change_password')}</h4>
      {error && <div className="alert-warning">{error}</div>}
      <Form
        className={s.passwordForm}
        onSubmit={(e) => {
          e.preventDefault();
          validation.handleSubmit();
          return false;
        }}>
        <div className={s.formField}>
          <Label className={s.label} htmlFor="old_password">
            {t('user_profile_old_password')}
          </Label>
          <PasswordInput
            name="old_password"
            validation={validation}
            placeholder={t('user_profile_old_password_placeholder')}
            invalid={!!(validation.touched.old_password && validation.errors.old_password)}
            size="sm"
          />
        </div>
        <div className={s.formItem}>
          <div className={s.formField}>
            <Label className={s.label} htmlFor="new_password">
              {t('user_profile_new_password')}
            </Label>
            <PasswordInput
              name="new_password"
              validation={validation}
              placeholder={t('user_profile_new_password_placeholder')}
              invalid={!!validation.errors.new_password && !!validation.touched.new_password}
              size="sm"
            />
          </div>
          <div className={s.formField}>
            <Label className={s.label} htmlFor="confirm_password">
              {t('user_profile_confirm_new_password')}
            </Label>
            <PasswordInput
              name="confirm_password"
              validation={validation}
              placeholder={t('user_profile_confirm_new_password')}
              invalid={!!validation.errors.confirm_password && validation.touched.confirm_password}
              size="sm"
            />
          </div>
        </div>

        <button
          type="submit"
          color="primary"
          className={s.submitBtn}
          disabled={!validation.dirty || Object.keys(validation.errors).length > 0}>
          {loading ? (
            <div className="d-flex gap-2 justify-content-center align-items-center">
              <Spinner className="spinner" /> {t('common_save_changes')}
            </div>
          ) : (
            t('common_save_changes')
          )}
        </button>
      </Form>
    </div>
  );
};

export default PasswordSetting;
