import React, { useCallback, useEffect, useRef, useState } from 'react';

import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { MetaTags } from 'react-meta-tags';
import { connect, useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';

import { getTeamInfo } from 'store/team/actions';
import { getTeamInfoSelector } from 'store/team/reducer';

import AllStructureInfo from './components/AllStructureInfo';
import FirstStructureInfo from './components/FirstStructureInfo';
import PartnersList from './components/PartnersList';
import AnalysisModal from './components/analysis-modal';
import Breadcrumbs from 'components/Common/Breadcrumb';
import ReferralsTree from 'components/ReferralsTree';

import { t } from '../../i18n';
import './scss/team.scss';

const Team = () => {
  const dispatch = useDispatch();
  const {
    totalReferrals,
    referralsIncome,
    teamDeposit,
    firstDeposit,
    firstReferrals,
    firstReferralsIncome,
  } = useSelector(getTeamInfoSelector);
  const currentUser = useSelector((state) => state.Profile.user);
  const referralsCount = useSelector((state) => state.Team.teamInfo.totalReferrals);
  const treeRef = useRef(null);
  const [isOpenedAnalysisModal, setIsOpenedAnalysisModal] = useState({ opened: false, data: null });

  useEffect(() => {
    dispatch(getTeamInfo());
  }, []);

  const handleOpenAnalysisModal = useCallback(() => {
    setIsOpenedAnalysisModal({
      opened: true,
      data: {
        partner_id: currentUser?.partnerId,
        full_name: currentUser?.fullName,
      },
    });
  }, [currentUser]);

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>{t('team_meta_title')}</title>
        </MetaTags>
        <Container fluid>
          <div className="team_header_wrapper">
            <Breadcrumbs
              title={t('team_structure')}
              hasBreadcrumbItem={false}
              breadcrumbItem={t('team_structure')}
              zeroPadding
            />

            <button onClick={handleOpenAnalysisModal}>{t('team_my_analysis')}</button>
          </div>
          <Row>
            <Col lg={6}>
              <AllStructureInfo
                totalReferrals={totalReferrals}
                referralsIncome={referralsIncome}
                teamDeposit={teamDeposit}
              />
            </Col>
            <Col lg={6}>
              <FirstStructureInfo
                firstDeposit={firstDeposit}
                firstReferrals={firstReferrals}
                firstReferralsIncome={firstReferralsIncome}
              />
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <ReferralsTree
                currentUser={currentUser}
                referralsCount={referralsCount}
                ref={treeRef}
              />
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <PartnersList treeRef={treeRef} />
            </Col>
          </Row>
        </Container>
      </div>
      {isOpenedAnalysisModal?.opened && (
        <AnalysisModal
          isOpen={isOpenedAnalysisModal?.opened}
          data={isOpenedAnalysisModal?.data}
          setIsOpen={setIsOpenedAnalysisModal}
        />
      )}
    </React.Fragment>
  );
};

const mapStateProps = (state) => {
  const { error, success } = state.Profile;
  return { error, success };
};

Team.propTypes = {
  t: PropTypes.any,
  success: PropTypes.any,
};

export default withRouter(connect(mapStateProps, {})(withTranslation()(Team)));
