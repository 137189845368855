import React, { useCallback, useEffect, useRef, useState } from 'react';

import { ReactComponent as CalendarIcon } from 'assets/images/icons/calendarIcon.svg';
import { ReactComponent as CloseIcon } from 'assets/images/icons/close.svg';
import classNames from 'classnames';
import { ar, de, enUS, es, fr, hi, it, ka, kk, ru, tr, uz } from 'date-fns/locale';
import useTranslation from 'hooks/useTranslation';
import i18next from 'i18next';
import moment from 'moment';
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import { Input, Label, Modal } from 'reactstrap';

import { getAllProjects } from '../../../../services/projectService';

import Multiselect from '../../../../components/Multiselect';
import YearDatePicker from '../../../../components/YearDatePicker';
import InvestedFundsAnalytics from './components/InvestedFundsAnalytics';
import InvitedUsersAnalytics from './components/InvitedUsersAnalytics';
import InvestedByTypesAnalytics from './components/investedByTypesAnalytics';
import Tabs from 'components/Tabs';

import { convertLocale } from '../../../../helpers/convertLocation';
import { formatNumber } from '../../../../helpers/formatNumber';
import s from './AnalysisModal.module.scss';
import './day-picker-custom.css';

const TABS_ITEMS = [
  {
    label: 'team_analysis_modal_tabs_customer_acquisition',
    value: 'invited_users',
  },
  {
    label: 'team_analysis_modal_tabs_fundraising',
    value: 'fundraising',
  },
  {
    label: 'team_analysis_modal_tabs_referral_charges',
    value: 'referral_charges',
  },
];

const locales = {
  ar: ar,
  de: de,
  en: enUS,
  es: es,
  fr: fr,
  hi: hi,
  it: it,
  ka: ka,
  kk: kk,
  ru: ru,
  tr: tr,
  uz: uz,
};

export const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className={s.tooltip}>
        <div>{label}</div>
        {payload?.map((item) => (
          <div style={{ color: item.color }} key={item.name}>
            {i18next.t(item?.name)}: {formatNumber(item.value, 2)}
          </div>
        ))}
      </div>
    );
  }

  return null;
};

const AnalysisModal = ({ isOpen, data, setIsOpen }) => {
  const t = useTranslation();
  const [activeTab, setActiveTab] = useState('invited_users');
  const [activeYear, setActiveYear] = useState(new Date());
  const [selectedDates, setSelectedDates] = useState({});
  const [isYearPickerOpen, setIsYearPickerOpen] = useState(true);
  const [groupBy, setGroupBy] = useState('month');
  const [projects, setProjects] = useState([]);
  const startDatePickerRef = useRef(null);
  const endDatePickerRef = useRef(null);
  const [filter, setFilter] = useState({
    projects: [],
  });
  const [isStartDatePickerVisible, setIsStartDatePickerVisible] = useState(false);
  const [isEndDatePickerVisible, setIsEndDatePickerVisible] = useState(false);
  const [dateRange, setDateRange] = useState({
    from: new Date(new Date().setFullYear(new Date().getFullYear() - 1)),
    to: new Date(),
  });

  const AnalysisComponents = {
    invited_users: (
      <InvitedUsersAnalytics
        referralId={data?.partner_id}
        activeYear={moment(activeYear).format('yyyy')}
      />
    ),
    fundraising: (
      <InvestedFundsAnalytics
        referralId={data?.partner_id}
        activeYear={moment(activeYear).format('yyyy')}
        projects={projects}
        filter={filter}
        selectedDates={dateRange}
        groupBy={groupBy}
      />
    ),
    referral_charges: (
      <InvestedByTypesAnalytics
        referralId={data?.partner_id}
        activeYear={moment(activeYear).format('yyyy')}
      />
    ),
  };

  const handleClose = () => {
    setIsOpen({ opened: false, data: null });
  };

  const fetchAllProjects = async () => {
    const response = await getAllProjects(convertLocale('en'));
    const basicPackages = response?.data?.filter((obj) => obj.categories.slug === 'bazovyj-paket');
    const otherPackages = response?.data?.filter((obj) => obj.categories.slug !== 'bazovyj-paket');

    basicPackages.sort((a, b) => parseFloat(a.min_amount) - parseFloat(b.min_amount));
    otherPackages.sort((a, b) => parseFloat(a.min_amount) - parseFloat(b.min_amount));

    const sortedData = basicPackages.concat(otherPackages);
    setFilter({
      ...filter,
      projects: sortedData?.map((item) => item.service_name),
    });
    setProjects(sortedData);
  };

  const handleSelectProject = useCallback(
    (value) => setFilter((prev) => ({ ...prev, projects: value })),
    [setFilter],
  );

  useEffect(() => {
    fetchAllProjects();
  }, []);

  useEffect(() => {
    if (!isYearPickerOpen && selectedDates?.startDate && selectedDates?.endDate) {
      setActiveYear(null);
    } else if (isYearPickerOpen && selectedDates?.startDate && selectedDates?.endDate) {
      setSelectedDates({});
      setActiveYear(new Date());
    }
  }, [isYearPickerOpen, selectedDates?.endDate, selectedDates?.startDate]);

  useEffect(() => {
    if (activeTab !== 'fundraising') {
      setIsYearPickerOpen(true);
      setSelectedDates({});
      setActiveYear(new Date());
    }
  }, [activeTab]);

  const handleDateSelect = useCallback((range) => {
    setIsStartDatePickerVisible(false);
    setIsEndDatePickerVisible(false);
    setDateRange({ from: range?.from, to: range?.to });
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        startDatePickerRef.current &&
        event.target instanceof Node &&
        !startDatePickerRef.current.contains(event.target)
      ) {
        setIsStartDatePickerVisible(false);
      }
      if (
        endDatePickerRef.current &&
        event.target instanceof Node &&
        !endDatePickerRef.current.contains(event.target)
      ) {
        setIsEndDatePickerVisible(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      setDateRange({ from: null, to: null });
    };
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      centered
      className={`${!isOpen ? 'd-none' : ''}`}
      size="xl"
      toggle={handleClose}>
      <div className={s.wrapper}>
        <div className={s.header}>
          <div className={s.refInfo}>
            <div className={s.name}>
              <p className={classNames(s.text, s.bold)}>
                {t('team_analysis_modal_referral_name', { name: data?.full_name })}
              </p>
              <p className={s.text}>{data?.partner_id}</p>
            </div>
            <div className={s.filters}>
              <div className={s.pickerContainer}>
                {activeTab === 'fundraising' && (
                  <div className={s.filterItem}>
                    <Label>{t('group_label')}</Label>
                    <Input
                      id="exampleSelect"
                      name="select"
                      type="select"
                      className={s.selectGroupType}
                      onChange={(e) => setGroupBy(e.target.value)}
                      value={groupBy}>
                      <option value="month">{t('month_group')}</option>
                      <option value="date">{t('day_group')}</option>
                    </Input>
                  </div>
                )}
                {activeTab !== 'fundraising' ? (
                  <YearDatePicker
                    activeYear={activeYear}
                    onChange={(date) => setActiveYear(date)}
                  />
                ) : (
                  <div className={s.datePickerWrapper}>
                    <Label>{t('team_modal_chose_date_range')}</Label>
                    <div className={s.dateRangePicker}>
                      <button
                        className={s.selectDateButton}
                        onClick={() => setIsStartDatePickerVisible(!isStartDatePickerVisible)}>
                        {!!dateRange?.from
                          ? `${moment(dateRange?.from).format('DD.MM.YYYY')}`
                          : moment().subtract(1, 'years').format('DD.MM.YYYY')}
                        <CalendarIcon />
                      </button>
                      {isStartDatePickerVisible && (
                        <div className={s.datePickerDropdown} ref={startDatePickerRef}>
                          <DayPicker
                            mode="single"
                            selected={dateRange?.from}
                            onSelect={(date) => handleDateSelect({ from: date, to: dateRange?.to })}
                            captionLayout="dropdown-buttons"
                            fromYear={moment().subtract(10, 'years').year()}
                            toYear={moment().add(10, 'years').year()}
                            weekStartsOn={1}
                            defaultMonth={dateRange?.from}
                            locale={locales[i18next.language || 'en']}
                          />
                        </div>
                      )}
                      <span className={s.divider}>-</span>
                      <button
                        className={s.selectDateButton}
                        onClick={() => setIsEndDatePickerVisible(!isEndDatePickerVisible)}>
                        {!!dateRange?.to
                          ? `${moment(dateRange?.to).format('DD.MM.YYYY')}`
                          : moment().format('DD.MM.YYYY')}
                        <CalendarIcon />
                      </button>
                      {isEndDatePickerVisible && (
                        <div className={s.datePickerDropdown} ref={endDatePickerRef}>
                          <DayPicker
                            mode="single"
                            selected={dateRange?.to}
                            onSelect={(date) =>
                              handleDateSelect({ from: dateRange?.from, to: date })
                            }
                            captionLayout="dropdown-buttons"
                            fromYear={moment().subtract(10, 'years').year()}
                            toYear={moment().add(10, 'years').year()}
                            weekStartsOn={1}
                            defaultMonth={dateRange?.to}
                            locale={locales[i18next.language || 'en']}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>

              {activeTab === 'fundraising' && (
                <div className={s.filterItem}>
                  <Label>{t('team_modal_chose_product')}</Label>
                  <Multiselect
                    options={projects?.map((item) => ({
                      label: t(`strapi_${item.service_name}`),
                      value: item.service_name,
                    }))}
                    placeholder={t('team_modal_chose_product')}
                    defaultSelected={filter?.projects}
                    onChange={handleSelectProject}
                  />
                </div>
              )}
            </div>
          </div>
          <div className={s.closeWrapper}>
            <div onClick={handleClose} className={s.close}>
              <CloseIcon />
            </div>
          </div>
        </div>
        <div className={s.body}>
          <Tabs tabs={TABS_ITEMS} onChange={setActiveTab} />
          {AnalysisComponents[activeTab]}
        </div>
      </div>
    </Modal>
  );
};

export default AnalysisModal;
