import { useEffect } from 'react';

import {
  getDisabledProjectTime,
  getDisabledProjectTimeDifference,
  removeDisabledProjectTime,
} from 'helpers/DisabledProject/DisabledProject';
import { useDispatch, useSelector } from 'react-redux';

import { setProjectButtonCondition } from '../../../store/projects/project/actions';

export const useProjectInvestmentTimeout = (projects, setDisabledProjects) => {
  const isButtonsDisabled = useSelector(
    (state) => state.Project.isDepositButtonDisabledByInvestment,
  );
  const dispatch = useDispatch();

  useEffect(() => {
    projects?.forEach((project) => {
      if (
        getDisabledProjectTime(project.service_name) ||
        isButtonsDisabled.includes(project.service_name)
      ) {
        setDisabledProjects((prevState) => {
          if (!prevState.includes(project.service_name)) {
            return [...prevState, project.service_name];
          }
          return prevState;
        });

        const timeDifference = getDisabledProjectTimeDifference(project.service_name);
        if (timeDifference > 0 && timeDifference <= 1000 * 60 * 15) {
          const timeout = setTimeout(() => {
            removeDisabledProjectTime(project.service_name);
            setDisabledProjects((prevState) => prevState.filter((p) => p !== project.service_name));
            dispatch(
              setProjectButtonCondition(
                isButtonsDisabled.filter((p) => p !== project.service_name),
              ),
            );
          }, timeDifference);
          return () => clearTimeout(timeout);
        } else {
          removeDisabledProjectTime(project.service_name);
          setDisabledProjects((prevState) => prevState.filter((p) => p !== project.service_name));
          dispatch(
            setProjectButtonCondition(isButtonsDisabled.filter((p) => p !== project.service_name)),
          );
        }
      }
    });
  }, [projects, setDisabledProjects, isButtonsDisabled, dispatch]);
};
