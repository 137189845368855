import React from 'react';

import DefaultProfileImage from 'assets/images/default-profile-img.png';
import PropTypes from 'prop-types';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';

import Icon from '../../components/Icon';

import { allStructureLevels, firstStructureLevels } from 'constants/levels';

import { formatNumber } from '../../helpers/formatNumber';
import useTranslation from '../../hooks/useTranslation';
import './sass/notification.scss';
import './sass/welcomeComp.scss';

const WelcomeComp = ({ user }) => {
  const t = useTranslation();
  const {
    level,
    investorLevel,
    fullName,
    partnerId,
    need_to_all_structure,
    need_to_first_structure,
    localized_full_name,
  } = user;
  const language = useSelector((state) => state.Layout.language);

  const originLocation = window && window?.location.origin;
  const { photo } = useSelector((state) => ({
    photo: state.Photo.photo,
  }));

  return (
    <div className="dashboard-card user-card-info dashboard-top-block">
      <div className="dashboard-user-info">
        <div>
          <img
            src={photo ?? DefaultProfileImage}
            alt={`${fullName} photo`}
            className="user-avatar"
          />
        </div>
        <div>
          <span className="dashboard-user-name">
            {language === 'ru' ? fullName : localized_full_name}
          </span>
          <div className="dashboard_user_level_info">
            <span className="count">{Number(level) + 1}</span>
            <p>
              {t('dashboard_your_level')}:{' '}
              <span className="level-info">{t(`user_levels.level_${level}`)}</span>
            </p>
          </div>
        </div>
      </div>
      <div>
        {investorLevel !== 0 && (
          <>
            <div className="dashboard-personal-goal-block">
              <div className="dashboard-personal-goal-block_title">
                {t('dashboard_your_goal', { level: Number(level) + 2 })}
              </div>
              <div className="dashboard-stats">
                <div>
                  <div className="dashbaord__level_title">{t('dashboard_personal_sales')}</div>
                  <div className="dashboard__to_next_level">
                    <div className="dashboard__level_amount">
                      {formatNumber(firstStructureLevels[Number(level)])}$
                    </div>
                    <div className="dashboard__level_amount">
                      {formatNumber(firstStructureLevels[Number(level) + 1])}$
                    </div>
                    <div className="level__line">
                      <div
                        className="level__line_first"
                        style={{ width: `${need_to_first_structure?.percent}%` }}></div>
                    </div>
                    <div className="dashboard__level_amount">
                      {t('common_done')}{' '}
                      <span>{formatNumber(need_to_first_structure?.current_value)}$</span>
                    </div>
                    <div className="dashboard__level_amount">
                      {t('common_remaining')}{' '}
                      <span>{formatNumber(need_to_first_structure?.difference)}$</span>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="dashbaord__level_title">{t('dashboard_structured_sales')}</div>
                  <div className="dashboard__to_next_level">
                    <div className="dashboard__level_amount">
                      {formatNumber(allStructureLevels[Number(level)])}$
                    </div>
                    <div className="dashboard__level_amount">
                      {formatNumber(allStructureLevels[Number(level) + 1])}$
                    </div>
                    <div className="level__line">
                      <div
                        className="level__line_second"
                        style={{ width: `${need_to_all_structure?.percent}%` }}></div>
                    </div>
                    <div className="dashboard__level_amount">
                      {t('common_done')}{' '}
                      <span>{formatNumber(need_to_all_structure?.current_value)}$</span>
                    </div>
                    <div className="dashboard__level_amount">
                      {t('common_remaining')}{' '}
                      <span>{formatNumber(need_to_all_structure?.difference)}$</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="dashboard-ref-link">
                <div>{t('dashboard_your_referral_link')}: </div>
                <div className="d-flex align-items-center">
                  <span className="copy__link">{partnerId}</span>
                  <CopyToClipboard
                    text={`${originLocation}/register?partner_id=${partnerId}`}
                    onCopy={() => toast.success(t('dashboard_referral_link_copied'))}>
                    <button
                      className="btn font-size-18 p-0 m-0 d-flex"
                      style={{ color: '#0e7772' }}>
                      <Icon name="copy" size={18} />
                    </button>
                  </CopyToClipboard>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

const MemoizedWelcomeComp = React.memo(WelcomeComp);

export default MemoizedWelcomeComp;

WelcomeComp.propTypes = {
  user: PropTypes.shape({
    level: PropTypes.string,
    fullName: PropTypes.string,
    partnerId: PropTypes.string,
    deposit: PropTypes.number,
    createdAt: PropTypes.string,
    needToAllStructure: PropTypes.any,
    needToFirstStructure: PropTypes.any,
  }),
};
