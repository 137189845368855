import { useContractWrite, usePrepareContractWrite } from 'wagmi';
import { bsc } from 'wagmi/chains';

import { usdtAbi, usdtToken } from 'constants/wallet';

export const useUsdtTransfer = ({ recipientAddress, amount, handleError }) => {
  const { config } = usePrepareContractWrite({
    abi: usdtAbi,
    address: usdtToken,
    chainId: bsc.id,
    functionName: 'transfer',
    args: [recipientAddress, Number(amount)],
    onError(error) {
      if (error?.cause.reason === 'BEP20: transfer amount exceeds balance') {
        handleError(error?.cause.reason);
      }
    },
    onSuccess() {
      handleError(null);
    },
  });

  const data = useContractWrite(config);
  return [data, config];
};
