import {
  SET_ACCRUALS,
  SET_ACCRUALS_GRAPH_DATA,
  SET_ACCRUALS_INCOME,
  SET_TOTAL_ACCRUALS,
} from './actionTypes';

const initState = {
  items: [],
  income: {},
  accruals: [],
  accrualsGraphData: [],
};

const Accruals = (state = initState, action) => {
  switch (action.type) {
    case SET_ACCRUALS:
      return { ...state, items: action.payload };

    case SET_ACCRUALS_INCOME:
      return { ...state, income: action.payload };

    case SET_TOTAL_ACCRUALS:
      return { ...state, accruals: action.payload };

    case SET_ACCRUALS_GRAPH_DATA:
      return { ...state, accrualsGraphData: action.payload };

    default:
      return state;
  }
};

export default Accruals;
