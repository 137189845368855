import { useCallback, useState } from 'react';

import { API_URL, get } from 'helpers/api_helper';

export const usePromotion = () => {
  const [promotionData, setPromotionData] = useState(null);

  const fetchPromotionData = useCallback(async (take = 10, skip = 0) => {
    try {
      const response = await get(`${API_URL}/promotions/v2?take=${take}&skip=${skip}`);
      const {
        first_condition_goal = 0,
        first_structure = 0,
        promo_users_rating = [],
        promotion_level = 0,
        second_condition_goal = 0,
        first_condition_completed = 0,
        second_condition_completed = 0,
        second_condition_rating = [],
        super_promo_users_rating = [],
      } = response;
      const data = {
        first_condition_goal,
        first_structure,
        promo_users_rating,
        promotion_level,
        second_condition_goal,
        first_condition_completed,
        second_condition_completed,
        second_condition_rating,
        super_promo_users_rating,
      };
      setPromotionData(data);
      return data;
    } catch (error) {
      console.error('Error fetching promotion data:', error);
      return [];
    }
  }, []);

  return { promotionData, fetchPromotionData };
};
