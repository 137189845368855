import {
  ADD_NOTIFICATION,
  DELETE_NOTIFICATION,
  EDIT_NOTIFICATION,
  GET_ALL_NOTIFICATIONS,
  GET_NOTIFICATIONS,
  GET_NOTIFICATIONS_SETTINGS,
  SELECT_NOTIFICATION,
  SET_NOTIFICATIONS_SETTINGS,
} from './actionTypes';

export const getNotifications = (payload) => ({
  type: GET_NOTIFICATIONS,
  payload,
});

export const getAllNotifications = (payload) => ({
  type: GET_ALL_NOTIFICATIONS,
  payload,
});

export const addNotification = (payload) => ({
  type: ADD_NOTIFICATION,
  payload,
});

export const editNotification = (payload) => ({
  type: EDIT_NOTIFICATION,
  payload,
});

export const deleteNotification = (notificationId) => ({
  type: DELETE_NOTIFICATION,
  payload: notificationId,
});

export const selectCurrentNotification = (notificationId) => ({
  type: SELECT_NOTIFICATION,
  payload: notificationId,
});

export const getNotificationsSettings = (payload) => ({
  type: GET_NOTIFICATIONS_SETTINGS,
  payload,
});

export const setNotificationsSettings = (payload) => ({
  type: SET_NOTIFICATIONS_SETTINGS,
  payload,
});
