import React from 'react';

import { ReactComponent as USDT } from 'assets/images/icons/bep-20.svg';
import cn from 'classnames';
import { t } from 'i18next';
import { Trans } from 'react-i18next';

import s from './ProductInfo.module.scss';

const ProductInfo = ({ productDetail, amount }) => {
  return (
    <div className={s.wrapper}>
      <div>
        <div className={s.title}>{productDetail?.name}</div>
        <div className={s.info}>
          <div className={s.item}>
            <div>{t('project_investment_period')}</div>
            <div className="fw-bold">
              {t(`strapi_invest_period_${productDetail?.invest_period_in_weeks}`)}
            </div>
          </div>
          <div className={s.item}>
            <div>{t('project_payment_period')}</div>
            <div className="fw-bold">
              {t(`strapi_payment_period_${productDetail?.payment_period_in_weeks}`)}
            </div>
          </div>
          <div className={s.item}>
            <div>{t('project_yearly_profit')}</div>
            <div className="fw-bold">{productDetail?.apy}%</div>
          </div>
        </div>
        <div>
          <div className={cn(s.item, s.amountInfo)}>
            <div>
              <Trans
                i18nKey="payment_hint"
                components={{
                  bold: <b />,
                }}
              />
            </div>
            <div className={s.amount}>
              <div className="fw-bold">
                {amount <= Number(productDetail?.min_amount) ? productDetail?.min_amount : amount}{' '}
                USDT
              </div>
              <div>BEP-20</div>
              <USDT />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductInfo;
