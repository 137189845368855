import React, { useEffect } from 'react';

import classNames from 'classnames';
import useTranslation from 'hooks/useTranslation';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { getInvitedUsersAnalytics } from 'store/team/actions';

import EmptyState from 'components/EmptyState';

import s from '../AnalysisModal.module.scss';
import { CustomTooltip } from '../index';
import CustomLegend from './CustomLegend';

const InvitedUsersAnalytics = ({ referralId, activeYear }) => {
  const t = useTranslation();
  const dispatch = useDispatch();
  const invitedUsersAnalytics = useSelector((state) => state.Team.invitedUsersAnalytics);
  const invitedUsersAnalyticsLoading = useSelector(
    (state) => state.Team.invitedUsersAnalyticsLoading,
  );
  const inviteChartData = invitedUsersAnalytics?.map((item) => ({
    invitedStructure: Number(item.invited_structure),
    invitedPersonal: Number(item.invited_personal),
    name: moment(item.date).format('DD.MM.YYYY'),
  }));

  useEffect(() => {
    dispatch(getInvitedUsersAnalytics(referralId, activeYear));
  }, [activeYear, dispatch, referralId]);

  return (
    <>
      <div className={s.chartWrapper}>
        {!!inviteChartData?.length ? (
          <ResponsiveContainer className={s.chart}>
            <LineChart
              data={inviteChartData}
              margin={{
                top: 5,
                right: 30,
                left: -10,
                bottom: 5,
              }}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip content={CustomTooltip} />
              <Legend
                wrapperStyle={{
                  display: 'flex',
                  justifyContent: 'center',
                  width: '-webkit-fill-available',
                  left: 'unset',
                }}
                content={CustomLegend}
              />
              <Line
                type="monotone"
                dataKey="invitedPersonal"
                stroke="#556EE6"
                activeDot={{ r: 8 }}
              />
              <Line type="monotone" dataKey="invitedStructure" stroke="#F1B44C" />
            </LineChart>
          </ResponsiveContainer>
        ) : (
          <EmptyState />
        )}
      </div>
      <div className={s.tableWrapper}>
        <div className={s.table}>
          <div className={s.row}>
            <div className={s.item}>{t('tables_title.date')}</div>
            <div className={s.item}>
              <span className={classNames(s.circle, s.blue)} />{' '}
              <div className={s.label}>{t('invitedPersonal')}</div>
            </div>
            <div className={s.item}>
              <span className={classNames(s.circle, s.yellow)} />{' '}
              <div className={s.label}>{t('invitedStructure')}</div>
            </div>
          </div>
          {!!invitedUsersAnalytics?.length ? (
            invitedUsersAnalytics?.map((item, i) => (
              <div className={s.row} key={`${i}_${item.date}`}>
                <div className={classNames(s.item, s.bold)}>
                  {moment(item.date).format("MMM 'YY")}
                </div>
                <div className={s.item}>{item.invited_personal}</div>
                <div className={s.item}>{item.invited_structure}</div>
              </div>
            ))
          ) : (
            <EmptyState />
          )}
        </div>
      </div>
      {invitedUsersAnalyticsLoading && (
        <div className={s.loaderWrapper}>
          <span className={s.loader}></span>
        </div>
      )}
    </>
  );
};

export default InvitedUsersAnalytics;
