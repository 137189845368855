import { MarkerType } from 'reactflow';

export const createTreeEdges = (referrals, i = '') => {
  return referrals?.map((referral) => ({
    id: `${referral.partner_id}-${referral.referrer_id}`,
    source: referral.partner_id,
    target: referral.referrer_id,
    type: 'smoothstep',
    style: { stroke: '#dedee5' },
    markerStart: {
      type: MarkerType.ArrowClosed,
    },
  }));
};

export const calculatePositions = (mainCardPosition, referrals) => {
  const cardWidth = 250;
  const cardSpacing = 150;
  const positions = [];

  const mainCardX = mainCardPosition.x;
  const mainCardY = mainCardPosition.y;

  referrals.forEach((_, index) => {
    const x = mainCardX + index * (cardWidth + cardSpacing);
    const y = mainCardY + 300;

    const position = {
      x: x,
      y: y,
    };

    positions.push(position);
  });

  return positions;
};

export const calculateFullTreePosition = (mainCardPosition, referrals) => {
  const cardWidth = 250;
  const cardSpacing = 50;
  const cardHeight = 200;

  const mainCardX = mainCardPosition.x;
  const mainCardY = mainCardPosition.y;

  const positions = [];
  const centerX = Math.floor((referrals.length - 1) / 2) * (cardWidth + cardSpacing) + mainCardX;

  referrals.forEach((_, index) => {
    const x = centerX + (index - Math.floor(referrals.length / 2)) * (cardWidth + cardSpacing);
    const y = mainCardY + index * cardHeight + index * cardSpacing;

    const position = {
      x: x,
      y: y,
    };

    positions.push(position);
  });

  return positions;
};
