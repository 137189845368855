/* eslint-disable react-hooks/rules-of-hooks */
import React from 'react';

import {
  addHeaderFormatterToTableColumns,
  headerFormatterCenteredTitle,
} from 'helpers/table_helper';
import i18next from 'i18next';
import moment from 'moment/moment';

import ShortWallet from 'components/SharedAcrrualsPage/components/ShortWallet';

export const DISPLAYED_KEY_NAMES = {
  payment_date: 'Дата',
  referralFullName: 'Имя партнера',
  referralPartnerId: 'ID партнера',
  wallet_addr: 'Адрес кошелька',
  amount: 'Количество',
  percent: 'Процент',
  accrual_type: 'Тип',
  product: 'Продукт',
  status: 'Статус',
};

export const columns = (t) => [
  {
    dataField: 'payment_date',
    sort: true,
    text: i18next.t('tables_title.date'),
    attrs: {
      'data-label': t('tables_title.date'),
    },
    formatter: (row) => {
      return <div>{moment(row).format('DD.MM.YYYY')}</div>;
    },
    headerFormatter: headerFormatterCenteredTitle,
  },
  {
    dataField: 'user_partner.full_name',
    sort: true,
    text: t('tables_title.partner_name'),
    attrs: {
      'data-label': t('tables_title.partner_name'),
    },
  },
  {
    dataField: 'user_partner.partner_id',
    sort: true,
    text: t('tables_title.partner_id'),
    attrs: {
      'data-label': t('tables_title.partner_id'),
    },
  },
  {
    dataField: 'wallet_addr',
    text: t('tables_title.address'),
    sort: true,
    attrs: {
      'data-label': t('tables_title.address'),
    },
    classes: 'wallet__addr_col',
    formatter: (cell, row) => <ShortWallet id={row.id} wallet={cell} />,
  },
  {
    dataField: 'amount',
    text: t('tables_title.amount'),
    sort: true,
    attrs: {
      'data-label': t('tables_title.amount'),
    },
    formatter: (row) => {
      const roundedValue = Math.ceil(row * 1000) / 1000;
      return <div className="text-bold">{roundedValue} USDT</div>;
    },
  },
  {
    dataField: 'percent',
    text: t('tables_title.percentage'),
    sort: true,
    attrs: {
      'data-label': t('tables_title.percentage'),
    },
    formatter: (row) => {
      return <div>{row}%</div>;
    },
  },
  {
    dataField: 'accrual_type',
    text: t('tables_title.type'),
    sort: true,
    attrs: {
      'data-label': t('tables_title.type'),
    },
    formatter: (row) => {
      const values = {
        product: t('tables_title.product_type'),
        referral: t('tables_title.referral_income'),
        upgrade: t('tables_title.upgrade'),
        passive: t('tables_title.passive'),
      };
      return <div>{values[row]}</div>;
    },
  },
  {
    dataField: 'product',
    text: t('tables_title.product'),
    sort: true,
    attrs: {
      'data-label': t('tables_title.product'),
    },
    formatter: (row) => {
      return <div>{row.product}</div>;
    },
  },
  {
    dataField: 'status',
    text: t('tables_title.status'),
    sort: true,
    attrs: {
      'data-label': t('tables_title.status'),
    },
    formatter: (row) => {
      const values = {
        sent: t('tables_title.sent'),
        waiting: t('tables_title.waiting'),
        error: t('tables_title.error'),
        nulled: t('tables_title.nulled'),
      };
      return <div>{values[row]}</div>;
    },
    formatExtraData: localStorage.getItem('I18N_LANGUAGE'),
  },
];
