import { forwardRef, useCallback, useEffect, useRef, useState } from 'react';

import { ReactComponent as EyeClose } from 'assets/images/icons/eye-close.svg';
import { ReactComponent as Eye } from 'assets/images/icons/eye.svg';
import classNames from 'classnames';
import i18n from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import 'reactflow/dist/style.css';

import {
  clearUserReferrals,
  getPartnersReferralTree,
  setDetailsInfo,
  setIsOpenReferralsTree,
  setPartnersReferralsTree,
} from 'store/team/actions';

import SwitchInput from 'components/Custom/SwitchInput';

import EmptyState from '../EmptyState';
import s from './ReferralTree.module.scss';
import Tree from './Tree';
import {
  calculateFullTreePosition,
  calculatePositions,
  createTreeEdges,
} from './referralsTreeUtils';

const ReferralsTree = forwardRef(({ currentUser = {}, referralsCount = 0 }, ref) => {
  const partnersReferralsTree = useSelector((state) => state.Team.partnersReferralsTree);
  const treeLoading = useSelector((state) => state.Team.partnersReferralsTreeLoading);
  const detailsInfo = useSelector((state) => state.Team.detailsInfo);
  const treeRef = useRef(null);
  const [isShowReferralsTree, setIsShowReferralsTree] = useState(false);
  const [referralsTree, setReferralsTree] = useState({ nodes: [], edges: [] });
  const [selectedNode, setSelectedNode] = useState();
  const dispatch = useDispatch();
  const [openedNode, setOpenedNode] = useState({});
  const [neededRemoveNodes, setNeededRemoveNodes] = useState([]);
  const fullUserTree = useSelector((state) => state.Team.userReferrals);
  const isRefOpenedTree = useSelector((state) => state.Team.isOpenedReferralsTree);
  const [referralsFetching, setReferralsFetching] = useState(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const params = {};
  for (let [key, value] of queryParams.entries()) {
    params[key] = value;
  }

  const handleGetPartnersReferralsTree = (id) => {
    dispatch(getPartnersReferralTree(id));
    setReferralsFetching(true);
  };
  const handleChangeDetailsInfo = (field, value) => {
    dispatch(setDetailsInfo({ ...detailsInfo, [field]: value }));
  };

  const handleOpenRefTree = useCallback(() => {
    setIsShowReferralsTree(!isShowReferralsTree);
  }, [isShowReferralsTree]);

  const handleOpenNode = useCallback((node) => {
    setOpenedNode(node);
    setNeededRemoveNodes((prevState) => [...prevState, node]);
  }, []);

  const getFullUserNodesAndEdges = useCallback(async () => {
    setReferralsTree({ nodes: [], edges: [] });

    const updatedNodes = fullUserTree?.reverse().map((referral, i) => ({
      id: referral.partner_id,
      data: {
        ...referral,
        handleOpenNode,
        type: 'target',
        disableConnectionButton: true,
      },
      type: 'customNode',
    }));

    const updatedEdges = createTreeEdges(fullUserTree?.filter((node) => !!node?.referrer_id));

    const nodesPosition = calculateFullTreePosition({ x: 0, y: 0 }, updatedNodes);

    await new Promise((resolve) => setTimeout(resolve, 0)); // Добавляем задержку для обновления состояния

    setReferralsTree({
      nodes: [...updatedNodes.map((ref, i) => ({ ...ref, position: nodesPosition[i] }))],
      edges: updatedEdges,
    });
  }, [fullUserTree, handleOpenNode]);

  const removeNodeAndDescendants = (nodes, edges, nodeId, isRoot = true) => {
    let filteredNodes = nodes.filter((node) => {
      if (isRoot && node.id === nodeId) {
        return true;
      }
      return node.id !== nodeId;
    });

    let filteredEdges = edges.filter((edge) => {
      return edge.target !== nodeId;
    });

    const children = nodes.filter((node) => node.data.referrer_id === nodeId);
    children.forEach((child) => {
      const { filteredNodes: updatedNodes, filteredEdges: updatedEdges } = removeNodeAndDescendants(
        filteredNodes,
        filteredEdges,
        child.id,
        false,
      );
      filteredNodes = updatedNodes;
      filteredEdges = updatedEdges;
    });

    return { filteredNodes, filteredEdges };
  };

  useEffect(() => {
    neededRemoveNodes?.forEach((node) => {
      if (
        node?.partner_id !== openedNode?.partner_id &&
        node?.referrer_id === openedNode?.referrer_id
      ) {
        const { filteredNodes, filteredEdges } = removeNodeAndDescendants(
          referralsTree?.nodes,
          referralsTree?.edges,
          node.partner_id,
          true,
        );

        setReferralsTree((prevState) => ({
          edges: filteredEdges,
          nodes: filteredNodes,
        }));
      }
    });
  }, [neededRemoveNodes]);

  useEffect(() => {
    if (!!partnersReferralsTree?.length) {
      setReferralsFetching(false);
      const nodes = partnersReferralsTree?.map((referral, i) => ({
        id: referral.partner_id,
        data: {
          ...referral,
          handleOpenNode,
        },
        type: 'customNode',
        ...(i === 0 && {
          targetPosition: 'bottom',
        }),
      }));

      const nodesPosition = calculatePositions(selectedNode?.position || { x: 0, y: 0 }, nodes);
      const edges = createTreeEdges(partnersReferralsTree);

      if (!!referralsTree?.nodes?.length && !!referralsTree?.edges?.length) {
        setReferralsTree((prevState) => {
          const selectedNodeIndex = prevState?.nodes.findIndex(
            (node) => node.id === selectedNode?.id,
          );
          const selectedNodeObj = prevState?.nodes[selectedNodeIndex];
          selectedNodeObj.targetPosition = 'bottom';
          selectedNodeObj.sourcePosition = 'top';
          prevState.nodes[selectedNodeIndex] = selectedNodeObj;

          return {
            nodes: [
              ...prevState?.nodes,
              ...nodes.map((ref, i) => ({ ...ref, position: nodesPosition[i] })),
            ],
            edges: [...prevState?.edges, ...edges],
          };
        });
      } else {
        setReferralsTree({
          nodes: [
            {
              id: currentUser?.partnerId,
              data: {
                partner_id: currentUser?.partnerId,
                full_name: currentUser?.fullName,
                referrals_count: referralsCount,
                ref_level: currentUser?.level,
                avatar_url: currentUser?.photo?.path || '',
                structure_investments: currentUser?.structure_investments,
                personal_investments: currentUser?.personal_investments,
              },
              type: 'customNode',
              targetPosition: 'bottom',
              position: { x: 0, y: 0 },
            },
            ...nodes.map((ref, i) => ({ ...ref, position: nodesPosition[i] })),
          ],
          edges,
        });
      }
    }
  }, [partnersReferralsTree]);

  useEffect(() => {
    if (
      !!isShowReferralsTree &&
      !partnersReferralsTree?.length &&
      !treeLoading &&
      !isRefOpenedTree &&
      !referralsFetching
    ) {
      setReferralsTree({ nodes: [], edges: [] });
      handleGetPartnersReferralsTree(currentUser?.partnerId);
    }
  }, [
    currentUser,
    handleGetPartnersReferralsTree,
    isShowReferralsTree,
    partnersReferralsTree,
    treeLoading,
  ]);

  useEffect(() => {
    if (!isShowReferralsTree) {
      dispatch(setDetailsInfo({}));
      dispatch(clearUserReferrals([]));
      dispatch(setPartnersReferralsTree());
      if (fullUserTree?.length) {
        setReferralsTree({ nodes: [], edges: [] });
      }
      dispatch(setIsOpenReferralsTree(false));
    }
  }, [dispatch, isShowReferralsTree]);

  useEffect(() => {
    if (isShowReferralsTree && !!fullUserTree?.length) {
      getFullUserNodesAndEdges();
    }
  }, [fullUserTree, getFullUserNodesAndEdges, isShowReferralsTree]);

  useEffect(() => {
    if (isRefOpenedTree) {
      setIsShowReferralsTree(true);
    }
  }, [isRefOpenedTree]);

  useEffect(() => {
    if (params?.openRef && currentUser?.partnerId) {
      setIsShowReferralsTree(true);
    }
  }, [currentUser?.partnerId, params?.openRef]);

  return (
    <div ref={ref}>
      <div className={classNames(s.wrapper, { [s.show]: isShowReferralsTree })} ref={treeRef}>
        <div>{i18n.t('team_referral_tree_title')}</div>
        <div className={s.treeFilters}>
          {isShowReferralsTree && (
            <div className={s.switchWrapper}>
              <SwitchInput
                label="common_show_photo"
                onChange={(checked) => handleChangeDetailsInfo('photo', checked)}
              />
              <SwitchInput
                label="common_show_detail_info"
                onChange={(checked) => handleChangeDetailsInfo('investInfo', checked)}
              />
            </div>
          )}
          <div className={s.showButtonWrapper}>
            <div className={s.showButton} onClick={handleOpenRefTree}>
              {i18n.t(!isShowReferralsTree ? 'team_show_referral_tree' : 'team_hide_referral_tree')}{' '}
              {!isShowReferralsTree ? (
                <Eye width={18} height={18} color="#115e5c" />
              ) : (
                <EyeClose width={18} height={18} color="#115e5c" />
              )}
            </div>
          </div>
        </div>
      </div>

      {isShowReferralsTree && referralsCount > 0 && (
        <div className={s.tree}>
          <Tree
            treeWidth={treeRef?.current?.clientWidth}
            referralTree={referralsTree}
            setSelectedNode={setSelectedNode}
          />
        </div>
      )}
      {referralsCount === 0 && isShowReferralsTree && <EmptyState withBg />}
    </div>
  );
});

export default ReferralsTree;
