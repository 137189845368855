import React, { useCallback } from 'react';

import { t } from 'i18next';
import toast from 'react-hot-toast';
import { Button, Modal, ModalBody } from 'reactstrap';

import { deleteNotificationTypeRequest } from 'services/notificationsService';

const DeleteNotificationTypesModal = ({ isOpen, closeModal, type, refresh }) => {
  const deleteNotificationTypeHandler = useCallback(async () => {
    const res = await deleteNotificationTypeRequest(type?.id);
    if (res) {
      toast.success(t('api_delete_notification_type_success'));
      closeModal();
      refresh();
    } else {
      toast.error(t('api_delete_notification_type_error'));
    }
  }, [closeModal, refresh, type?.id]);

  return (
    <Modal isOpen={isOpen} toggle={closeModal} centered>
      <ModalBody className="notifications__modal_delete_body">
        <div>
          {t('notification_delete_notification_type')} <br />
          <span>{type?.title}</span>?
        </div>
        <div className="notifications__modal_delete_btns flex-row">
          <Button color="primary" onClick={closeModal}>
            {t('common_cancel')}
          </Button>
          <Button color="danger" onClick={deleteNotificationTypeHandler}>
            {t('common_delete')}
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default DeleteNotificationTypesModal;
