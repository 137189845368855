import React, { useCallback, useEffect, useState } from 'react';

import { t } from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from 'reactstrap';

import SwitchInput from '../../../components/Custom/SwitchInput';

import {
  getNotificationsSettings,
  setNotificationsSettings,
} from '../../../store/notifications/actions';
import s from './Components.module.scss';

const NotificationsSetting = () => {
  const dispatch = useDispatch();
  const { notificationSettings, loading } = useSelector((state) => state.Notifications);
  const [settings, setSettings] = useState([]);
  const language = useSelector((state) => state.Layout.language);
  const [isSettingsChanged, setIsSettingsChanged] = useState(false);

  const handleChange = ({ type, field, value }) => {
    setSettings((prevState) => {
      return prevState.map((item) => {
        if (item?.notification_type?.id === type?.id) {
          item[field] = value;
        }
        return item;
      });
    });
    setIsSettingsChanged(true);
  };

  const handleSubmit = useCallback(() => {
    dispatch(
      setNotificationsSettings(
        settings?.map((item) => ({
          site: item?.site,
          email: item?.email,
          notification_type_id: item?.notification_type?.id,
        })),
      ),
    );
    setIsSettingsChanged(false);
  }, [dispatch, settings]);

  useEffect(() => {
    dispatch(getNotificationsSettings(language));
  }, [dispatch, language]);

  useEffect(() => {
    setSettings(
      notificationSettings?.map((item) => ({
        notification_type: item?.notification_type,
        site: item?.notification_configuration?.site,
        email: item?.notification_configuration?.email,
      })),
    );
  }, [notificationSettings]);

  return (
    <div className={s.settingBlock}>
      <div>
        <div className="notification-setting-item notification-setting-head">
          <div className={s.blockTitle}>{t('user_profile_notification_setting')}</div>
          <div className={s.label}>{t('user_profile_notification_setting_site')}</div>
          <div className={s.label}>{t('user_profile_notification_setting_email')}</div>
        </div>
        <div className="notification-setting-list">
          {settings?.map((item) => (
            <div className="notification-setting-item" key={item?.notification_type?.id}>
              <div className={s.label}>{item?.notification_type?.title}</div>
              <SwitchInput
                value={item?.site}
                size="sm"
                color="green"
                onChange={(value) =>
                  handleChange({ type: item?.notification_type, field: 'site', value })
                }
              />
              <SwitchInput
                value={item?.email}
                size="sm"
                color="green"
                onChange={(value) =>
                  handleChange({ type: item?.notification_type, field: 'email', value })
                }
              />
            </div>
          ))}
        </div>

        <button
          type="button"
          className={s.submitBtn}
          disabled={!isSettingsChanged}
          onClick={handleSubmit}>
          {loading ? (
            <div className="d-flex gap-2 justify-content-center align-items-center">
              <Spinner className="spinner" /> {t('common_save_changes')}
            </div>
          ) : (
            t('common_save_changes')
          )}
        </button>
      </div>
    </div>
  );
};

export default NotificationsSetting;
