export const INVEST_MODAL_STEPS = {
  CHOSE_PACKAGE: 'CHOSE_PACKAGE',
  CHOSE_PAYMENT_METHOD: 'CHOSE_PAYMENT_METHOD',
  PAYMENT: 'PAYMENT',
  QR: 'QR',
  WALLET_CONNECT: 'WALLET_CONNECT',
  WAITING: 'WAITING',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
  EXPIRED: 'EXPIRED',
};

export const changeInvestModalStep = (step) => {
  switch (step) {
    case INVEST_MODAL_STEPS.CHOSE_PACKAGE:
      return INVEST_MODAL_STEPS.QR;
    case INVEST_MODAL_STEPS.QR:
      return INVEST_MODAL_STEPS.WAITING;

    default:
      return INVEST_MODAL_STEPS.CHOSE_PACKAGE;
  }
};

export const PAYMENT_METHOD_WITH_STEP = {
  qrCode: INVEST_MODAL_STEPS.QR,
  walletConnect: INVEST_MODAL_STEPS.WALLET_CONNECT,
};
