import React from 'react';

import PropTypes from 'prop-types';
import { Cell, Label, Pie, PieChart, ResponsiveContainer, Sector } from 'recharts';

import { formatNumber } from '../../../helpers/formatNumber';
import { t } from '../../../i18n';
import * as S from './styled';
import './styles.scss';

const renderActiveShape = (props) => {
  const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill } = props;

  return (
    <g>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius + 5}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
    </g>
  );
};

const renderedLabel = (props) => {
  const {
    viewBox: { cx, cy },
  } = props;
  const textProps = {
    x: cx,
    y: cy - 10,
    textAnchor: 'middle',
    verticalanchor: 'middle',
    fill: '#495057',
  };

  const valueProps = {
    x: cx,
    y: cy + 20,
    textAnchor: 'middle',
    verticalanchor: 'middle',
    fill: 'black',
    fontSize: '15',
  };

  return (
    <>
      <text {...textProps}>{t('dashboard_total_amount')}</text>
      <text {...valueProps}>${+props.value.toFixed(2)}</text>
    </>
  );
};

const IncomeChart = ({ chartData, totalData }) => {
  const COLORS = [
    '#8543E0',
    '#3BA0FF',
    '#FA8C16',
    '#36CBCB',
    '#4DCB73',
    '#FAD337',
    '#3353F4',
    '#EB2F96',
    '#F04864',
  ];

  const { product, partners, total } = totalData;

  const additionCharInfo = {
    product: {
      total: product.total,
      percent: product.percent,
    },
    partners: {
      total: partners.total,
      percent: partners.percent,
    },
  };

  const productData = chartData?.filter((el) => el?.key === 'fromFinancialProducts');
  const partnersData = chartData?.filter((el) => el?.key === 'fromPartners');

  return (
    <div className="chart__wrapper">
      <div className="pie-container">
        <ResponsiveContainer>
          <PieChart>
            <Pie
              data={chartData}
              outerRadius={'100%'}
              innerRadius={'80%'}
              dataKey={'value'}
              cx="50%"
              cy="50%"
              activeShape={renderActiveShape}>
              {chartData.map((entry, index) => {
                return (
                  <Cell
                    className="some"
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                );
              })}
              <Label value={total} position="center" content={renderedLabel} />
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      </div>
      <div className="custom-legend">
        <div className="legend-container">
          <div className="legend-item legend-item-title">
            <span>
              {t('dashboard_from_partners')} <span>{additionCharInfo?.partners.percent}%</span>{' '}
            </span>
            <span>$ {additionCharInfo?.partners.total}</span>
          </div>
          {partnersData?.map((el, index) => (
            <div className="legend-item" key={index}>
              <div className="legend-item-info">
                <span
                  className="legend-color"
                  style={{ backgroundColor: COLORS[index % COLORS.length] }}></span>
                <span>{el.name}:</span>
                <span className="text-black">{`${el.percent}%`}</span>
              </div>

              <span className="legend-item-value">{`$${formatNumber(el.value)}`}</span>
            </div>
          ))}
        </div>

        <div className="legend-container">
          <div className="legend-item legend-item-title">
            <span>
              {t('dashboard_financial_product_total')} <span>{product.percent}%</span>
            </span>
            <span>$ {product.total}</span>
          </div>
          {productData?.map((el, index) => (
            <div className="legend-item" key={index}>
              <div className="legend-item-info">
                <span
                  className="legend-color"
                  style={{
                    backgroundColor: COLORS?.slice(partnersData?.length)[index % COLORS.length],
                  }}></span>
                <span>{el.name}:</span>
                <span className="text-black">{`${el.percent}%`}</span>
              </div>

              <span className="legend-item-value">{`$${formatNumber(el.value)}`}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

IncomeChart.propTypes = {
  chartData: PropTypes.array,
  totalData: PropTypes.shape({
    total: PropTypes.number,
    product: PropTypes.shape({
      percent: PropTypes.number,
      total: PropTypes.number,
    }),
    partners: PropTypes.shape({
      percent: PropTypes.number,
      total: PropTypes.number,
    }),
  }),
};

export default IncomeChart;
