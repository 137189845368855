import React, { memo } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';

import { useDebounce } from 'hooks/useDebounce';
import PropTypes from 'prop-types';
import { Trans } from 'react-i18next';

import { fetchReferralName } from 'services/authService';

import { t } from '../../i18n';
import s from './scss/AuthPages.module.scss';

const DisplayReferralName = ({ referralId, setError, setTouched }) => {
  const [referralName, setReferralName] = useState('');
  const debouncedValue = useDebounce(referralId, 500);
  const fetchDisplayReferralName = async () => {
    const res = await fetchReferralName(referralId);
    setReferralName(res.name);
    if (!res.name) {
      setError('referrerId', t('auth_register_simple_inviter_error'));
      setTouched('referrerId', true);
    }
  };

  useEffect(() => {
    if (debouncedValue) {
      fetchDisplayReferralName();
    }
  }, [debouncedValue]);

  return (
    <span className={s.referrerName}>
      {!!referralName && (
        <Trans
          i18nKey="auth_register_inviter_name"
          components={{ br: <br /> }}
          values={{ referralName }}
        />
      )}
    </span>
  );
};

DisplayReferralName.propTypes = {
  referralId: PropTypes.string,
};
export default memo(DisplayReferralName);
