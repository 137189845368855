import React, { useEffect } from 'react';

import MetaTags from 'react-meta-tags';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';

import useTranslation from '../../hooks/useTranslation';
import { fetchProfileData } from '../../store/actions';
import '../Authentication/scss/profile.scss';
import s from './UserProfileSetting.module.scss';
import { BaseInfoSetting, NotificationsSetting, PasswordSetting } from './components';

const UserProfileSetting = () => {
  const dispatch = useDispatch();
  const t = useTranslation();

  useEffect(() => {
    dispatch(fetchProfileData());
  }, [dispatch]);

  return (
    <React.Fragment>
      <MetaTags>
        <title>{t('user_profile_meta_title')}</title>
      </MetaTags>
      <div className={s.container}>
        <div className={s.wrapper}>
          <div className={s.settingBlockWrapper}>
            <BaseInfoSetting />
          </div>
          <div className={s.settingGroupWrapper}>
            <PasswordSetting />
            <NotificationsSetting />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default withRouter(UserProfileSetting);
