import React from 'react';

import AgreementModal from 'components/AgreementModal/AgreementModal';

// import SetDefaultWalletModal from '../SetDefaultWalletModal';

const Modals = () => {
  return (
    <>
      {/*<SetDefaultWalletModal />*/}
      <AgreementModal />
    </>
  );
};

export default Modals;
