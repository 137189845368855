import * as Yup from 'yup';

import { fetchReferralNameDebounced } from '../services/authService';

import { t } from '../i18n';

export const confirmSchema = Yup.object({
  email: Yup.string().email().required(t('common_email_hint_required')),
  confirm_code: Yup.string().required(t('auth_confirm_code_hint')),
});

export const registerSchema = Yup.object({
  referrerId: Yup.string().test(
    'checkCorrect',
    t('auth_register_simple_inviter_error'),
    async (value) => {
      return new Promise((resolve) => {
        fetchReferralNameDebounced(value, resolve);
      });
    },
  ),
  default_wallet_address: Yup.string()
    .matches(/^0x[a-fA-F0-9]{40}$/, t('set_default_wallet_modal_warning'))
    .required(t('set_default_wallet_modal_warning')),
  fullName: Yup.string().required(t('auth_register_full_name_hint_required')),
  password: Yup.string()
    .min(8, t('common_password_hint_small'))
    .matches(
      /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$/,
      t('common_password_hint_validation'),
    )
    .required(t('common_password_hint_required')),
  confirm_password: Yup.string()
    .required(t('common_password_hint_repeat'))
    .oneOf([Yup.ref('password'), null], t('common_password_hint_not_match')),
  country: Yup.object()
    .shape({
      value: Yup.string(),
      label: Yup.string(),
    })
    .required(t('auth_register_choose_country_hint')),
  mobile: Yup.string()
    .matches(/^(\+)[1-9][0-9\-\(\)\.]{9,15}$/, 'common_phone_number_invalid_hint')
    .required('common_phone_number_validation_hint'),
  // recaptcha: Yup.string().required().nullable(),
});
// .matches(mobileValidation, 'Must be valid number format').required("Please Enter Your Phone Number")
