import React, { useState } from 'react';

import { t } from 'i18next';
import Web3 from 'web3';

import { usdtAbi, usdtToken } from 'constants/wallet';

import s from './Payment.module.scss';

const PayButton = ({ amount, investWallet, goAwaitPayment }) => {
  const [balanceError, setBalanceError] = useState(false);
  const handlePay = async () => {
    if (typeof window.ethereum !== 'undefined') {
      try {
        const web3 = new Web3(window.ethereum);
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        const fromAddress = accounts[0];
        const amountInUSDT = web3.utils.toWei(amount, 'ether');
        const usdtContract = new web3.eth.Contract(usdtAbi, usdtToken);

        const userBalance = await usdtContract.methods.balanceOf(fromAddress).call();
        const userBalanceInEther = web3.utils.fromWei(userBalance, 'ether');

        if (parseFloat(userBalanceInEther) < parseFloat(amount)) {
          console.error('Insufficient balance!');
          setBalanceError(true);
          return;
        }

        const estimatedGas = await usdtContract.methods
          .transfer(investWallet, amountInUSDT)
          .estimateGas({ from: fromAddress });

        const gasPrice = await web3.eth.getGasPrice();

        usdtContract.methods
          .transfer(investWallet, amountInUSDT)
          .send({ from: fromAddress, gas: estimatedGas, gasPrice })
          .on('transactionHash', function (hash) {
            if (hash) {
              goAwaitPayment();
            }
          })
          .on('error', console.code);
      } catch (error) {
        console.error('Payment error:', error.code);
      }
    } else {
      console.error('MetaMask is not installed!');
    }
  };

  return (
    <div className={s.paymentButtonWrapper}>
      <button onClick={handlePay} className={s.paymentButton}>
        {t('pay_button_text')}
      </button>
      {balanceError && <div className={s.hint}>{t('pay_button_hint')}</div>}
    </div>
  );
};

export default PayButton;
