import React from 'react';
import { useMemo } from 'react';
import { useState } from 'react';

import moment from 'moment';
import PropTypes from 'prop-types';
import BootstrapTable from 'react-bootstrap-table-next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Input, Label } from 'reactstrap';

import { countryName } from 'constants/countries';

import { formatNumber } from '../../../helpers/formatNumber';
import useTranslation from '../../../hooks/useTranslation';
import { getUserReferrals, setIsOpenReferralsTree } from '../../../store/team/actions';
import '../scss/list.scss';
import CountryFlag from './CountryFlag';
import AnalysisModal from './analysis-modal';

export function headerFormatter(column, colIndex, { sortElement }) {
  return (
    <div className="head-column">
      <p>{column.text}</p>
      {sortElement}
    </div>
  );
}

const buttonAnalysis = (handleOpenAnalysisModal, row, label) => {
  return (
    <button className="table_analysis_button" onClick={() => handleOpenAnalysisModal(row)}>
      {label}
    </button>
  );
};

const List = ({
  partners,
  userPartnerId,
  fullName,
  isFirstLine,
  searchValue,
  setSort,
  setSearchValue,
  setIsFirstLine,
  treeRef,
  localizeFullName,
  isHideFirstLineCheckbox = false,
}) => {
  const t = useTranslation();
  const dispatch = useDispatch();
  const isDesktop = useSelector((state) => state.Layout.isDesktop);
  const [openedAnalysisModal, setOpenedAnalysisModal] = useState({ opened: false, data: null });
  const language = useSelector((state) => state.Layout.language);
  const handleOpenAnalysisModal = (referralData) => {
    setOpenedAnalysisModal({ opened: true, data: referralData });
  };

  const handleGoToTree = (id) => {
    if (treeRef.current) {
      const scrollOffset = -100;
      const elementRect = treeRef.current.getBoundingClientRect();
      const topPosition = elementRect.top + window.pageYOffset + scrollOffset;
      window.scrollTo({ top: topPosition, behavior: 'smooth' });
    }

    dispatch(setIsOpenReferralsTree(true));
    dispatch(getUserReferrals(id));
  };

  const columns = useMemo(
    () => [
      {
        dataField: 'full_name',
        text: t('tables_title.partner_name'),
        sort: true,
        attrs: {
          'data-label': t('tables_title.partner_name'),
        },
        headerFormatter,
        formatter: (cell, row) => {
          const partnerFullName =
            language === 'ru' ? row.referrer_full_name : row.referrer_localized_full_name;

          return (
            <>
              <div className="fw-bold">{language === 'ru' ? cell : row.localized_full_name}</div>
              <div>
                <div
                  dangerouslySetInnerHTML={{
                    __html: t('team_referrer_name', {
                      partnerFullName,
                      interpolation: { escapeValue: false },
                    }),
                  }}></div>
              </div>
            </>
          );
        },
      },
      {
        dataField: 'mobile',
        text: t('tables_title.mobile'),
        sort: true,
        attrs: {
          'data-label': t('tables_title.mobile'),
        },
        headerFormatter,
        formatter: (cell, row) => {
          if (row.is_data_hidden) {
            return t('common_hidden');
          } else {
            return cell;
          }
        },
      },
      {
        dataField: 'partner_id',
        text: t('tables_title.partner_id'),
        attrs: {
          'data-label': t('tables_title.partner_id'),
        },
        formatter: (cell) => (
          <div className="partnerIdWrapper">
            {cell}
            <button className="showTreeBtn" onClick={() => handleGoToTree(cell)}>
              {t('open_tree')}
            </button>
          </div>
        ),
      },
      {
        dataField: 'created_at',
        text: t('tables_title.register_date'),
        sort: true,
        attrs: {
          'data-label': t('tables_title.register_date'),
        },
        headerFormatter,
        formatter: (cell) => {
          return <>{moment(cell).format('DD.MM.YYYY')}</>;
        },
      },
      {
        dataField: 'level',
        text: t('tables_title.user_level'),
        sort: true,
        attrs: {
          'data-label': t('tables_title.user_level'),
        },
        headerFormatter,
        formatter: (cell) => Number(cell) + 1,
      },
      {
        dataField: 'total_referrals',
        text: t('tables_title.referrals_amount'),
        attrs: {
          'data-label': t('tables_title.referrals_amount'),
        },
        sort: true,
        headerFormatter,
        formatter: (cell, row) => {
          const redirectPath = `/team/structure/${row.partner_id}`;
          return (
            <>
              {cell}{' '}
              {parseInt(cell) !== 0 && (
                <Link
                  to={redirectPath}
                  target={isDesktop ? '' : '_blank'}
                  style={{ color: '#0c958f' }}>
                  {t('common_structure')}
                </Link>
              )}
            </>
          );
        },
      },
      {
        dataField: 'country',
        sort: true,
        attrs: {
          'data-label': t('tables_title.country'),
        },
        text: t('tables_title.country'),
        headerFormatter,
        formatter: (country) => {
          return <CountryFlag countryCode={country} title={countryName(t)[country]} />;
        },
      },
      {
        dataField: 'deposit_amount',
        sort: true,
        attrs: {
          'data-label': t('tables_title.deposit_amount'),
        },
        text: t('tables_title.deposit_amount'),
        headerFormatter,
        formatter: (cell, row) => {
          if (row.is_data_hidden) {
            return t('common_hidden');
          } else {
            return formatNumber(Number(cell), 2);
          }
        },
      },
      {
        dataField: 'team_deposit',
        sort: true,
        attrs: {
          'data-label': t('tables_title.structure_amount'),
        },
        text: t('tables_title.structure_amount'),
        headerFormatter,
        formatter: (cell, row) => {
          return formatNumber(Number(cell || 0), 2);
        },
      },
      {
        text: '',
        dataField: 'id',
        formatExtraData: localStorage.getItem('I18N_LANGUAGE'),
        formatter: (cell, row) => {
          const showAnalysisButton =
            row?.first_referrals > 0 ||
            Number(row?.deposit_amount) > 0 ||
            Number(row?.teamDeposit) > 0;
          if (showAnalysisButton) {
            return buttonAnalysis(handleOpenAnalysisModal, row, t('team_analysis_button_label'));
          } else {
            return null;
          }
        },
      },
    ],
    [t, userPartnerId, fullName, language, handleGoToTree, isDesktop],
  );

  const handleTableChange = (type, { sortField, sortOrder }) => {
    if (type === 'sort') {
      setSort({ field: sortField, order: sortOrder });
    }
  };

  return (
    <>
      <div className="d-flex justify-content-between align-items-center py-3 team-filters">
        {!isHideFirstLineCheckbox && (
          <Label>
            <Input
              type="checkbox"
              checked={isFirstLine}
              onChange={(e) => setIsFirstLine(e.target.checked)}
            />{' '}
            <span>{t('team_first_line_checkbox_label')}</span>
          </Label>
        )}
        <Input
          style={{
            maxWidth: '200px',
          }}
          placeholder={t('common_search')}
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
        />
      </div>
      <BootstrapTable
        keyField="id"
        data={partners}
        columns={columns}
        headerClasses="table-head"
        wrapperClasses="table-responsive"
        classes="table__adaptive_bordered_between table-without_padding"
        remote
        onTableChange={handleTableChange}
        responsive
      />
      {openedAnalysisModal?.opened && (
        <AnalysisModal
          isOpen={openedAnalysisModal?.opened}
          data={openedAnalysisModal?.data}
          setIsOpen={setOpenedAnalysisModal}
        />
      )}
    </>
  );
};

List.propTypes = {
  partners: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  fullName: PropTypes.any,
  userPartnerId: PropTypes.any,
  isFirstLine: PropTypes.bool,
  setIsFirstLine: PropTypes.func,
  searchValue: PropTypes.string,
  setSearchValue: PropTypes.func,
};

export default withRouter(List);
