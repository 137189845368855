import i18next from 'i18next';
import LngDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import ar from './locales/ar/translation.json';
import de from './locales/de/translation.json';
import en from './locales/en/translation.json';
import es from './locales/es/translation.json';
import fr from './locales/fr/translation.json';
import hi from './locales/hi/translation.json';
import it from './locales/it/translation.json';
import ka from './locales/ka/translation.json';
import kk from './locales/kk/translation.json';
import ru from './locales/rs/translation.json';
import tr from './locales/tr/translation.json';
import uz from './locales/uz/translation.json';
import zhCN from './locales/zh/translation.json';

// the translations
const resources = {
  en,
  ru,
  de,
  es,
  fr,
  hi,
  it,
  kk,
  ar,
  tr,
  ka,
  uz,
  zh: zhCN,
};

const LANGUAGES = [
  'az',
  'hy',
  'be',
  'ka',
  'ky',
  'mo',
  'tg',
  'tk',
  'uk',
  'uk-UA',
  'uk-RU',
  'ru-RU',
  'zh-CN',
  'zh',
];

localStorage.removeItem('i18nextLng');

i18next
  .use(initReactI18next)
  .use(LngDetector)
  .init({
    fallbackLng: 'en',
    resources,
    detection: {
      caches: false,
    },
  });

if (localStorage.getItem('I18N_LANGUAGE')) {
  i18next.changeLanguage(localStorage.getItem('I18N_LANGUAGE'));
} else {
  if (LANGUAGES.includes(navigator.language)) {
    i18next.changeLanguage('ru');
  }
}

export const t = (key, params) => String(i18next.t(key, { ...params }));
