export function getNumberWithSuffix(number, lng) {
  const suffixes = {
    ru: ['-я', '-я', '-я', '-я', '-я'],
    en: ['st', 'nd', 'rd', 'th', 'th'],
    de: ['.ter', '.ter', '.ter', '.ter', '.ter'],
    es: ['º', 'º', 'º', 'º', 'º'],
    fr: ['er', 'e', 'e', 'e', 'e'],
    hi: ['वाँ', 'रा', 'रा', 'था', 'वाँ'],
    it: ['°', '°', '°', '°', '°'],
    kk: ['-ші', '-ші', '-ші', '-ші', '-ші'],
    ar: ['أول', 'ثاني', 'ثالث', 'رابع', 'خامس'],
    tr: ['.inci', '.nci', '.üncü', '.üncü', '.inci'],
    ka: ['-ლი', '-ე', '-ე', '-ე', '-ე'],
    uz: ['-inchi', '-inchi', '-inchi', '-inchi', '-inchi'],
  };

  if (!suffixes[lng] || number < 1 || number > 5) {
    console.warn(`Language '${lng}' not supported or number '${number}' out of range.`);
    return number;
  }

  return number + suffixes[lng][number - 1];
}
