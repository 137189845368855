import React from 'react';

import { t } from '../../i18n';

const SwitchInput = ({ onChange, value, label, size, color = 'green', ...props }) => {
  const handleChange = (e) => {
    onChange(e.target.checked);
  };

  return (
    <label className={`switch-label ${size} ${color}`}>
      <input
        className="sr-only"
        type="checkbox"
        checked={value}
        onChange={handleChange}
        {...props}
      />
      <div className="slider"></div>
      <span className="label-switch-input">{t(label)}</span>
    </label>
  );
};

export default SwitchInput;
