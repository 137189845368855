import React, { useCallback, useEffect, useState } from 'react';

import { API_URL, post } from 'helpers/api_helper';
import { Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Input, Modal, Spinner } from 'reactstrap';

import { t } from '../../../../i18n';
import {
  resendConfirmCode,
  sendConfirmCode,
  sendConfirmCodeSuccessful,
} from '../../../../store/users/actions';

const ConfirmChangeDefaultWalletModal = ({ isOpen, wallet, closeModal, hideSaveButton }) => {
  const dispatch = useDispatch();
  const [error, setError] = useState(null);
  const [confirm, setConfirm] = useState(false);
  const currentUser = useSelector((state) => state.Profile.user);
  const confirmedSuccess = useSelector((state) => state.Users.confirmedSuccess);
  const [verifyCode, setVerifyCode] = useState('');
  const { errorLoading, loading } = useSelector((state) => ({
    errorLoading: state.Users.confirmedError,
    loading: state.Users.confirmedLoading,
  }));

  const changeDefaultWallet = async () => {
    const response = await post(`${API_URL}/change-default-wallet-addr`, {
      new_wallet: wallet,
    });
    if (response.status === 200) {
      hideSaveButton(true);
      return setConfirm(true);
    }
    setError(true);
  };

  const verificationHandler = useCallback(() => {
    dispatch(
      sendConfirmCode({
        type: 'confirm_wallet',
        code: verifyCode?.replace('-', ''),
        email: currentUser?.email,
        value: wallet,
      }),
    );
  }, [dispatch, verifyCode, currentUser?.email, wallet]);

  const resendVerificationCode = useCallback(() => {
    dispatch(
      resendConfirmCode({
        type: 'confirm_wallet',
        email: currentUser?.email,
        value: wallet,
      }),
    );
  }, [currentUser?.email, dispatch, wallet]);

  const handleChange = (event) => {
    let value = event.target.value.replace(/\D/g, '');

    if (value.length > 6) {
      value = value.substring(0, 6);
    }

    if (value.length > 3) {
      value = value.substring(0, 3) + '-' + value.substring(3);
    }

    setVerifyCode(value);
  };

  const closeHandler = () => {
    setError(null);
    closeModal(() => setConfirm(false));
    setVerifyCode('');
  };

  useEffect(() => {
    if (confirmedSuccess) {
      closeHandler();
      dispatch(sendConfirmCodeSuccessful(false));
    }
  }, [closeHandler, confirmedSuccess]);

  return (
    <Modal centered isOpen={isOpen} toggle={closeHandler} className="users_change_wallet_wrapper">
      <span
        onClick={closeHandler}
        className="position-absolute p-8 bg-white rounded-circle d-flex justify-content-center align-items-center w-20 h-20"
        style={{
          cursor: 'pointer',
          zIndex: '1000',
          top: '-12px',
          right: '-12px',
          width: '25px',
          height: '25px',
          fontSize: '20px',
        }}>
        x
      </span>
      <div className="p-4 text-center">
        {!confirm && isOpen && (
          <>
            <h4 className="users_change_wallet_title">{t('user_profile_change_default_wallet')}</h4>
            <p className="users_change_wallet_text">
              <Trans
                i18nKey="change_wallet_modal_hint"
                components={{ b: <span className="users_change_wallet_address" /> }}
                values={{ wallet: wallet }}
              />
            </p>

            <div className="users_change_wallet_actions">
              <Button
                className="users_change_wallet_action"
                color="success"
                onClick={changeDefaultWallet}>
                {t('common_yes')}
              </Button>
              <Button className="users_change_wallet_action" color="danger" onClick={closeHandler}>
                {t('common_no')}
              </Button>
            </div>
          </>
        )}
        {confirm && (
          <div>
            <h4 className="users_change_wallet_title">{t('common_operation_confirmation')}</h4>
            {errorLoading ? <div className="alert-warning mb-2">{`${t(errorLoading)}`}</div> : null}
            <p className="users_change_wallet_text">
              {t('auth_recover_password_text', { address: currentUser?.email })}
            </p>
            <div className="d-flex justify-content-center">
              <Input
                className="auth-input"
                style={{ textAlign: 'center' }}
                onChange={handleChange}
                value={verifyCode}
                placeholder="XXX-XXX"
              />
            </div>
            <div className="mt-4 d-flex flex-column align-items-center w-100">
              <button className="auth-link-resend" onClick={resendVerificationCode}>
                {t('auth_verify_your_email_resend')}
              </button>
              <button
                disabled={verifyCode?.length < 7}
                onClick={verificationHandler}
                className="users_change_wallet_btn">
                {loading ? (
                  <div className="d-flex gap-2 justify-content-center align-items-center">
                    <Spinner className="spinner" /> {t('common_complete')}
                  </div>
                ) : (
                  t('common_complete')
                )}
              </button>
            </div>
          </div>
        )}
        {error && <p>{t('change_wallet_modal_operation_error')}</p>}
      </div>
    </Modal>
  );
};

export default ConfirmChangeDefaultWalletModal;
