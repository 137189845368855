import { investorProAllLimit, investorProPerUserLimit } from 'constants/deposit';

/**
 * @type {(investorLimits : import("../pages/Projects/hooks/useIsProjectDisabled").InvestorProLimit) => boolean}
 */
export const isInvestorProLimitExceeded = ({ allPackages, perUser }) =>
  allPackages >= investorProAllLimit || perUser >= investorProPerUserLimit;

export const extractPackageId = (string) => {
  if (string.includes('-')) {
    const parts = string.split('-');
    const number = parts[parts.length - 1];
    return Number(number);
  } else {
    return Number(string);
  }
};
