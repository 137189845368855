import React, { memo, useCallback, useEffect, useState } from 'react';

import { ReactComponent as Connection } from 'assets/images/icons/conection.svg';
import { getFirstLetterFromFullName } from 'helpers/Utils/user';
import { formatNumber } from 'helpers/formatNumber';
import { useDispatch, useSelector } from 'react-redux';
import { Handle, Position } from 'reactflow';

import userService from 'services/userService';
import { getPartnersReferralTree, setIsOpenReferralsTree } from 'store/team/actions';

import s from './Tree.module.scss';
import { DETAILS_INFO_CONTENT } from './utils';

export default memo(({ data, isConnectable }) => {
  const dispatch = useDispatch();
  const detailsInfo = useSelector((state) => state.Team.detailsInfo);
  const language = useSelector((state) => state.Layout.language);
  const [lang, setLang] = useState('en');
  const [photo, setPhoto] = useState(null);
  const [isPhotoLoaded, setIsPhotoLoaded] = useState(false);
  const isAgreement = Number(data?.agreement) === 1;

  const goToStructure = useCallback(
    (isOpenReferralsTree = false) => {
      const query = isOpenReferralsTree ? `?openRef=true` : '';
      window.open(
        `${process.env.REACT_APP_BASE_URL}/team/structure/${data?.partner_id}${query}`,
        '_blank',
      );
    },
    [data],
  );

  const handleGetReferralsTree = useCallback(() => {
    if (data.referrals_count > 0 && !data?.disableConnectionButton) {
      data?.handleOpenNode(data);
      dispatch(getPartnersReferralTree(data?.partner_id));
    } else if (data?.disableConnectionButton && data?.referrals_count > 0) {
      goToStructure(true);
    }
  }, [data, dispatch, goToStructure]);

  const getReferralPhoto = useCallback(async () => {
    if (data?.avatar_url) {
      const res = await userService.getPhoto(data?.avatar_url);
      if (res) {
        setPhoto(res);
      }
      setIsPhotoLoaded(true);
    }
  }, [data]);

  useEffect(() => {
    setLang(language);
  }, [language]);

  useEffect(() => {
    if (detailsInfo?.photo && !photo && !isPhotoLoaded) {
      getReferralPhoto();
    }
  }, [detailsInfo?.photo, getReferralPhoto, photo]);

  return (
    <>
      <Handle
        type="source"
        position={Position.Top}
        id={data.partner_id}
        style={{ background: '#555' }}
        onConnect={(params) => console.log('handle onConnect', params)}
        isConnectable={isConnectable}
      />
      <div className={s.wrapper}>
        <div className={s.block}>
          <div className={s.content}>
            <div>
              <div className={s.name}>{data?.full_name}</div>
              <div className={s.refId}>{data?.partner_id}</div>
            </div>
            {!detailsInfo?.investInfo && !data?.disableConnectionButton ? (
              <div>
                <button onClick={handleGetReferralsTree} className={s.count}>
                  <Connection />
                  {data?.referrals_count}
                </button>
              </div>
            ) : !detailsInfo?.investInfo && data?.disableConnectionButton ? (
              <div>
                <button onClick={handleGetReferralsTree} className={s.count}>
                  <Connection />
                  {data?.referrals_count}
                </button>
              </div>
            ) : null}
          </div>
          <div className={s.levelInfo}>
            {detailsInfo?.photo ? (
              photo ? (
                <img src={photo} alt={data?.full_name} className={s.photo} />
              ) : (
                <div className={s.levelCount}>{getFirstLetterFromFullName(data?.full_name)}</div>
              )
            ) : (
              <div className={s.levelCount}>{Number(data?.level || data?.ref_level) + 1}</div>
            )}
            <div className={s.levelLabel}>
              {detailsInfo?.photo ? `Level ${Number(data?.level || data?.ref_level) + 1}` : 'Level'}
            </div>
          </div>
        </div>
        {detailsInfo.investInfo && (
          <div>
            <div className={s.item}>
              <div>{DETAILS_INFO_CONTENT[lang].structureInvestments}</div>
              <div>{formatNumber(data?.structure_investments)}</div>
            </div>
            <div className={s.item}>
              <div>{DETAILS_INFO_CONTENT[lang].personalInvestments}</div>
              <div>
                {isAgreement
                  ? formatNumber(data?.personal_investments)
                  : DETAILS_INFO_CONTENT[lang].dataHidden}
              </div>
            </div>

            <div className={s.item}>
              <button onClick={handleGetReferralsTree} className={s.count}>
                <Connection />
                {data?.referrals_count}
              </button>

              <div className={s.link} onClick={goToStructure}>
                {DETAILS_INFO_CONTENT[lang].structureButton}
              </div>
            </div>
          </div>
        )}
      </div>
      {data?.type === 'target' && (
        <Handle
          type="target"
          position={Position.Bottom}
          id={data.partner_id}
          style={{ background: '#555' }}
          onConnect={(params) => console.log('handle onConnect', params)}
          isConnectable={isConnectable}
        />
      )}
    </>
  );
});
