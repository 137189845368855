import React, { useCallback, useEffect, useState } from 'react';

import classNames from 'classnames';
import cn from 'classnames';
import { formatNumber } from 'helpers/formatNumber';
import useTranslation from 'hooks/useTranslation';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { getInvestedFundsAnalytics, getInvestedFundsAnalyticsDetailed } from 'store/team/actions';

import EmptyState from 'components/EmptyState';

import s from '../AnalysisModal.module.scss';
import { CustomTooltip } from '../index';
import CustomLegend from './CustomLegend';
import { hasDuplicateDate } from './utils';

const InvestedFundsAnalytics = ({
  referralId,
  activeYear,
  projects,
  filter,
  selectedDates: externalSelectedDates,
  groupBy = 'month',
}) => {
  const t = useTranslation();
  const dispatch = useDispatch();
  const [showInvestedDetails, setShowInvestedDetails] = useState(false);
  const investedFundsAnalytics = useSelector((state) => state.Team.investedFundsAnalytics);
  const investedFundsAnalyticsLoading = useSelector(
    (state) => state.Team.investedFundsAnalyticsLoading,
  );
  const [isSingleDateView, setIsSingleDateView] = useState(false);
  const investedFundsAnalyticsDetails = useSelector(
    (state) => state.Team.investedFundsAnalyticsDetails,
  );
  const investChartData = investedFundsAnalytics?.data?.map((item) => ({
    investedStructure: Number(item.invested_structure),
    investedPersonal: Number(item.invested_personal),
    name: moment(item.date).format('DD.MM.YYYY'),
  }));
  const [selectedDates, setSelectedDates] = useState([]);

  const handleDetailsClick = useCallback(
    (item, index) => {
      setShowInvestedDetails(true);

      const getDates = () => {
        const clickedDateObj = moment(item?.date, 'YYYY-MM-DD').startOf('day');

        const clickedMonth = clickedDateObj.month();
        const clickedYear = clickedDateObj.year();

        const sameMonthObjects = investedFundsAnalytics?.data?.filter((item) => {
          const itemDateObj = moment(item.date, 'YYYY-MM-DD');
          return itemDateObj.month() === clickedMonth && itemDateObj.year() === clickedYear;
        });

        if (sameMonthObjects.length > 1) {
          const date = moment(item?.date).format('YYYY-MM-DDTHH:mm:ss');
          setSelectedDates([date]);
          return [date];
        } else {
          const firstDayOfMonth = clickedDateObj.startOf('month').format('YYYY-MM-DDTHH:mm:ss');
          const lastDayOfMonth = clickedDateObj
            .endOf('month')
            .startOf('day')
            .format('YYYY-MM-DDTHH:mm:ss');
          setSelectedDates([firstDayOfMonth, lastDayOfMonth]);
          return [firstDayOfMonth, lastDayOfMonth];
        }
      };

      const [firstDay, lastDay] = getDates();

      dispatch(
        getInvestedFundsAnalyticsDetailed(
          referralId,
          firstDay,
          lastDay,
          projects?.map((item) => item?.service_name),
        ),
      );
    },
    [dispatch, investedFundsAnalytics, projects, referralId],
  );

  useEffect(() => {
    if (!!filter?.projects?.length) {
      const firstDay = externalSelectedDates?.from
        ? moment(externalSelectedDates?.from).startOf('day').format('YYYY-MM-DDTHH:mm:ss')
        : moment().subtract(1, 'years').startOf('day').format('YYYY-MM-DDTHH:mm:ss');
      const lastDay = externalSelectedDates?.to
        ? moment(externalSelectedDates?.to).startOf('day').format('YYYY-MM-DDTHH:mm:ss')
        : moment().startOf('day').format('YYYY-MM-DDTHH:mm:ss');

      dispatch(getInvestedFundsAnalytics(referralId, filter?.projects, firstDay, lastDay, groupBy));
    }
  }, [dispatch, referralId, filter, externalSelectedDates, groupBy]);

  useEffect(() => {
    if (investedFundsAnalytics) {
      setIsSingleDateView(hasDuplicateDate(investedFundsAnalytics?.data || []));
    }
  }, [investedFundsAnalytics]);

  return (
    <>
      <div className={s.chartWrapper}>
        {!!investChartData?.length ? (
          <ResponsiveContainer className={s.chart}>
            <LineChart
              data={investChartData}
              margin={{
                top: 5,
                right: -15,
                left: -5,
                bottom: 5,
              }}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip content={CustomTooltip} />
              <Legend
                wrapperStyle={{
                  display: 'flex',
                  justifyContent: 'center',
                  width: '-webkit-fill-available',
                  left: 'unset',
                }}
                content={CustomLegend}
              />
              <Line
                type="monotone"
                dataKey="investedPersonal"
                stroke="#556EE6"
                activeDot={{ r: 8 }}
              />
              <Line type="monotone" dataKey="investedStructure" stroke="#F1B44C" />
            </LineChart>
          </ResponsiveContainer>
        ) : (
          <EmptyState />
        )}
      </div>
      <div className={s.tableWrapper}>
        <div className={s.table}>
          <div className={s.row}>
            <div className={s.item}>{t('tables_title.date')}</div>
            <div className={s.item}>
              <span className={classNames(s.circle, s.blue)} />{' '}
              <div className={s.label}>{t('common_personal_sales')}</div>
            </div>
            <div className={s.item}>
              <span className={classNames(s.circle, s.yellow)} />{' '}
              <div className={s.label}>{t('common_structure_sales')}</div>
            </div>
          </div>
          {(investedFundsAnalytics?.personal_summary > 0 ||
            investedFundsAnalytics?.structure_summary > 0) && (
            <div className={s.row}>
              <div className={cn(s.item, s.summary)}>{t('team_modal_summary_period')}</div>
              <div className={cn(s.item, s.summary)}>
                <div className={s.label}>
                  {formatNumber(investedFundsAnalytics?.personal_summary)} USDT
                </div>
              </div>
              <div className={cn(s.item, s.summary)}>
                <div className={s.label}>
                  {formatNumber(investedFundsAnalytics?.structure_summary)} USDT
                </div>
              </div>
            </div>
          )}
          {!!investedFundsAnalytics?.data?.length ? (
            investedFundsAnalytics?.data?.map((item, i) => (
              <div key={`${i}_${item.date}`}>
                <div className={s.row}>
                  <div className={classNames(s.item, s.bold)}>
                    {moment(item.date).format(isSingleDateView ? 'DD.MM.YYYY' : "MMM 'YY")}
                  </div>
                  <div
                    className={cn(s.item, s.personalDetails)}
                    onClick={() => handleDetailsClick(item, i)}>
                    {formatNumber(item.invested_personal)} USDT
                  </div>
                  <div className={s.item}>{formatNumber(item.invested_structure)} USDT</div>
                </div>
              </div>
            ))
          ) : (
            <EmptyState />
          )}
        </div>
      </div>

      {showInvestedDetails && !!investedFundsAnalyticsDetails?.length && (
        <div>
          <div className={s.overlay} onClick={() => setShowInvestedDetails(false)}></div>
          <div className={s.detailsContainer}>
            <div className={s.detailsTitle}>
              {t('common_personal_sales')}{' '}
              <span className={s.dates}>
                {selectedDates[0] && moment(selectedDates[0]).format('DD.MM.YYYY')}{' '}
                {selectedDates[1] && ` - ${moment(selectedDates[1]).format('DD.MM.YYYY')}`}
              </span>
            </div>

            <div className={s.table}>
              <div className={s.row}>
                <div className={s.item}>{t('tables_title.date')}</div>
                <div className={s.item}>
                  <div>{t('common_product')}</div>
                </div>
                <div className={s.item}>
                  <div>{t('common_amount')}</div>
                </div>
              </div>
              {investedFundsAnalyticsDetails?.map((item, j) => (
                <div className={s.row}>
                  <div className={classNames(s.item, s.bold)}>
                    {moment(item.date).format('DD.MM.YYYY')}
                  </div>
                  <div className={s.item}>{t(`strapi_${item?.product_service_description}`)}</div>
                  <div className={s.item}>{formatNumber(item.currency_amount)} USDT</div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
      {investedFundsAnalyticsLoading && (
        <div className={s.loaderWrapper}>
          <span className={s.loader}></span>
        </div>
      )}
    </>
  );
};

export default InvestedFundsAnalytics;
