import { useEffect, useState } from 'react';

import classNames from 'classnames';

import useTranslation from '../../hooks/useTranslation';
import s from './Tabs.module.scss';

const capitalizeFirstWord = (text) => {
  return text.charAt(0).toUpperCase() + text.slice(1);
};

const Tabs = ({ tabs, onChange, defaultTab = 0 }) => {
  const [activeTab, setActiveTab] = useState();
  const t = useTranslation();

  const handleAddTab = (tab) => {
    setActiveTab(tab);
    onChange(tab);
  };

  useEffect(() => {
    setActiveTab(tabs[0]?.value);
    onChange(tabs[0]?.value);
  }, [onChange, tabs]);

  return (
    <div className={s.tabsWrapper}>
      {tabs?.map((tab) => (
        <button
          key={tab?.value}
          className={classNames(s.tabsItem, { [s.active]: activeTab === tab?.value })}
          onClick={() => handleAddTab(tab?.value)}>
          <span className={classNames(s.label, { [s.active]: activeTab === tab?.value })}>
            {capitalizeFirstWord(t(tab.label))}
          </span>
        </button>
      ))}
    </div>
  );
};

export default Tabs;
