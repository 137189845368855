import axios from 'axios';

export const getUserDefaultCountry = async () => {
  const {
    data: { ip },
  } = await axios.get('https://api64.ipify.org?format=json');

  if (!!ip) {
    const { data, status } = await axios.get(`https://api.iplocation.net/?cmd=ip-country&ip=${ip}`);

    if (status === 200) {
      return {
        name: data?.country_name,
        code: data?.country_code2,
      };
    }
  }
};
