/* eslint-disable react/jsx-no-undef */
import React from 'react';

import {
  addHeaderFormatterToTableColumns,
  headerFormatterCenteredTitle,
} from 'helpers/table_helper';
import i18next from 'i18next';
import moment from 'moment';

import ShortWallet from 'components/SharedAcrrualsPage/components/ShortWallet';

import s from './AccrualsTable.module.scss';

export const DISPLAYED_KEY_NAMES = {
  payment_date: i18next.t('tables_title.date'),
  partnerFullName: i18next.t('tables_title.partner_name'),
  partnerId: i18next.t('tables_title.partner_id'),
  wallet_addr: i18next.t('tables_title.address'),
  amount: i18next.t('tables_title.amount'),
  percent: i18next.t('tables_title.percentage'),
  accrual_type: i18next.t('tables_title.type'),
  product: i18next.t('tables_title.product'),
  status: i18next.t('tables_title.status'),
  packageLevel: i18next.t('tables_title.package_number'),
};

export const columns = (t, language) =>
  addHeaderFormatterToTableColumns(
    [
      {
        dataField: 'payment_date',
        text: t('tables_title.date'),
        sort: true,
        attrs: {
          'data-label': t('tables_title.date'),
        },
        formatter: (row) => {
          return <div>{moment(row).format('DD.MM.YYYY')}</div>;
        },
      },
      {
        dataField: 'user_partner.full_name',
        sort: true,
        text: t('tables_title.partner_name'),
        attrs: {
          'data-label': t('tables_title.partner_name'),
        },
        formatter: (row, cell) => {
          const localizedName = cell.user_partner?.localized_full_name;

          return <div>{language === 'ru' ? row : localizedName || '-'}</div>;
        },
      },
      {
        dataField: 'user_partner.partner_id',
        sort: true,
        text: t('tables_title.partner_id'),
        attrs: {
          'data-label': t('tables_title.partner_id'),
        },
        formatter: (row) => <div>{row || '-'}</div>,
      },
      {
        dataField: 'wallet_addr',
        sort: true,
        text: t('tables_title.address'),
        attrs: {
          'data-label': t('tables_title.address'),
        },
        classes: 'wallet__addr_col',
        formatter: (cell, row) => <ShortWallet id={row.id} wallet={cell} />,
      },
      {
        dataField: 'amount',
        text: t('tables_title.amount'),
        sort: true,
        attrs: {
          'data-label': t('tables_title.amount'),
        },
        formatter: (row) => {
          const roundedValue = Math.ceil(row * 1000) / 1000;
          return <div className="text-bold">{roundedValue} USDT</div>;
        },
      },
      {
        sort: true,
        dataField: 'percent',
        text: t('tables_title.percentage'),
        attrs: {
          'data-label': t('tables_title.percentage'),
        },
        formatter: (row) => {
          return <div>{row}%</div>;
        },
      },
      {
        dataField: 'accrual_type',
        sort: true,
        text: t('tables_title.type'),
        attrs: {
          'data-label': t('tables_title.type'),
        },
        formatter: (row) => {
          const values = {
            product: t('tables_title.product_type'),
            referral: t('tables_title.referral'),
            upgrade: t('tables_title.upgrade'),
            passive: t('tables_title.passive'),
          };
          return <div>{values[row]}</div>;
        },
      },
      {
        dataField: 'product',
        text: t('tables_title.product'),
        sort: true,
        attrs: {
          'data-label': t('tables_title.product'),
        },
        formatter: (row, cell) => {
          return (
            <div>
              {cell?.accrual_type === 'product' ? (
                <div className={s.product}>
                  <div className={s.item}>
                    <div>{t('tables_title.product')}:</div>{' '}
                    <div className={s.value}>{t(`strapi_${row?.product_service_description}`)}</div>
                  </div>
                  <div className={s.item}>
                    <div>{t('tables_title.package_number')}:</div>
                    <div className={s.value}>{row?.user_package_number}</div>
                  </div>
                </div>
              ) : (
                <div>{t(`strapi_${row?.product_service_description}`)}</div>
              )}
            </div>
          );
        },
      },
      {
        dataField: 'status',
        text: t('tables_title.status'),
        sort: true,
        attrs: {
          'data-label': t('tables_title.status'),
        },
        formatter: (row) => {
          const values = {
            sent: t('tables_title.sent'),
            waiting: t('tables_title.waiting'),
            error: t('tables_title.error'),
            nulled: t('tables_title.nulled'),
          };
          return <div>{values[row]}</div>;
        },
      },
    ],
    headerFormatterCenteredTitle,
  );
