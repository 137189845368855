import React, { useCallback, useEffect, useState } from 'react';

import { t } from 'i18next';
import toast from 'react-hot-toast';
import { Button, Input, Label, Modal, ModalBody } from 'reactstrap';

import {
  createNotificationTypeRequest,
  editNotificationTypeRequest,
  getOneNotificationTypeRequest,
} from 'services/notificationsService';

const CreateNotificationTypesModal = ({ isOpen, closeModal, refresh, editTypeId }) => {
  const [typeTitle, setTypeTitle] = useState('');
  const [isInvalid, setIsInvalid] = useState(false);

  const handleChange = useCallback((e) => {
    const value = e.target.value;
    const containsCyrillic = /[А-Яа-яЁё]/.test(value);

    if (containsCyrillic) {
      setIsInvalid(true);
    } else {
      setIsInvalid(false);
    }

    setTypeTitle((prevState) => value);
  }, []);

  const handleSubmit = useCallback(async () => {
    let res;

    if (editTypeId) {
      res = await editNotificationTypeRequest(editTypeId, {
        title: typeTitle,
      });
    } else {
      res = await createNotificationTypeRequest({
        title: typeTitle,
      });
    }

    if (res?.data?.id) {
      if (editTypeId) {
        toast.success(t('api_edit_notification_type_success'));
      } else {
        toast.success(t('api_create_notification_type_success'));
      }
      closeModal();
      setTypeTitle('');
      refresh();
    } else {
      if (editTypeId) {
        toast.error(t('api_edit_notification_type_error'));
      } else {
        toast.error(t('api_create_notification_type_error'));
      }
    }
  }, [closeModal, refresh, typeTitle]);

  useEffect(() => {
    if (isOpen && editTypeId) {
      getOneNotificationTypeRequest(editTypeId).then((res) => {
        if (res?.id) {
          setTypeTitle(res?.title);
        }
      });
    }
  }, [editTypeId, isOpen]);

  return (
    <Modal isOpen={isOpen} toggle={closeModal} centered>
      <ModalBody className="notifications__modal_delete_body">
        <div className="create_notification_type_modal_content">
          <div className="w-100 d-flex flex-column align-items-start">
            <Label className="font-size-13">{t('common_type_title')}</Label>
            <Input
              value={typeTitle}
              type="text"
              placeholder={t('notification_type_placeholder')}
              onChange={handleChange}
              invalid={isInvalid}
            />
            {isInvalid && (
              <div
                className="invalid-feedback"
                style={{ display: 'block', fontSize: '12px', textAlign: 'left' }}>
                {t('notification_create_type_name_english_hint')}
              </div>
            )}
          </div>
        </div>
        <div className="notifications__modal_delete_btns flex-row">
          <Button color="primary" onClick={closeModal}>
            {t('common_cancel')}
          </Button>
          <Button color="danger" onClick={handleSubmit} disabled={!typeTitle}>
            {!editTypeId ? t('common_create') : t('common_save')}
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default CreateNotificationTypesModal;
