import getAuthUser from 'helpers/GetAuthUser';
import getImageReader from 'helpers/GetImageReader';
import { getImage } from 'helpers/api_helper';

const getUserPhoto = async (path) => {
  const { photo } = getAuthUser();

  try {
    const photoBlob = await getImage(path || photo.path);
    return getImageReader(photoBlob);
  } catch (error) {
    return null;
  }
};

export default getUserPhoto;
