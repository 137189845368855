import { useMemo } from 'react';

export const useGeneratedPassword = (passwordLength = 12) => {
  const chars = '0123456789abcdefghijklmnopqrstuvwxyz!@#$%^&*()ABCDEFGHIJKLMNOPQRSTUVWXYZ';

  const isPasswordContainsChars = (chars, password) => {
    for (const char of chars) {
      if (password.includes(char)) return true;
    }
    return false;
  };

  const checkPassword = (password) => {
    const specialChars = '!@#$%^&*()';
    const isPasswordContainsSpecialChars = isPasswordContainsChars(specialChars, password);
    const numberChars = '1234567890';
    const isPasswordContainsNumbersChars = isPasswordContainsChars(numberChars, password);
    const upperChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const isPasswordContainsUpperChars = isPasswordContainsChars(upperChars, password);
    const normalChars = 'abcdefghijklmnopqrstuvwxyz';
    const isPasswordContainsNormalChars = isPasswordContainsChars(normalChars, password);

    return (
      isPasswordContainsSpecialChars &&
      isPasswordContainsNumbersChars &&
      isPasswordContainsUpperChars &&
      isPasswordContainsNormalChars
    );
  };

  const generatePassword = () => {
    let password = '';
    for (let i = 0; i <= passwordLength; i++) {
      const randomNumber = Math.floor(Math.random() * chars.length);
      password += chars.substring(randomNumber, randomNumber + 1);
    }
    return password;
  };

  return useMemo(() => {
    let password, isPasswordValid;

    do {
      password = generatePassword();
      isPasswordValid = checkPassword(password);
    } while (!isPasswordValid);

    return password;
  }, [checkPassword, generatePassword]);
};
