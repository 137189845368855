import cn from 'classnames';
import ReactQuill from 'react-quill';

import s from './TextEditor.module.scss';
import { formats, modules } from './utils';

const TextEditor = ({ value, placeholder = '', onBlur, onChange, isInvalid }) => {
  return (
    <>
      <ReactQuill
        theme="snow"
        modules={modules}
        formats={formats}
        className={cn(s.editor, { [s.invalid]: isInvalid })}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      />
    </>
  );
};

export default TextEditor;
