import moment from 'moment/moment';

export const formInitialState = {
  id: null,
  notificationTitle: '',
  notificationType: '',
  notificationMessage: '',
  recipientCategory: 'all',
  notificationDate: '',
  whomNotifySolo: '',
  whomNotifyGroup: '',
  isEmail: false,
  isSite: true,
  fullMessage: '',
};

export const handleSubmitNotificationCreateForm = (
  values,
  editNotification,
  addNotification,
  redirectHandler,
) => {
  const data = {
    notification_text: values.fullMessage,
    notification_title: values.notificationTitle,
    isEmail: values.isEmail,
    isSite: values.isSite,
    notification_date: moment(values.notificationDate).format('YYYY-MM-DDTHH:mm:ss'),
    notification_type: parseInt(values.notificationType),
  };

  if (values.recipientCategory === 'all') {
    data.whom_notify = ['all'];
  }
  if (values.recipientCategory === 'one') {
    data.whom_notify = [values.whomNotifySolo];
  }
  if (values.recipientCategory === 'group') {
    data.whom_notify = values.whomNotifyGroup;
  }
  if (values.id) {
    data.id = values.id;
    editNotification({ data, redirectHandler });
  } else {
    addNotification({ data, redirectHandler });
  }
};
