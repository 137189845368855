export const GET_TEAM_INFO = 'GET_TEAM_INFO';
export const GET_PARTNERS_INCOME = 'GET_PARTNERS_INCOME';
export const SET_PARTNERS_INCOME_RANGE = 'SET_PARTNERS_INCOME_RANGE';
export const GET_PARTNERS = 'GET_PARTNERS';

export const GET_PARTNERS_REFERRALS_TREE = 'GET_PARTNERS_REFERRALS_TREE';

export const SET_PARTNERS_REFERRALS_TREE = 'SET_PARTNERS_REFERRALS_TREE';

export const GET_INVITED_USERS_ANALYTICS = 'GET_INVITED_USERS_ANALYTICS';

export const GET_INVESTED_FUNDS_ANALYTICS = 'GET_INVESTED_FUNDS_ANALYTICS';
export const GET_INVESTED_FUNDS_ANALYTICS_DETAILED = 'GET_INVESTED_FUNDS_ANALYTICS_DETAILED';

export const GET_INVESTED_BY_TYPES_ANALYTICS = 'GET_INVESTED_BY_TYPES_ANALYTICS';

export const SET_DETAILS_INFO = 'SET_DETAILS_INFO';

export const GET_USER_REFERRALS = 'GET_USER_REFERRALS';

export const SET_USER_REFERRALS = 'SET_USER_REFERRALS';

export const SET_IS_OPENED_REFERRALS_TREE = 'SET_IS_OPENED_REFERRALS_TREE';
