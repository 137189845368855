export const getDisabledProjectStorageName = (projectName) => `MC_DEPOSIT_DISABLED_${projectName}`;

export const setDisabledProjectTime = (value = new Date().getTime(), projectName) => {
  localStorage.setItem(getDisabledProjectStorageName(projectName), value);
};

export const getDisabledProjectTime = (projectName) =>
  parseInt(localStorage.getItem(getDisabledProjectStorageName(projectName))) || 0;

export const removeDisabledProjectTime = (projectName) =>
  localStorage.removeItem(getDisabledProjectStorageName(projectName));

export const getDisabledProjectTimeDifference = (projectName) => {
  const disabledProjectTime = getDisabledProjectTime(projectName);

  if (!disabledProjectTime) return 0;
  return new Date(disabledProjectTime) - new Date();
};
