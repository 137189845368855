import React, { forwardRef, useState } from 'react';

import DatePicker from 'react-datepicker';

import s from '../YearDatePicker/YearDatePicker.module.scss';

const CustomDatePicker = forwardRef(({ value, onClick }, ref) => (
  <button className={s.customDatePicker} onClick={onClick} ref={ref}>
    {value}
  </button>
));
const YearRangeDatePicker = ({ activeYears, onChange }) => {
  const [startDate, setStartDate] = useState(activeYears?.startDate);
  const [endDate, setEndDate] = useState(activeYears?.endDate);

  const handleChange = ([newStartDate, newEndDate]) => {
    setStartDate(newStartDate);
    setEndDate(newEndDate);

    onChange({
      startDate: newStartDate,
      endDate: newEndDate,
    });
  };

  return (
    <DatePicker
      selected={startDate}
      onChange={handleChange}
      selectsRange
      startDate={startDate}
      endDate={endDate}
      dateFormat="yyyy"
      showYearPicker
      customInput={<CustomDatePicker />}
    />
  );
};

export default YearRangeDatePicker;
