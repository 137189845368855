import { useState } from 'react';

import { ReactComponent as CloseIcon } from 'assets/images/icons/close.svg';
import { ReactComponent as EmptyIcon } from 'assets/images/icons/promo-empty.svg';
import { t } from 'i18next';
import { useSelector } from 'react-redux';
import { Modal, ModalHeader } from 'reactstrap';

import { formatNumber } from '../../../helpers/formatNumber';
import { getNumberWithSuffix } from '../../../helpers/numberWithSuffix';
import GoalChecker from './GoalChecker';
import s from './PromotionComponents.module.scss';

const EmptyStats = () => (
  <div className={s.emptyStats}>
    <EmptyIcon />
    <p>{t('common_empty')}</p>
  </div>
);

const StatsPage = ({
  level,
  firstGoal,
  secondGoal,
  secondRating,
  firstPromoRating,
  firstConditionCompleted,
  secondConditionCompleted,
  superPromoRating,
}) => {
  const language = useSelector((state) => state.Layout.language);
  const [leaderBoardModal, setLeaderBoardModal] = useState({});

  return (
    <div className={s.statsContainer}>
      <div className={s.wrapper}>
        <h4 className={s.userLevel}>
          <span>{level}</span> {t('promotion_your_level_with_date')}
        </h4>

        <div className={s.statsBlock}>
          <h5 className={s.blockLabel}>{t('promotion_personal_attracted_funds')}</h5>
          <GoalChecker
            data={firstGoal || []}
            conditionCompleted={firstConditionCompleted}
            userLevel={level}
          />
        </div>
        <div className={s.statsBlock}>
          <h5 className={s.blockLabel}>{t('promotion_attracted_by_fifth_line')}</h5>

          <GoalChecker
            data={secondGoal || []}
            conditionCompleted={secondConditionCompleted}
            userLevel={level}
          />

          <div className={s.secondRatingList}>
            {secondRating?.map((user, i) => (
              <div className={s.ratingListItem} key={i}>
                {t('promotion_line_label', {
                  line: getNumberWithSuffix(i + 1, language),
                  partnerId: user?.partner_id,
                  fullName: user?.full_name,
                  mobile: Number(user?.agreement) !== 1 ? t('common_mobile_hidden') : user?.mobile,
                })}

                <span className={s.userAmount}>${formatNumber(user?.team_deposit, 0)}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className={s.wrapper}>
        {level > 3 && (
          <div className={s.leaderBoardTable}>
            <h4 className={s.leaderBoardTableTitle}>
              {t('promotion_super_promo_leaderboard')}
              {superPromoRating?.length > 10 && (
                <button
                  className={s.viewAll}
                  onClick={() => setLeaderBoardModal({ opened: true, field: 'superPromoRating' })}>
                  {t('common_view_all')}
                </button>
              )}
            </h4>

            {!superPromoRating?.length ? (
              <EmptyStats />
            ) : (
              superPromoRating?.map((user, i) => {
                if (i < 10) {
                  return (
                    <div className={s.ratingListItem} key={i}>
                      {`${i + 1}. ${
                        language === 'ru' ? user?.full_name : user?.localized_full_name
                      }`}
                      <span className={s.userAmount}>{formatNumber(user?.score, 0)}</span>
                    </div>
                  );
                }
              })
            )}
          </div>
        )}
        <div className={s.leaderBoardTable}>
          <h4 className={s.leaderBoardTableTitle}>
            {t('promotion_first_promo_leaderboard')}{' '}
            {!!firstPromoRating?.length && (
              <button
                className={s.viewAll}
                onClick={() => setLeaderBoardModal({ opened: true, field: 'firstPromoRating' })}>
                {t('common_view_all')}
              </button>
            )}
          </h4>

          {!firstPromoRating?.length ? (
            <EmptyStats />
          ) : (
            firstPromoRating?.map((user, i) => {
              if (i < 10) {
                return (
                  <div className={s.ratingListItem} key={i}>
                    {`${i + 1}. ${language === 'ru' ? user?.full_name : user?.localized_full_name}`}
                    <span className={s.userAmount}>{formatNumber(user?.score, 0)}</span>
                  </div>
                );
              }
            })
          )}
        </div>
      </div>

      <Modal
        isOpen={!!leaderBoardModal?.opened}
        toggle={() => setLeaderBoardModal({})}
        centered
        size="lg">
        <ModalHeader>
          {leaderBoardModal?.field === 'firstPromoRating'
            ? t('promotion_first_promo_leaderboard')
            : t('promotion_super_promo_leaderboard')}

          <button className={s.closeBtn} onClick={() => setLeaderBoardModal({})}>
            <CloseIcon />
          </button>
        </ModalHeader>

        <div className={s.leaderBoardModalContent}>
          {(leaderBoardModal?.field === 'firstPromoRating'
            ? firstPromoRating
            : superPromoRating
          )?.map((user, i) => {
            const itemClass =
              leaderBoardModal?.field === 'firstPromoRating'
                ? i < 50
                  ? s.ratingListItem
                  : `${s.ratingListItem} ${s.faded}`
                : s.ratingListItem;

            return (
              <div className={itemClass} key={i}>
                {`${i + 1}. ${user?.full_name}`}
                <span className={s.userAmount}>{formatNumber(user?.score, 0)}</span>
              </div>
            );
          })}
        </div>
      </Modal>
    </div>
  );
};

export default StatsPage;
