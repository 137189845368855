import React, { useMemo } from 'react';

import { formatNumber } from 'helpers/formatNumber';
import useTranslation from 'hooks/useTranslation';
import moment from 'moment';
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import EmptyState from '../../../../components/EmptyState';

import s from './AnalyzeChart.module.scss';
import CustomLegend from './CustomLegend';

const AnalyzeChart = ({ data }) => {
  const t = useTranslation();
  const chartData = data?.map((item) => {
    let todayData = 0;

    if (item?.day_start === moment().format('YYYY-MM-DD')) {
      todayData = Number(item.sum_of_deposits);
    }

    const neededDate =
      item.day_start === moment().format('YYYY-MM-DD') ||
      item.day_start === moment().add(1, 'day').format('YYYY-MM-DD') ||
      item.day_start === moment().add(-1, 'day').format('YYYY-MM-DD');

    return {
      inInvesting: Number(item.sum_of_deposits).toFixed(2),
      payed: Number(item.payed).toFixed(2),
      name: moment(item.day_start).format('DD.MM.YYYY'),
      ...(neededDate && {
        todayData,
      }),
    };
  });

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className={s.tooltip}>
          <div>
            {label === moment().format('DD.MM.YYYY') ? <b>{t('today_data')}</b> : <b>{label}</b>}
          </div>
          {payload?.map((item) => {
            if (item?.name !== 'todayData') {
              return (
                <div style={{ color: item.color }} key={item?.name}>
                  {t(item?.name)}: {formatNumber(Number(item.value), 2)} USDT
                </div>
              );
            } else {
              return null;
            }
          })}
        </div>
      );
    }

    return null;
  };

  const maxDataValue = useMemo(() => {
    if (chartData?.length > 0) {
      const maxInvesting = Math.max(...chartData.map((item) => Number(item.inInvesting)));
      const maxPayed = Math.max(...chartData.map((item) => Number(item.payed)));

      return Math.max(maxInvesting, maxPayed);
    }
  }, [chartData]);

  return (
    <div className={s.wrapper}>
      <div className={s.title}>{t('yield_graph')}</div>
      <ResponsiveContainer className={s.chart}>
        <LineChart
          data={chartData}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis domain={[0, maxDataValue]} />
          <Tooltip content={CustomTooltip} />
          <Legend
            wrapperStyle={{ display: 'flex', justifyContent: 'center' }}
            iconType="circle"
            width="100%"
            content={CustomLegend}
          />
          <Line
            dataKey="inInvesting"
            stroke="#556EE6"
            activeDot={{ r: 8 }}
            strokeWidth={3}
            dot={null}
            strokeLinecap="square"
          />
          <Line
            dataKey="payed"
            stroke="#F1B44C"
            strokeWidth={3}
            dot={null}
            strokeLinecap="square"
          />
          <Line
            dataKey="todayData"
            stroke="#34C38F"
            strokeWidth={3}
            dot={null}
            strokeLinecap="square"
          />
        </LineChart>
      </ResponsiveContainer>
      {chartData?.length === 0 && (
        <div className={s.emptyState}>
          <EmptyState />
        </div>
      )}
    </div>
  );
};

export default AnalyzeChart;
