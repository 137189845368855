import React, { useCallback, useEffect, useState } from 'react';

import Logo from 'assets/images/logo-black.png';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import MetaTags from 'react-meta-tags';
import { useDispatch, useSelector } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Form, FormFeedback, Input, Label, Spinner } from 'reactstrap';
// Formik Validation
import * as Yup from 'yup';

import PasswordInput from 'components/Custom/passwordInput';

import { t } from '../../i18n';
import { resendConfirmCode, sendConfirmCode } from '../../store/users/actions';
import s from './scss/AuthPages.module.scss';

const RecoverPassword = (props) => {
  const dispatch = useDispatch();
  const [verifyEmail, setVerifyEmail] = useState('');
  const { error, loading } = useSelector((state) => ({
    error: state.Users.confirmedError,
    loading: state.Users.confirmedLoading,
  }));
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .min(8, t('common_password_hint_small'))
        .matches(
          /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$/,
          t('common_password_hint_validation'),
        )
        .required(t('common_password_hint_required')),
      confirmPassword: Yup.string()
        .required(t('common_password_hint_repeat'))
        .oneOf([Yup.ref('password'), null], t('common_password_hint_not_match')),
      confirmCode: Yup.string().required(t('common_code_hint_required')),
    }),
    onSubmit: async (values) => {
      dispatch(
        sendConfirmCode({
          type: 'reset_password',
          value: values?.confirmPassword,
          code: values?.confirmCode?.replace('-', ''),
          history: props.history,
          email: verifyEmail,
        }),
      );
    },
  });

  const resendVerificationCode = useCallback(() => {
    dispatch(
      resendConfirmCode({
        type: 'reset_password',
        email: verifyEmail,
        value: validation.values.confirmPassword,
      }),
    );
  }, [dispatch, validation.values.confirmPassword, verifyEmail]);

  const handleCodeChange = useCallback(
    (event) => {
      let value = event.target.value.replace(/\D/g, '');

      if (value.length > 6) {
        value = value.substring(0, 6);
      }

      if (value.length > 3) {
        value = value.substring(0, 3) + '-' + value.substring(3);
      }

      validation.setFieldValue('confirmCode', value);
    },
    [validation],
  );

  useEffect(() => {
    if (localStorage.getItem('verifyEmail')) {
      setVerifyEmail(localStorage.getItem('verifyEmail'));
    }
  }, []);

  return (
    <React.Fragment>
      <MetaTags>
        <title>{t('recover_password_meta_title')}</title>
      </MetaTags>
      <div className={s.wrapper}>
        <div className={s.formWrapper}>
          <img src={Logo} alt="logo" className={s.logo} />
          <div>
            <h4 className={s.title}>{t('auth_recover_password')}</h4>
            <p className={s.subtitle}>
              {t('auth_recover_password_text', { address: verifyEmail })}
            </p>
          </div>

          <Form
            className={s.form}
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
            }}>
            {error ? <div className="alert-warning">{`${t(error)}`}</div> : null}
            <div>
              <div className={s.forgotWrapper}>
                <Label className={s.formLabel} htmlFor="password">
                  {`${t('common_password')}`}
                </Label>
              </div>

              <PasswordInput
                validation={validation}
                name="password"
                id="password"
                placeholder={`${t('common_password_placeholder')}`}
                invalid={!!(validation.touched.password && validation.errors.password)}
              />
              {validation.touched.password && validation.errors.password ? (
                <FormFeedback type="invalid" className="auth-input-warning-hint">
                  {validation.errors.password}
                </FormFeedback>
              ) : null}
            </div>
            <div>
              <div className={s.forgotWrapper}>
                <Label className={s.formLabel} htmlFor="password">
                  {`${t('auth_recover_password_confirm_password')}`}
                </Label>
              </div>

              <PasswordInput
                validation={validation}
                name="confirmPassword"
                id="confirmPassword"
                placeholder={`${t('common_password_placeholder')}`}
                invalid={
                  !!(validation.touched.confirmPassword && validation.errors.confirmPassword)
                }
              />
              {validation.touched.confirmPassword && validation.errors.confirmPassword ? (
                <FormFeedback type="invalid" className="auth-input-warning-hint">
                  {validation.errors.confirmPassword}
                </FormFeedback>
              ) : null}
            </div>

            <div>
              <div className={s.forgotWrapper}>
                <Label className={s.formLabel} htmlFor="password">
                  {`${t('auth_recover_password_confirm_password_code')}`}
                </Label>
                <button className={s.forgotTxt} onClick={resendVerificationCode}>
                  {t('auth_verify_your_email_resend')}
                </button>
              </div>

              <Input
                name="confirmCode"
                className="auth-input"
                placeholder="XXX-XXX"
                type="text"
                onChange={(e) => handleCodeChange(e)}
                onBlur={validation.handleBlur}
                value={validation.values.confirmCode || ''}
                invalid={!!(validation.touched.confirmCode && validation.errors.confirmCode)}
              />
              {validation.touched.confirmCode && validation.errors.confirmCode ? (
                <FormFeedback type="invalid" className="auth-input-warning-hint">
                  {validation.errors.confirmCode}
                </FormFeedback>
              ) : null}
            </div>

            <div className={s.actions}>
              <button className={s.submitButton} type="submit">
                {loading ? (
                  <div className="d-flex gap-2 justify-content-center align-items-center">
                    <Spinner className="spinner" /> {t('common_restore')}
                  </div>
                ) : (
                  t('common_restore')
                )}
              </button>
            </div>

            <p className={s.bottomText}>
              {t('auth_recover_password_remember_password')}
              <Link to="login" className={s.bottomTextLink}>
                {' '}
                {t('auth_register_login')}
              </Link>
            </p>
          </Form>
        </div>
      </div>
    </React.Fragment>
  );
};

RecoverPassword.propTypes = {
  history: PropTypes.object,
};

export default withRouter(RecoverPassword);
