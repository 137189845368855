import { useState } from 'react';

import { ReactComponent as Metamask } from 'assets/images/icons/metamask.svg';
import { ReactComponent as QrCode } from 'assets/images/icons/qr.svg';
import { ReactComponent as SafePal } from 'assets/images/icons/safepal.svg';
import { ReactComponent as Trust } from 'assets/images/icons/trust.svg';
import cn from 'classnames';

import s from './Payment.module.scss';

const WALLETS_LIST = [
  {
    title: 'other',
    icon: <QrCode width={28} height={28} />,
  },
  {
    title: 'metamask',
    icon: <Metamask width={28} height={28} />,
  },
  {
    title: 'trustWallet',
    icon: <Trust width={28} height={28} />,
  },
  {
    title: 'safePal',
    icon: <SafePal width={28} height={28} />,
  },
];

const WalletsTabs = ({ setPaymentType }) => {
  const [active, setActive] = useState(0);

  const handleChangeTab = (index) => {
    setActive(index);
    setPaymentType(WALLETS_LIST[index].title);
  };

  return (
    <div className={s.walletsTabs}>
      {WALLETS_LIST.map((wallet, index) => (
        <div
          key={wallet.title}
          className={cn(s.walletItem, active === index && s.active)}
          onClick={() => handleChangeTab(index)}>
          {wallet.icon}
        </div>
      ))}
    </div>
  );
};

export default WalletsTabs;
