import React, { useCallback, useEffect, useState } from 'react';

import { t } from 'i18next';
import BootstrapTable from 'react-bootstrap-table-next';
import { MetaTags } from 'react-meta-tags';
import { useSelector } from 'react-redux';
import { Button, Card } from 'reactstrap';

import { getNotificationsTypeRequest } from 'services/notificationsService';

import Breadcrumbs from 'components/Common/Breadcrumb';
import LinksBreadcrumbs from 'components/LinksBreadcrumbs';

import CustomDropdown from '../Admin/CustomDropdown/CustomDropdown';
import CreateNotificationTypesModal from './modals/CreateNotificationTypesModal';
import DeleteNotificationTypesModal from './modals/DeleteNotificationTypesModal';

const getTableColumns = (editClickHandler, deleteModalOpenHandler) => [
  {
    dataField: 'id',
    text: t('common_type_id'),
    sort: true,
    attrs: {
      'data-label': t('common_type_id'),
    },
  },
  {
    dataField: 'title',
    text: t('common_type_title'),
    sort: true,
    attrs: {
      'data-label': t('common_type_title'),
    },
  },
  {
    dataField: 'buttons',
    text: '',
    sort: false,
    formatter: (_, row) => {
      return (
        <CustomDropdown
          editClickHandler={() => editClickHandler(row.id)}
          deleteClickHandler={() => deleteModalOpenHandler(row)}
        />
      );
    },
  },
];

const NotificationsTypes = () => {
  const [notificationTypes, setNotificationTypes] = useState([]);
  const language = useSelector((state) => state.Layout.language);
  const [deleteTypeModalData, setDeleteTypeModalData] = useState(null);
  const [editTypeModalData, setEditTypeModalData] = useState(null);
  const [isOpenedCreateModal, setIsOpenedCreateModal] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const editNotificationId = localStorage.getItem('editNotificationId');

  const BREADCRUMBS_ROUTES = [
    { name: t('notification_title'), path: '/notifications' },
    {
      name: editNotificationId
        ? `${t('common_edit_notification')}: ${editNotificationId}`
        : t('common_create_notification'),
      path: editNotificationId
        ? `/notifications/edit/${editNotificationId}`
        : '/notifications/create',
    },
    { name: t('notification_types') },
  ];

  const getNotificationsTypes = useCallback(async () => {
    const data = await getNotificationsTypeRequest(language);
    setNotificationTypes(data);
  }, [language]);

  const editClickHandler = (id) => {
    setEditTypeModalData(id);
  };

  const deleteModalOpenHandler = (type) => {
    setDeleteTypeModalData(type);
  };

  useEffect(() => {
    getNotificationsTypes();
  }, [getNotificationsTypes, language, isRefreshing]);

  useEffect(() => {
    return () => {
      localStorage.removeItem('editNotificationId');
    };
  }, []);

  return (
    <div className="page-content">
      <MetaTags>
        <title>{t('notification_types')}</title>
      </MetaTags>

      <Breadcrumbs
        title={t('notification_types')}
        hasBreadcrumbItem={false}
        breadcrumbItem={t('notification_types')}
      />
      <LinksBreadcrumbs routes={BREADCRUMBS_ROUTES} className="mb-2" />
      <Card>
        <div className="notification_types_table">
          <div className="text-end mb-4">
            <Button
              color="primary"
              className="notification__add_btn"
              onClick={() => setIsOpenedCreateModal(true)}>
              <div className="p-2">{t('notification_create_notification_type')}</div>
            </Button>
          </div>
          <BootstrapTable
            keyField="id"
            data={notificationTypes}
            columns={getTableColumns(editClickHandler, deleteModalOpenHandler)}
            headerClasses="table-head"
            wrapperClasses="table-responsive without__padding table text-start table__adaptive_bordered_between"
            responsive
          />
        </div>
        <DeleteNotificationTypesModal
          isOpen={!!deleteTypeModalData?.id}
          type={deleteTypeModalData}
          closeModal={() => setDeleteTypeModalData(null)}
          updateNotificationTypesList={setNotificationTypes}
          refresh={() => setIsRefreshing(true)}
        />
        <CreateNotificationTypesModal
          isOpen={isOpenedCreateModal}
          closeModal={() => setIsOpenedCreateModal(false)}
          refresh={() => setIsRefreshing(true)}
        />
        <CreateNotificationTypesModal
          isOpen={!!editTypeModalData}
          closeModal={() => setEditTypeModalData(null)}
          refresh={() => setIsRefreshing(true)}
          editTypeId={editTypeModalData}
        />
      </Card>
    </div>
  );
};

export default NotificationsTypes;
