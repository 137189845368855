export const FETCH_ACCRUALS = 'FETCH_ACCRUALS';
export const SET_ACCRUALS = 'SET_ACCRUALS';

export const FETCH_ACCRUALS_INCOME = 'FETCH_ACCRUALS_INCOME';
export const SET_ACCRUALS_INCOME = 'SET_ACCRUALS_INCOME';

export const FETCH_TOTAL_ACCRUALS = 'FETCH_TOTAL_ACCRUALS';
export const SET_TOTAL_ACCRUALS = 'SET_TOTAL_ACCRUALS';

export const FETCH_ACCRUALS_GRAPH_DATA = 'FETCH_ACCRUALS_GRAPH_DATA';
export const SET_ACCRUALS_GRAPH_DATA = 'SET_ACCRUALS_GRAPH_DATA';
