import * as Yup from 'yup';

import { t } from '../i18n';

export const editProfileSchema = Yup.object({
  fullName: Yup.string().required('auth_register_full_name_hint_required'),
  country: Yup.object()
    .shape({
      value: Yup.string(),
      label: Yup.string(),
    })
    .required('auth_register_choose_country_hint'),
  mobile: Yup.string()
    .matches(/^(\+)[1-9][0-9\-\(\)\.]{9,15}$/, 'common_phone_number_invalid_hint')
    .required('common_phone_number_validation_hint'),
  photo: Yup.object()
    .shape({
      id: Yup.string(),
      path: Yup.string(),
    })
    .notRequired(),
});

export const editPasswordSchema = Yup.object({
  old_password: Yup.string().required(t('common_password_hint_required')),
  new_password: Yup.string()
    .min(8, t('common_password_hint_small'))
    .matches(
      /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$/,
      t('common_password_hint_validation'),
    )
    .required(t('common_password_hint_required')),
  confirm_password: Yup.string()
    .oneOf([Yup.ref('new_password'), null], t('common_password_hint_not_match'))
    .when('new_password', {
      is: (password) => password !== undefined,
      then: Yup.string().required(t('common_password_hint_repeat')),
      otherwise: Yup.string().notRequired(),
    }),
});
