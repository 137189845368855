import React from 'react';

import commonIcons from './common';
import sidebarIcons from './sidebar';

const Icon = (props) => {
  const { name, size = 24, width, height } = props;

  const icons = {
    ...commonIcons,
    ...sidebarIcons,
  };
  const iconSize = width && height ? null : size;

  const MainIcon = icons[name];

  return <MainIcon width={iconSize || width} height={iconSize || height} />;
};

export default Icon;
