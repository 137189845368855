import React, { useEffect, useState } from 'react';

import cn from 'classnames';
import { t } from 'i18next';
import { useSelector } from 'react-redux';
import { Button, Input, Label } from 'reactstrap';

import ConfirmChangeDefaultWalletModal from '../../Authentication/components/Profile/ConfirmChangeDefaultWalletModal';

import { validateBep20UsdtAddress } from '../../../helpers/validateBep20Address';
import s from './Components.module.scss';

const DefaultWalletChange = () => {
  const userWalletAddr = useSelector((state) => state.Profile.user.default_wallet_address ?? '');
  const [manualChangeWallet, setManualChangeWallet] = useState(userWalletAddr.trim());
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newWallet, setNewWallet] = useState('');
  const [isDisabled, setIsDisabled] = useState(true);

  function saveChangeWallet() {
    setIsModalOpen(true);
    setNewWallet(manualChangeWallet?.trim());
  }

  function closeModal(callback) {
    setNewWallet('');
    setIsModalOpen(false);
    callback();
  }

  function cancelChange() {
    setIsDisabled(true);
    setManualChangeWallet(userWalletAddr);
  }

  useEffect(() => {
    if (manualChangeWallet !== userWalletAddr) setManualChangeWallet(userWalletAddr);
  }, [userWalletAddr]);

  return (
    <div>
      <div className={s.walletBlock}>
        <div className={s.formField}>
          <Label className={s.label}>{t('user_profile_default_wallet_address')}</Label>
          <Input
            value={manualChangeWallet}
            className={cn('auth-input', s.input)}
            disabled={isDisabled}
            onChange={(e) => setManualChangeWallet(e.target.value?.trim())}
            invalid={!validateBep20UsdtAddress(manualChangeWallet.trim())}
          />

          <a className={s.supportLink} href="https://t.me/mcplatform_pro_bot" target="_blank">
            {t('change_wallet_bot')}
          </a>
        </div>
        {/*<div>*/}
        {/*  {isDisabled && (*/}
        {/*    <>*/}
        {/*      <button*/}
        {/*        className={cn(s.changeWalletBtn, s.edit)}*/}
        {/*        onClick={() => setIsDisabled(false)}*/}
        {/*        type="button">*/}
        {/*        {t('common_edit')}*/}
        {/*      </button>*/}
        {/*    </>*/}
        {/*  )}*/}
        {/*  {!isDisabled && (*/}
        {/*    <div className={s.actions}>*/}
        {/*      <button*/}
        {/*        className={cn(s.changeWalletBtn, s.save)}*/}
        {/*        onClick={saveChangeWallet}*/}
        {/*        disabled={!validateBep20UsdtAddress(manualChangeWallet.trim())}*/}
        {/*        type="button">*/}
        {/*        {t('common_save')}*/}
        {/*      </button>*/}
        {/*      <button*/}
        {/*        className={cn(s.changeWalletBtn, s.cancel)}*/}
        {/*        onClick={cancelChange}*/}
        {/*        type="button">*/}
        {/*        {t('common_cancel')}*/}
        {/*      </button>*/}
        {/*    </div>*/}
        {/*  )}*/}
        {/*</div>*/}
      </div>
      {!validateBep20UsdtAddress(manualChangeWallet) && (
        <div className="invalid-feedback d-block">{t('set_default_wallet_modal_warning')}</div>
      )}
      <ConfirmChangeDefaultWalletModal
        closeModal={closeModal}
        isOpen={isModalOpen}
        wallet={newWallet}
        hideSaveButton={setIsDisabled}
      />
    </div>
  );
};

export default DefaultWalletChange;
