import React, { useEffect, useState } from 'react';

import { ReactComponent as WarningIcon } from 'assets/images/icons/warning.svg';
import { useFormik } from 'formik';
import { t } from 'i18next';
import moment from 'moment';
import PropTypes from 'prop-types';
import { MetaTags } from 'react-meta-tags';
import { connect, useSelector } from 'react-redux';
import { Link, useParams, withRouter } from 'react-router-dom';
import { Button, Container, FormFeedback, FormGroup, Input, Label } from 'reactstrap';

import {
  getNotificationsTypeRequest,
  getOneNotificationRequest,
} from 'services/notificationsService';
import { addNotification, editNotification } from 'store/actions';

import Breadcrumbs from 'components/Common/Breadcrumb';
import InputWithError from 'components/InputWithError/InputWithError';
import LinksBreadcrumbs from 'components/LinksBreadcrumbs';
import TextEditor from 'components/TextEditor';

import CreateNotificationFormReferralsSelect from './CreateNotificationFormReferralsSelect';
import { formInitialState, handleSubmitNotificationCreateForm } from './utils';
import { createNotificationSchema } from './yupSchema';

const CreateNotification = ({ addNotification, editNotification, history }) => {
  const { notificationId } = useParams();
  const [notificationTypes, setNotificationTypes] = useState([]);
  const language = useSelector((state) => state.Layout.language);
  const BREADCRUMBS_ROUTES = [
    { name: t('notification_title'), path: '/notifications' },
    {
      name: notificationId
        ? `${t('common_edit_notification')}: ${notificationId}`
        : t('common_create_notification'),
    },
  ];

  const redirectHandler = () => {
    return history.push('/notifications');
  };

  const formik = useFormik({
    validationSchema: createNotificationSchema,
    initialValues: formInitialState,
    onSubmit: (values) =>
      handleSubmitNotificationCreateForm(
        values,
        editNotification,
        addNotification,
        redirectHandler,
      ),
  });

  useEffect(() => {
    const getNotificationsTypes = async () => {
      const data = await getNotificationsTypeRequest(language);
      setNotificationTypes(data);
    };
    getNotificationsTypes();
  }, [language]);

  useEffect(() => {
    if (notificationId) {
      localStorage.setItem('editNotificationId', notificationId);

      getOneNotificationRequest(notificationId)?.then((data) => {
        if (data?.id) {
          const recipientCategory = data?.whom_notify.includes('all')
            ? 'all'
            : data?.whom_notify.length === 1
            ? 'one'
            : 'group';

          formik.setValues({
            recipientCategory,
            id: data.id,
            isEmail: data.isEmail ?? false,
            isSite: data.isSite ?? true,
            notificationDate: moment(new Date(data.notification_date)).format('yyyy-MM-DDThh:mm'),
            notificationTitle: data.notification_title,
            fullMessage: data.notification_text,
            whomNotifyGroup: data?.whom_notify || [],
            whomNotifySolo: recipientCategory === 'one' ? data?.whom_notify[0].toString() : '',
            notificationType: data?.notifications_type?.id,
          });
        }
      });
    }
  }, [notificationId]);

  return (
    <div className="page-content">
      <MetaTags>
        <title>{formik.values.id ? t('common_editing') : t('common_create_notification')}</title>
      </MetaTags>
      <Container fluid>
        <div>
          <Breadcrumbs
            title={t('common_notification')}
            hasBreadcrumbItem={false}
            breadcrumbItem={`${
              formik.values.id ? t('common_edit_notification') : t('common_create_notification')
            }`}
          />
          <LinksBreadcrumbs routes={BREADCRUMBS_ROUTES} className="mb-2" />
        </div>

        <div className="notification__create_body_wrapper">
          <div className="notification__create_body">
            <div className="notification__create_body_upper">
              <div className="notification_create_language_hint_wrapper">
                <div className="notification_create_language_hint_title_wrapper">
                  <div>
                    <WarningIcon color="red" />
                    <h4>{t('notification_create_language_hint_title')}</h4>
                  </div>
                </div>
                <div>{t('notification_create_language_hint_body')}</div>
              </div>
              <FormGroup>
                <div className="notification_create_form_field">
                  <Label>{t('notification_type')}</Label>
                  <Link to="/notifications/types">{t('notification_manage_type')}</Link>
                </div>
                <InputWithError name="notificationType" formik={formik} type="select">
                  <option value="" disabled>
                    {t('notification_choose_type')}
                  </option>
                  {notificationTypes &&
                    notificationTypes.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.title}
                      </option>
                    ))}
                </InputWithError>
              </FormGroup>
              <FormGroup>
                <Label>{t('notification_name')}</Label>
                <InputWithError name="notificationTitle" formik={formik} />
              </FormGroup>
              <div className="mb-4">
                <Label>{t('common_message')}</Label>
                <TextEditor
                  value={formik.values.fullMessage}
                  onChange={(value) => formik.setFieldValue('fullMessage', value)}
                  isInvalid={!!formik.errors.fullMessage}
                />
                {formik.errors.fullMessage && (
                  <div className="invalid-feedback" style={{ display: 'block' }}>
                    {formik.errors.fullMessage}
                  </div>
                )}
              </div>
              <FormGroup>
                <Label>{t('notification_receiver_type')}</Label>
                <div className="notification__create_category_wrapper d-flex justify-content-between align-items-center">
                  <Label>
                    <Input
                      name="recipientCategory"
                      type="radio"
                      checked={formik.values.recipientCategory === 'all'}
                      value="all"
                      onChange={formik.handleChange}
                    />{' '}
                    {t('notification_receiver_type_all')}
                  </Label>
                  <Label>
                    <Input
                      name="recipientCategory"
                      type="radio"
                      value="one"
                      checked={formik.values.recipientCategory === 'one'}
                      onChange={formik.handleChange}
                    />{' '}
                    {t('notification_receiver_type_one')}
                  </Label>
                  <Label>
                    <Input
                      name="recipientCategory"
                      type="radio"
                      value="group"
                      checked={formik.values.recipientCategory === 'group'}
                      onChange={formik.handleChange}
                    />{' '}
                    {t('notification_receiver_type_group')}
                  </Label>
                </div>
              </FormGroup>
              {formik.values?.recipientCategory === 'one' && (
                <div>
                  <Label>{t('notification_receiver_user_id')}</Label>
                  <CreateNotificationFormReferralsSelect
                    editNotificationId={notificationId}
                    formik={formik}
                    name="whomNotifySolo"
                  />
                </div>
              )}
              {formik.values?.recipientCategory === 'group' && (
                <div>
                  <Label>{t('notification_receiver_users_id')}</Label>
                  <CreateNotificationFormReferralsSelect
                    formik={formik}
                    editNotificationId={notificationId}
                    mode="multi"
                    name="whomNotifyGroup"
                  />
                </div>
              )}
              <FormGroup>
                <Label>{t('common_date_and_time')}</Label>
                <Input
                  name="notificationDate"
                  value={formik.values.notificationDate}
                  onChange={formik.handleChange}
                  type="datetime-local"
                  invalid={!!formik.touched.notificationDate && !!formik.errors.notificationDate}
                />
                {formik.touched.notificationDate && formik.errors.notificationDate ? (
                  <FormFeedback type="invalid">{formik.errors.notificationDate}</FormFeedback>
                ) : null}
              </FormGroup>
              <FormGroup>
                <Label>{t('notification_delivery_type')}</Label>
                <div className="d-flex">
                  <Label className="notification__create_checkbox_wrapper d-flex align-items-center">
                    <Input
                      className="notification__create_checkbox"
                      type="checkbox"
                      name="isSite"
                      checked={formik.values.isSite}
                      onChange={formik.handleChange}
                      invalid={!!formik.touched.isSite && !!formik.errors.isSite}
                    />
                    <span>{t('common_site')}</span>
                  </Label>
                  <Label className="notification__create_checkbox_wrapper d-flex align-items-center">
                    <Input
                      className="notification__create_checkbox"
                      type="checkbox"
                      name="isEmail"
                      checked={formik.values.isEmail}
                      onChange={formik.handleChange}
                      invalid={!!formik.touched.isEmail && !!formik.errors.isEmail}
                    />
                    <span>{t('common_mail')}</span>
                  </Label>
                </div>
                {formik.touched.isEmail && formik.errors.isEmail ? (
                  <div className="auth-input-warning-hint">{formik.errors.isEmail}</div>
                ) : null}
              </FormGroup>
            </div>
            <FormGroup>
              <Button
                className="notification__create_btn"
                type="submit"
                color="primary"
                onClick={formik.handleSubmit}>
                {t('common_save')}
              </Button>
            </FormGroup>
          </div>
        </div>
      </Container>
    </div>
  );
};

CreateNotification.propTypes = {
  addNotification: PropTypes.func,
  editNotification: PropTypes.func,
  getNotification: PropTypes.func,
  history: PropTypes.any,
};

const mapStateToProps = (state) => ({
  getNotification: (notificationId) =>
    state.Notifications.notifications.find((item) => item.id === notificationId),
});

const mapDispatchToProps = (dispatch) => ({
  addNotification: (data) => dispatch(addNotification(data)),
  editNotification: (data) => dispatch(editNotification(data)),
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CreateNotification));
