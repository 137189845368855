import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import {
  fetchAccrualsGraphData,
  fetchAccrualsIncome,
  fetchAccurals,
  fetchTotalAccruals,
} from 'services/accrualsService';

import {
  FETCH_ACCRUALS,
  FETCH_ACCRUALS_GRAPH_DATA,
  FETCH_ACCRUALS_INCOME,
  FETCH_TOTAL_ACCRUALS,
} from './actionTypes';
import { setAccruals, setAccrualsGraphData, setAccrualsIncome, setTotalAccruals } from './actions';

function* fetchAccrualSaga({ payload }) {
  try {
    const data = yield call(fetchAccurals, payload);
    yield put(setAccruals(data));
  } catch (error) {
    console.log(error);
  }
}

function* watchAccrualSaga() {
  yield takeEvery(FETCH_ACCRUALS, fetchAccrualSaga);
}

function* fetchTotalAccrualSaga({ payload }) {
  try {
    const data = yield call(fetchTotalAccruals, payload);
    yield put(setTotalAccruals(data));
  } catch (error) {
    console.log(error);
  }
}

function* watchTotalAccrualSaga() {
  yield takeEvery(FETCH_TOTAL_ACCRUALS, fetchTotalAccrualSaga);
}
function* fetchAccrualIncomeSaga() {
  try {
    const data = yield call(fetchAccrualsIncome);
    yield put(setAccrualsIncome(data));
  } catch (error) {
    console.log(error);
  }
}

function* watchAccrualIncomeSaga() {
  yield takeEvery(FETCH_ACCRUALS_INCOME, fetchAccrualIncomeSaga);
}

function* fetchAccrualsGraphDataSaga({ payload }) {
  try {
    const data = yield call(fetchAccrualsGraphData, payload);
    yield put(setAccrualsGraphData(data));
  } catch (error) {
    console.log(error);
  }
}

function* watchAccrualsGraphDataSaga() {
  yield takeEvery(FETCH_ACCRUALS_GRAPH_DATA, fetchAccrualsGraphDataSaga);
}

function* AccrualSaga() {
  yield all([
    fork(watchAccrualSaga),
    fork(watchAccrualIncomeSaga),
    fork(watchTotalAccrualSaga),
    fork(watchAccrualsGraphDataSaga),
  ]);
}

export default AccrualSaga;
