import React, { useCallback, useEffect, useState } from 'react';

import cn from 'classnames';
import { t } from 'i18next';

import s from './PaymentHint.module.scss';

const PaymentHint = ({ amount, setIsShowPaymentHint }) => {
  const [time, setTime] = useState(8);

  useEffect(() => {
    const timer = setInterval(() => {
      setTime((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  const handleClose = useCallback(() => {
    if (time <= 0) {
      setIsShowPaymentHint(false);
    }
  }, [setIsShowPaymentHint, time]);

  return (
    <div className={s.wrapper}>
      <div className={s.title}>{t('payment_hint_attention')}</div>
      <div className={s.hint}>{t('payment_hint_text', { amount })}</div>
      <div className={s.description}>{t('payment_hint_description')}</div>

      <button
        className={cn(s.confirmBtn, { [s.disabled]: time > 0 })}
        disabled={time > 0}
        onClick={handleClose}>
        {t('payment_hint_confirm_btn')}
      </button>
      {time > 0 && <span>{t('payment_hint_timer_txt', { seconds: time })}</span>}
    </div>
  );
};

export default PaymentHint;
