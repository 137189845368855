import React, { useEffect } from 'react';

import { ReactComponent as EmptyIcon } from 'assets/images/icons/empty.svg';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import useTranslation from '../../hooks/useTranslation';
import { getIncome } from '../../store/dashboard/actions';
import IncomeChart from './IncomeChats/incomeChart';
import './sass/incomebyLevel.scss';
import './sass/notification.scss';

const IncomeByLevel = () => {
  const dispatch = useDispatch();
  const income = useSelector((state) => state.Dashboard.income);
  const t = useTranslation();
  const structureLines = {
    1: t('dashboard_first_line'),
    2: t('dashboard_other_lines'),
  };
  const hasIncome =
    income &&
    (Number(income?.products?.basic) !== 0 ||
      Number(income?.products?.investor) !== 0 ||
      Number(income?.products?.investorPro) !== 0 ||
      Number(income?.partners?.firstLine) !== 0 ||
      Number(income?.partners?.otherLines) !== 0);

  const data = React.useMemo(() => {
    const partners = Object.values(income?.partners ?? {});
    const { basic = 0, investor = 0, investorPro = 0 } = income?.products ?? {};
    const totalProducts = +basic + +investor + +investorPro;
    const totalPartners = partners.reduce((acc, curr) => acc + +curr, 0);
    const total = totalProducts + totalPartners;

    const perc = (a, b) => (b === 0 ? 0 : Math.round((a / b) * 100));
    const round = (x) => +(+x).toFixed(2);

    return {
      total,
      partners: {
        totalPercent: perc(totalPartners, total),
        total: round(totalPartners),
        partners: partners.map((value, index) => ({
          id: index + 1,
          income: round(value),
          percent: perc(value, total),
        })),
      },
      products: {
        totalPercent: perc(totalProducts, total),
        total: round(totalProducts),
        products: [
          {
            name: t('dashboard_product.base'),
            income: round(basic),
            percent: perc(basic, total),
          },
          {
            name: t('dashboard_product.investor'),
            income: round(investor),
            percent: perc(investor, total),
          },
          {
            name: t('dashboard_product.investorPro'),
            income: round(investorPro),
            percent: perc(investorPro, total),
          },
        ],
      },
    };
  }, [income, t]);

  const { products, partners } = data;

  let chartData, totalData;

  if (products && partners) {
    chartData = [
      ...partners.partners.map((el) => ({
        value: el.income,
        name: structureLines[el.id],
        key: 'fromPartners',
        percent: el.percent,
      })),
      ...products.products.map((el) => ({
        value: el.income,
        name: el.name,
        key: 'fromFinancialProducts',
        percent: el.percent,
      })),
    ];
    totalData = {
      total: data.total,
      product: {
        total: data.products.total,
        percent: data.products.totalPercent,
      },
      partners: {
        total: data.partners.total,
        percent: data.partners.totalPercent,
      },
    };
  }

  useEffect(() => {
    dispatch(getIncome());
  }, [dispatch]);

  return (
    <div>
      <h4 className="dashboard-block-title">{t('dashboard_your_income')}</h4>
      {hasIncome ? (
        <div>{chartData && <IncomeChart chartData={chartData} totalData={totalData} />}</div>
      ) : (
        <div className="referrals-zero">
          <div>
            <EmptyIcon />
            <p>{t('dashboard_not_have_earnings')}</p>
          </div>
        </div>
      )}
    </div>
  );
};

IncomeByLevel.propTypes = {
  income: PropTypes.any,
};
export default IncomeByLevel;
