import React, { useEffect, useState } from 'react';

import { t } from 'i18next';

import { INVEST_MODAL_STEPS } from '../../utils';
import s from './Payment.module.scss';

function Timer({ setActiveSteps }) {
  const [time, setTime] = useState(20 * 60); // 20 minutes in seconds

  useEffect(() => {
    const timer = setInterval(() => {
      setTime((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    if (time === 0) {
      setActiveSteps(INVEST_MODAL_STEPS.EXPIRED);
    }
  }, [setActiveSteps, time]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;

    return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  return (
    <div className={s.timerWrapper}>
      <div className={s.timer}>{formatTime(time)}</div>
      <div>{t('payment_timer_text')}</div>
    </div>
  );
}

export default Timer;
