import React from 'react';

import { ReactComponent as ErrorIcon } from 'assets/images/icons/error.svg';
import { t } from 'i18next';
import { Card, CardTitle } from 'reactstrap';

import { SUPPORT_LINK } from 'constants/links';

import s from './Error.module.scss';

const Error = () => {
  return (
    <div className={s.wrapper}>
      <div className={s.header}>
        <div className={s.title}>{t('common_was_error')}</div>
      </div>
      <Card>
        <div
          style={{
            padding: '15px 5px',
          }}>
          <div className="text-center">
            <ErrorIcon />
            <CardTitle className="fs-5 mt-2">{t('common_was_error')}</CardTitle>
          </div>
        </div>
      </Card>
      <div className="text-center">
        <div className="fw-bold fs-5"></div>
        <div className="mt-2"></div>
        <div className="mt-4">
          <div className="mt-2 text-reset">{t('common_contact_support')}</div>
          <a href={SUPPORT_LINK} target="_blank" rel="noreferrer" className="mt-2 d-block">
            {t('common_write_support')}
          </a>
        </div>
      </div>
    </div>
  );
};

export default Error;
