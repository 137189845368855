import React, { useEffect, useState } from 'react';

import { getUserById } from '../../../../../services/userService';
import { getUserTeamRequest } from 'services/teamService';

import InputAutocomplete from 'components/InputAutocomplete';

const CreateNotificationFormReferralsSelect = ({
  formik,
  mode = 'single',
  name,
  editNotificationId,
}) => {
  const [searchReferral, setSearchReferral] = useState('');
  const [editValue, setEditValue] = useState([]);
  const [referrals, setReferrals] = useState([]);
  const handleSelectOption = (option) => {
    if (mode === 'single') {
      formik.setFieldValue('whomNotifySolo', option?.id);
    }

    if (mode === 'multi') {
      formik.setFieldValue(
        'whomNotifyGroup',
        option?.map((item) => item.id),
      );
    }
  };

  useEffect(() => {
    getUserTeamRequest(searchReferral).then((data) => {
      if (data) {
        setReferrals(data?.items);
      }
    });
  }, [searchReferral]);

  useEffect(() => {
    if (editNotificationId) {
      const whomNotifyGroup = formik?.values?.whomNotifyGroup;
      if (!!whomNotifyGroup?.length) {
        setEditValue(whomNotifyGroup);
      }
    }
  }, [editNotificationId]);

  return (
    <div>
      <InputAutocomplete
        value={searchReferral}
        onChange={setSearchReferral}
        options={referrals}
        mode={mode}
        onSelectOption={handleSelectOption}
        placeholder="Partner ID"
        name={name}
        editingValue={editValue}
      />
    </div>
  );
};

export default CreateNotificationFormReferralsSelect;
