import React from 'react';

import { t } from 'i18next';
import { Trans } from 'react-i18next';

import { formatNumber } from '../../../helpers/formatNumber';
import s from './PromotionComponents.module.scss';

const GoalChecker = ({ data = [], conditionCompleted, userLevel }) => {
  const [startPoint = 0, endPoint = 0] = data;
  const completed = isNaN(conditionCompleted) ? 0 : conditionCompleted;

  return (
    <div className={s.goalChecker}>
      <div className={s.goalLineContainer}>
        <div className={s.goalItem}>
          <div className={s.goalValueBlock}>
            <div className={s.goalItemLabel}>$0</div>
            {userLevel > 3 && <div className={s.promoLabel}>{t('first_promo_label')}</div>}
          </div>
          <div className={s.goalValueBlock}>
            {userLevel > 3 && <div className={s.promoLabel}>{t('super_promo_label')}</div>}
            <div className={s.goalItemLabel}>
              ${formatNumber(userLevel > 3 ? endPoint : startPoint, 0)}
            </div>
          </div>
        </div>
        <div className={s.goalLineWrapper}>
          <div
            className={s.goalLine}
            style={{
              width: `${
                completed > (userLevel > 3 ? endPoint : startPoint)
                  ? 100
                  : (completed / (userLevel > 3 ? endPoint : startPoint)) * 100
              }%`,
            }}></div>

          {userLevel > 3 && (
            <div className={s.interimStatsBlock}>
              <div className={s.goalItemLabel}>${formatNumber(startPoint, 0)}</div>
              <div className={s.pointer}></div>
            </div>
          )}
        </div>
      </div>
      <div className={s.goalItem}>
        <div className={s.goalItemLabel}>
          <Trans
            i18nKey="goal_checker_done"
            components={{ bold: <span /> }}
            values={{ value: `$${formatNumber(completed, 0)}` }}
          />
        </div>
        <div className={s.goalItemLabel}>
          <Trans
            i18nKey="goal_checker_remaining"
            components={{ bold: <span /> }}
            values={{
              value: `$${formatNumber(
                completed > (userLevel > 3 ? endPoint : startPoint)
                  ? 0
                  : (userLevel > 3 ? endPoint : startPoint) - completed,
                0,
              )}`,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default GoalChecker;
