import {
  GET_INVESTED_BY_TYPES_ANALYTICS,
  GET_INVESTED_FUNDS_ANALYTICS,
  GET_INVESTED_FUNDS_ANALYTICS_DETAILED,
  GET_INVITED_USERS_ANALYTICS,
  GET_PARTNERS,
  GET_PARTNERS_INCOME,
  GET_PARTNERS_REFERRALS_TREE,
  GET_TEAM_INFO,
  GET_USER_REFERRALS,
  SET_DETAILS_INFO,
  SET_IS_OPENED_REFERRALS_TREE,
  SET_PARTNERS_REFERRALS_TREE,
  SET_USER_REFERRALS,
} from './actionTypes';

export const getTeamInfo = () => ({
  type: GET_TEAM_INFO,
});

export const getPartners = (payload) => ({
  type: GET_PARTNERS,
  payload,
});

export const getPartnersIncome = (level, from, to) => ({
  type: GET_PARTNERS_INCOME,
  payload: { level, from, to },
});

export const getPartnersReferralTree = (id) => ({
  type: GET_PARTNERS_REFERRALS_TREE,
  payload: { id },
});

export const setPartnersReferralsTree = () => ({
  type: SET_PARTNERS_REFERRALS_TREE,
});

export const getInvitedUsersAnalytics = (id, year) => ({
  type: GET_INVITED_USERS_ANALYTICS,
  payload: { id, year },
});

export const getInvestedFundsAnalytics = (id, projects, firstDate, lastDate, groupBy) => ({
  type: GET_INVESTED_FUNDS_ANALYTICS,
  payload: { id, projects, firstDate, lastDate, groupBy },
});

export const getInvestedFundsAnalyticsDetailed = (id, firstDate, lastDate, projects) => ({
  type: GET_INVESTED_FUNDS_ANALYTICS_DETAILED,
  payload: { id, firstDate, lastDate, projects },
});

export const getInvestedByTypesAnalytics = (id, year) => ({
  type: GET_INVESTED_BY_TYPES_ANALYTICS,
  payload: { id, year },
});

export const setDetailsInfo = (payload) => ({
  type: SET_DETAILS_INFO,
  payload,
});

export const getUserReferrals = (id) => ({
  type: GET_USER_REFERRALS,
  payload: { id },
});

export const clearUserReferrals = (payload) => ({
  type: SET_USER_REFERRALS,
  payload,
});

export const setIsOpenReferralsTree = (payload) => ({
  type: SET_IS_OPENED_REFERRALS_TREE,
  payload,
});
