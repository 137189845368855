import { ReactComponent as USDT } from 'assets/images/icons/USDT.svg';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

import { formatNumber } from '../../../../helpers/formatNumber';
import { t } from '../../../../i18n';
import s from './AnalyzeTable.module.scss';

const AnalyzeTable = ({ info }) => {
  const currency = useSelector((state) => state.Currency.currency);

  return (
    <div className={s.wrapper}>
      <div className={s.table}>
        <div className={classNames(s.item)}>
          <div className={s.label}>{t('investments_analyze_total_investments')}</div>
          <div className={s.value}>
            {formatNumber(info?.total_investments ?? 0)} {currency}
          </div>
        </div>
        <div className={classNames(s.item)}>
          <div className={s.label}>{t('investments_analyze_current_investments')}</div>
          <div className={s.value}>
            {formatNumber(info?.current_investments ?? 0)} {currency}
          </div>
        </div>
      </div>
      <div className={s.totalBlock}>
        <div>
          <div className={classNames(s.item, s.fullWidth)}>
            <div className={s.totalTitle}>{t('investments_analyze_final_profit')}</div>
          </div>
          <div className={classNames(s.item, s.fullWidth)}>
            <div className={s.totalValue}>
              {formatNumber(info?.final_profit) ?? 0} {currency}
            </div>
          </div>
        </div>
        <div className={s.currencyBlock}>
          <div className={s.currency}>
            <USDT />
          </div>
        </div>
      </div>
      <div className={s.table}>
        <div className={classNames(s.item)}>
          <div className={s.label}>{t('investments_analyze_total_income')}</div>
          <div className={classNames(s.value, s.blue)}>
            {formatNumber(info?.total_income ?? 0)} {currency}
          </div>
        </div>
        <div className={classNames(s.item)}>
          <div className={s.label}>{t('investments_analyze_total_return')}</div>
          <div className={classNames(s.value, s.blue)}>
            {formatNumber(info?.total_investments_return ?? 0)} {currency}
          </div>
        </div>
        <div className={classNames(s.item)}>
          <div className={s.label}>{t('investments_analyze_total_payed')}</div>
          <div className={classNames(s.value, s.blue)}>
            {formatNumber(info?.total_payed ?? 0)} {currency}
          </div>
        </div>
      </div>
      <div className={s.table}>
        <div className={classNames(s.item)}>
          <div className={s.label}>{t('investments_analyze_future_income')}</div>
          <div className={classNames(s.value, s.green)}>
            {formatNumber(info?.future_income ?? 0)} {currency}
          </div>
        </div>
        <div className={classNames(s.item)}>
          <div className={s.label}>{t('investments_analyze_future_return')}</div>
          <div className={classNames(s.value, s.green)}>
            {formatNumber(info?.future_investments_return ?? 0)} {currency}
          </div>
        </div>
        <div className={classNames(s.item)}>
          <div className={s.label}>{t('investments_analyze_future_payed')}</div>
          <div className={classNames(s.value, s.green)}>
            {formatNumber(info?.future_payed ?? 0)} {currency}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnalyzeTable;
