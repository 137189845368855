import parse from 'html-react-parser';

export const highlightMatches = (text, searchTerm) => {
  if (!searchTerm || searchTerm === '') {
    return text; // нет поискового запроса, возвращаем текст без подсветки
  }

  const regex = new RegExp(`(${searchTerm})`, 'gi');

  const highlightedText = text.replace(regex, '<span class="mark_option">$1</span>');
  return parse(highlightedText); // парсим текст в HTML-разметку
};
