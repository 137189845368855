import React, { useEffect, useMemo, useRef, useState } from 'react';

import blankAvatar from 'assets/images/blankProfile.png';
import { ReactComponent as ChangePhotoIcon } from 'assets/images/icons/photo.svg';
import cn from 'classnames';
import { useFormik } from 'formik';
import { t } from 'i18next';
import PhoneInput from 'react-phone-input-2';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { Form, FormFeedback, Input, Label, Spinner } from 'reactstrap';

import PhotoEditorModal from '../../../components/DepositModals/PhotoEditor/PhotoEditorModal';

import countries from '../../../constants/countries';
import getImageReader from '../../../helpers/GetImageReader';
import WhiteListImages from '../../../helpers/WhiteListImages';
import { uploadPhotoError } from '../../../store/auth/photo/upload/actions';
import { editProfile } from '../../../store/auth/profile/actions';
import { editProfileSchema } from '../../../yupshema/editProfile';
import s from './Components.module.scss';
import { DefaultWalletChange } from './index';

const BaseInfoSetting = () => {
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);
  const [isFileUpload, setFileUpload] = useState(false);
  const [photoType, setPhotoType] = useState(null);
  const [photoPreview, setPhotoPreview] = useState(null);
  const isOpenModal = useMemo(() => isFileUpload && openModal, [isFileUpload, openModal]);
  const { email, fullName, country, mobile, agreement, referrer_id, referrer_name } = useSelector(
    (state) => state.Profile.user,
  );
  const [loading, setLoading] = useState(false);
  const { error, success, photo } = useSelector((state) => ({
    error: state.Profile.error,
    success: state.Profile.success,
    errorUpload: state.PhotoUpload.error,
    successUpload: state.PhotoUpload.upload,
    photo: state.Photo.photo,
  }));
  let fileInputRef = useRef(null);
  const userAvatar = photoPreview || photo || blankAvatar;

  const handleFileChange = async (e) => {
    const file = e.target.files[0];

    if (file) {
      if (!WhiteListImages.includes(file?.type)) {
        return dispatch(uploadPhotoError('Некорректный формат изображения'));
      }

      const result = await getImageReader(file);

      if (result) {
        setPhotoPreview(result);
        setPhotoType(file?.type);
        setFileUpload(true);
        setOpenModal(true);
      }
    }
  };

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      fullName: fullName || '',
      country: countries(t)[0]?.options?.find(({ value }) => value === country),
      mobile: mobile || '',
      agreement: Number(agreement) === 1,
    },
    validationSchema: editProfileSchema,

    onSubmit: (values) => {
      setLoading(true);
      const updatedValues = {};
      for (const key in values) {
        if (values.hasOwnProperty(key) && values[key]) {
          updatedValues[key] = values[key];
        }
      }

      if (values?.agreement) {
        updatedValues.agreement = '1';
      } else {
        updatedValues.agreement = '0';
      }

      dispatch(editProfile(updatedValues));
      setPhotoPreview('');
    },
  });

  useEffect(() => {
    if (success || error) {
      setLoading(false);
      validation.resetForm();
    }
  }, [error, success]);

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        validation.handleSubmit();
        return false;
      }}>
      <div className={s.baseInfoWrapper}>
        <h3 className={s.baseInfoTitle}>{t('common_profile')}</h3>
        <div className={s.formItem}>
          <div className={s.userAvatarWrapper}>
            <img src={userAvatar} alt={`${fullName} photo`} />
            <div className={s.changePhotoBtn} onClick={() => fileInputRef.current.click()}>
              <ChangePhotoIcon />
            </div>
            <input
              hidden={true}
              type="file"
              accept="image/*"
              onChange={handleFileChange}
              ref={fileInputRef}
            />
          </div>
          <div className={s.formField}>
            <Label className={s.label}>{t('user_profile_name')}</Label>
            <Input
              name="fullName"
              className={cn('auth-input', s.input)}
              type="text"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={String(validation.values.fullName) || ''}
              invalid={!!(validation.touched.fullName && validation.errors.fullName)}
            />
            {validation.touched.fullName && validation.errors.fullName ? (
              <FormFeedback type="invalid">{t(validation.errors.fullName)}</FormFeedback>
            ) : null}
          </div>
        </div>

        <div className={s.blockWithBg}>
          <p className={s.label}>{t('user_profile_referrer_info_label')}</p>
          <div className={s.inviterInfo}>
            <div>
              <span className={s.label}>{t('common_name')}:</span>
              <div className={s.value}>{referrer_name}</div>
            </div>
            <div>
              <span className={s.label}>{t('user_profile_referrer_id')}:</span>
              <div className={s.value}>{referrer_id}</div>
            </div>
          </div>
        </div>

        <div className={s.formField}>
          <Label className={s.label}>{t('common_email')}</Label>
          <div className={s.blockWithBg}>{email}</div>
        </div>

        <div className={s.formItem}>
          <div className={s.formField}>
            <Label className={s.label} htmlFor="country">
              {t('common_country')}
            </Label>
            <Select
              id="country"
              placeholder={t('auth_register_choose_country')}
              classNamePrefix="react-select-profile"
              name="country"
              value={validation.values.country || t('auth_register_choose_country')}
              onChange={(value) => validation.setFieldValue('country', value)}
              onBlur={validation.handleBlur}
              options={countries(t)}
              invalid={!!validation.errors.country}
            />
            {validation.errors.country && (
              <FormFeedback type="invalid" className="select__form_feedback">
                {validation.errors.country?.value ?? t(validation.errors.country)}
              </FormFeedback>
            )}
          </div>
          <div className={s.formField}>
            <Label className={s.label} htmlFor="country">
              {t('common_phone_number')}
            </Label>
            <PhoneInput
              onChange={(e) => validation.setFieldValue('mobile', `+${e}`)}
              onBlur={validation.handleBlur}
              value={validation.values.mobile || ''}
              enableAreaCodes
              inputProps={{
                id: 'mobile',
                name: 'mobile',
              }}
              containerClass={cn('phone profile-phone', {
                ['phoneInvalid']: validation.touched.mobile && validation.errors.mobile,
              })}
              inputClass={'phoneInput profile-phone-input'}
              buttonClass={'phoneBtn profile-phone-btn'}
              dropdownClass={'phoneDropdown phoneDropdownProfile'}
            />
            {validation.errors?.mobile && (
              <div className="warning">{t(validation.errors.mobile)}</div>
            )}
          </div>
        </div>

        <DefaultWalletChange />

        <Label className={cn(s.label, s.checkbox)}>
          <Input
            name="agreement"
            type="checkbox"
            checked={validation.values.agreement}
            onChange={validation.handleChange}
            style={{
              fontSize: 18,
              margin: 2,
            }}
          />{' '}
          {t('user_profile_agree_checkbox_label')}
        </Label>
        {error && <div className="alert-warning">{error}</div>}
        <button
          type="submit"
          color="primary"
          className={s.submitBtn}
          disabled={!validation.dirty || Object.keys(validation.errors).length > 0}>
          {loading ? (
            <div className="d-flex gap-2 justify-content-center align-items-center">
              <Spinner className="spinner" /> {t('common_save_changes')}
            </div>
          ) : (
            t('common_save_changes')
          )}
        </button>

        <PhotoEditorModal
          toggle={setOpenModal}
          isOpen={isOpenModal}
          photo={photoPreview}
          photoType={photoType}
          setPhotoPreview={setPhotoPreview}
        />
      </div>
    </Form>
  );
};

export default BaseInfoSetting;
