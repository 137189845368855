import React from 'react';

import { ReactComponent as SuccessIcon } from 'assets/images/icons/success.svg';
import { t } from 'i18next';
import { useHistory } from 'react-router-dom';
import { CardTitle, NavLink } from 'reactstrap';

import { SUPPORT_LINK } from 'constants/links';

import s from './Success.module.scss';

const Success = () => {
  const history = useHistory();

  const redirectToInvest = () => {
    history.push('/investments');
  };

  return (
    <div className={s.wrapper}>
      <div className={s.header}>
        <div className={s.title}>{t('common_success')}</div>
      </div>
      <div
        style={{
          padding: '15px 5px',
        }}>
        <div className="text-center">
          <SuccessIcon />
          <CardTitle className="fs-5 mt-2">
            &#9989; {t('deposit_modal_funds_successfully_enrolled')} &#9989;
          </CardTitle>
          <div>{t('deposit_modal_go_to_investments_packages')}</div>
          <NavLink className="mt-1 text-primary" onClick={redirectToInvest}>
            {t('investments_packages')}
          </NavLink>
        </div>
      </div>
      <div className="text-center">
        <div className="fw-bold fs-5"></div>
        <div className="mt-2"></div>
        <div className="mt-4">
          <div className="mt-2 text-reset">{t('deposit_modal_payment_questions')}</div>
          <div className="mt-2 text-reset">{t('common_contact_support')}</div>
          <a href={SUPPORT_LINK} target="_blank" rel="noreferrer" className="mt-2 d-block">
            {t('common_write_support')}
          </a>
        </div>
      </div>

      <button className={s.buttonContinue} onClick={redirectToInvest}>
        {t('to_investment')}
      </button>
    </div>
  );
};

export default Success;
