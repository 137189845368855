import React, { useCallback, useEffect, useState } from 'react';

import { ReactComponent as USDT } from 'assets/images/icons/bep-20.svg';
import cn from 'classnames';
import { get, post } from 'helpers/api_helper';
import { t } from 'i18next';
import { QRCodeSVG } from 'qrcode.react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import toast from 'react-hot-toast';
import { Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import ShortWallet from '../../../SharedAcrrualsPage/components/ShortWallet';

import { setDisabledProjectTime } from '../../../../helpers/DisabledProject/DisabledProject';
import { validateBep20UsdtAddress } from '../../../../helpers/validateBep20Address';
import useWindowSize from '../../../../hooks/useWindowSize';
import { setProjectButtonCondition } from '../../../../store/projects/project/actions';
import { INVEST_MODAL_STEPS } from '../../utils';
import PaymentHint from '../paymentHint';
import s from './Payment.module.scss';
import PayButton from './PaymentButton';
import Skeleton from './Skeleton';
import Timer from './Timer';
import WalletsTabs from './WalletsTabs';

const QrCodePayment = ({ setActiveSteps, setTransaction, amount }) => {
  const [investWallet, setInvestWallet] = useState('');
  const [isInvestWalletFetching, setIsInvestWalletFetching] = useState(false);
  const { projectDetail } = useSelector((state) => state.Project);
  const currentUser = useSelector((state) => state.Profile.user);
  const [isShowPaymentHint, setIsShowPaymentHint] = useState(false);
  const [transactionId, setTransactionId] = useState(null);
  const disabledProjects = useSelector(
    (state) => state.Project.isDepositButtonDisabledByInvestment,
  );
  const { width: windowWidth } = useWindowSize();
  const [paymentType, setPaymentType] = useState('other');
  const dispatch = useDispatch();

  const handleCreateTransaction = useCallback(async () => {
    if (projectDetail?.service_name) {
      const res = await post('/wallet/transaction', {
        product_service_description: projectDetail?.service_name,
        amount: amount || projectDetail?.min_amount,
      });

      if (res?.data?.id) {
        setTransactionId(res?.data?.id);
      }
    }
  }, [amount, projectDetail?.min_amount, projectDetail?.service_name, setTransactionId]);

  const getQrCodeLink = useCallback(() => {
    const amountInUsdt = amount || projectDetail?.min_amount;

    const walletStandard = {
      other: investWallet,
      metamask: investWallet,
      trustWallet: `ethereum:${investWallet}@56?amount=${amountInUsdt}`,
      safePal: `ethereum:${investWallet}?amount=${amountInUsdt}&token=USDT`,
    };

    return walletStandard[paymentType];
  }, [amount, investWallet, paymentType, projectDetail?.min_amount]);
  const goToAwaitPayment = () => {
    const currentTimePlusMinutes = new Date();
    currentTimePlusMinutes.setMinutes(currentTimePlusMinutes.getMinutes() + 15);
    setDisabledProjectTime(currentTimePlusMinutes.getTime(), projectDetail?.service_name);
    dispatch(setProjectButtonCondition([...disabledProjects, projectDetail?.service_name]));
    setActiveSteps(INVEST_MODAL_STEPS.WAITING);
  };

  const checkTransaction = useCallback(async () => {
    const res = await get(`/wallet/transaction/${transactionId}`);

    if (res?.main_transaction_id) {
      setTransaction(res.main_transaction_id);
      goToAwaitPayment();
    }

    if (res?.status === 'cancelled' || res?.status === 'scam') {
      setActiveSteps(INVEST_MODAL_STEPS.ERROR);
    }
  }, [goToAwaitPayment, setActiveSteps, setTransaction, transactionId]);

  const getInvestWallet = useCallback(async () => {
    setIsInvestWalletFetching(true);
    try {
      const res = await get('/wallet');

      if (!!res.public_key && !!validateBep20UsdtAddress(res.public_key)) {
        setInvestWallet(res.public_key);
      } else {
        setInvestWallet('');
      }
    } catch (e) {
      console.log(e);
    }

    setIsInvestWalletFetching(false);
  }, []);

  useEffect(() => {
    getInvestWallet();
    handleCreateTransaction();
  }, [getInvestWallet]);

  useEffect(() => {
    const interval = setInterval(checkTransaction, 15000);

    return () => {
      clearInterval(interval);
    };
  }, [checkTransaction, transactionId]);

  useEffect(() => {
    if (currentUser?.count_of_deposits < 5) {
      setIsShowPaymentHint(true);
    }
  }, [currentUser]);

  return (
    <div className={s.wrapper}>
      {isShowPaymentHint && (
        <PaymentHint
          amount={amount || projectDetail?.min_amount}
          setIsShowPaymentHint={setIsShowPaymentHint}
        />
      )}
      <div className={s.contentWrapper}>
        <WalletsTabs setPaymentType={setPaymentType} />
        <div className={s.paymentWrapper}>
          <div className={s.header}>
            <div className={s.title}>{t(`pay_${paymentType}_title`)}</div>
          </div>

          <div className={s.content}>
            {!investWallet && isInvestWalletFetching ? (
              <Skeleton />
            ) : (
              <>
                <div className={s.description}>{t('pay_qr_description')}</div>
                <div className={s.qrWrapper}>
                  {paymentType === 'metamask' ? (
                    <PayButton
                      investWallet={investWallet}
                      amount={amount || projectDetail?.min_amount}
                      goAwaitPayment={goToAwaitPayment}
                    />
                  ) : (
                    <QRCodeSVG value={getQrCodeLink()} size={150} />
                  )}
                </div>
                <div>{t('transfer_address_text')}</div>
                <div className={s.address}>
                  <ShortWallet wallet={investWallet} symbolNum={windowWidth < 500 ? 5 : 10} />
                  <CopyToClipboard
                    text={investWallet}
                    onCopy={() => toast.success(t('address_copied_successfully'))}>
                    <button className={s.copyButton}>{t('common_copy_address')}</button>
                  </CopyToClipboard>
                </div>
                <div className={cn(s.item, s.amountInfo)}>
                  <div>
                    <Trans
                      i18nKey="payment_hint"
                      components={{
                        bold: <b />,
                      }}
                    />
                  </div>
                  <div className={s.amount}>
                    <div className="fw-bold">{amount || projectDetail?.min_amount} USDT</div>
                    <div>BEP-20</div>
                    <USDT />
                  </div>
                </div>
                <Timer setActiveSteps={setActiveSteps} />
              </>
            )}
          </div>
        </div>
      </div>
      {!isInvestWalletFetching && !investWallet && (
        <div className={s.overlay}>
          <div className={s.qrError}>
            <h3>{t('common_was_error')}</h3>
            <p>{t('wallet_error_text')}</p>
            <a href="https://t.me/MC_Platform_Support" target="_blank" className={s.supportLink}>
              {t('common_support_label')}
            </a>
          </div>
        </div>
      )}
    </div>
  );
};

export default QrCodePayment;
