import React, { useCallback, useEffect, useState } from 'react';

import cn from 'classnames';
import { Trans } from 'react-i18next';
import { Input } from 'reactstrap';

import { t } from '../../../../i18n';
import { INVEST_MODAL_STEPS } from '../../utils';
import s from './SetAmountInvestorPackage.module.scss';

const SetAmountInvestorPackage = ({ productDetail, setActiveSteps, setInvestAmount }) => {
  const [disableSubmitButton, setDisableSubmitButton] = useState(false);
  const [amount, setAmount] = useState();
  const [amountError, setAmountError] = React.useState('');

  const handleChange = useCallback(
    (e) => {
      const inputRegex = /^[1-9][0-9]*$/;
      const inputValue = e.target.value;

      if (inputValue !== '' && !inputRegex.test(inputValue)) {
        return;
      }

      setAmount(inputValue);

      if (inputValue < Number(productDetail?.min_amount)) {
        setAmountError('minAmount');
        setDisableSubmitButton(true);
      } else {
        setAmountError('');
        setDisableSubmitButton(false);
      }

      if (amount === '') {
        setDisableSubmitButton(true);
      }
    },
    [amount, productDetail?.min_amount, setDisableSubmitButton],
  );
  const goToPayment = () => {
    setActiveSteps(INVEST_MODAL_STEPS.QR);
  };

  useEffect(() => {
    setInvestAmount(amount);
  }, [amount, setInvestAmount]);

  useEffect(() => {
    if (productDetail?.min_amount) {
      setAmount(productDetail?.min_amount);
    }
  }, [productDetail?.min_amount]);

  return (
    <div className={s.wrapper}>
      <div className={s.title}>{t('common_amount')}</div>

      <div className={s.amountHint}>
        <Trans
          i18nKey="invest_modal_amount_hint"
          components={{ bold: <b /> }}
          values={{
            packageName: t(`strapi_${productDetail?.service_name}`),
            amount: productDetail?.min_amount,
          }}
        />
      </div>

      <div className={s.amountInput}>
        <div className={s.label}>{t('invest_modal_enter_amount')}</div>
        <Input
          value={amount}
          onChange={handleChange}
          className={cn(amountError === 'minAmount' && s.invalid)}
        />
        <div className={s.warning}>
          {amountError === 'minAmount' &&
            t('deposit_modal_min_amount', { amount: productDetail?.min_amount })}
        </div>
      </div>

      <button
        className={cn(s.buttonContinue, disableSubmitButton && s.disabled)}
        onClick={goToPayment}
        disabled={disableSubmitButton}>
        {t('common_continue')}
      </button>
    </div>
  );
};

export default SetAmountInvestorPackage;
