import React from 'react';

import LanguageProvider from 'helpers/localization/languageProvider';
import ReactDOM from 'react-dom';
import { Toaster } from 'react-hot-toast';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import App from './App';
import './i18n';
import * as serviceWorker from './serviceWorker';
import store from './store';

const app = (
  <LanguageProvider>
    <Provider store={store}>
      <BrowserRouter>
        <App />
        <Toaster position="top-right" />
      </BrowserRouter>
    </Provider>
  </LanguageProvider>
);

ReactDOM.render(app, document.getElementById('root'));
serviceWorker.unregister();
