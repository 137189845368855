import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import MetaTags from 'react-meta-tags';
import { useDispatch, useSelector } from 'react-redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { t } from '../../i18n';
import { fetchProfileData } from '../../store/auth/profile/actions';
import Notifications from '../Notifications/Notifications';
import IncomeByLevel from './IncomeByLevel';
import Referrals from './Referrals';
import WelcomeComp from './WelcomeComp.jsx';
import './dashboard.scss';

const Dashboard = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.Profile.user);

  useEffect(() => {
    dispatch(fetchProfileData());
  }, [dispatch]);

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>{t('dashboard_meta_title')}</title>
        </MetaTags>
        <div className="dashboard-container">
          <div className="dashboard-info-block">
            {user && <WelcomeComp user={user} />}
            <div className="dashboard-card dashboard-notification_card dashboard-top-block">
              <Notifications />
            </div>
          </div>
          <div className="dashboard-info-block">
            {!!Number(user.investorLevel) && (
              <>
                <div className="dashboard-card dashboard-income-card">
                  <IncomeByLevel />
                </div>
                <div className="dashboard-card dashboard-referrals-card">
                  {user && <Referrals user={user} />}
                </div>
              </>
            )}
          </div>
        </div>
        {/*<Container fluid className="mobile-no-padding">*/}
        {/*  <Row>*/}
        {/*    <Col xl="4">{user && <WelcomeComp user={user} />}</Col>*/}
        {/*    <Col>*/}
        {/*      */}
        {/*    </Col>*/}
        {/*  </Row>*/}

        {/*</Container>*/}
      </div>
    </React.Fragment>
  );
};

Dashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
  success: PropTypes.any,
};

const mapStatetoProps = (state) => {
  const { error, success } = state.Profile;
  return { error, success };
};

export default withRouter(connect(mapStatetoProps, {})(withTranslation()(Dashboard)));
