import React, { useEffect } from 'react';

import { ReactComponent as EmptyIcon } from 'assets/images/icons/empty.svg';
import parse from 'html-react-parser';
import { t } from 'i18next';
import moment from 'moment';
import PropTypes from 'prop-types';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { getNotifications } from 'store/actions';

const Notifications = ({ notifications }) => {
  const dispatch = useDispatch();
  const language = useSelector((state) => state.Layout.language);

  const sortedNotifications = React.useMemo(() => {
    if (!notifications) {
      return [];
    }
    return [...notifications].sort((a, b) =>
      moment(b.notification_date).diff(moment(a.notification_date)),
    );
  }, [notifications]);

  const hasNotifications = sortedNotifications.length > 0;

  useEffect(() => {
    dispatch(getNotifications({ locale: language || 'en' }));
  }, [dispatch, language]);

  return (
    <>
      <div className="notification_title_block">
        <h4 className="dashboard-block-title">{t('notification_title')}</h4>
        <Link className="notification-view-all" to="notifications">
          {t('notification_view_all')}
        </Link>
      </div>
      {!hasNotifications && (
        <div className="referrals-zero" style={{ minHeight: 450 }}>
          <div>
            <EmptyIcon />
            <p>{t('notification_not_have_data')}</p>
          </div>
        </div>
      )}
      <div className="notification-list-wrapper">
        {hasNotifications &&
          sortedNotifications.map((n, i) => (
            <div className="notification-item-wrapper" key={`notification-${i}_${n.id}`}>
              <div className="notification_leftside">
                <h4 className="notification_title">{n.notification_title}</h4>
                <p className="notification_text">{parse(String(n.notification_text) || '')}</p>
              </div>
              <div className="notification_rightside">
                <p className="notification_type">{n.notifications_type?.title ?? ''}</p>
                {n.notification_date && (
                  <p className="notification_date_list">
                    {moment(n.notification_date).format('HH:MM DD.MM.YYYY')}
                  </p>
                )}
              </div>
            </div>
          ))}
      </div>
    </>
  );
};

Notifications.propTypes = {
  notifications: PropTypes.any,
  onGetNotifications: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    notifications: state.Notifications.notifications,
  };
};

export default connect(mapStateToProps)(Notifications);
