export const DETAILS_INFO_CONTENT = {
  en: {
    structureInvestments: 'Structural Investments',
    referrals: 'Referrals',
    personalInvestments: 'Personal Investments',
    structureButton: 'Go to the structure',
    dataHidden: 'Hidden',
  },
  ru: {
    structureInvestments: 'Структурные инвестиции',
    referrals: 'Рефералы',
    personalInvestments: 'Персональные инвестиции',
    structureButton: 'К структуре',
    dataHidden: 'Скрыто',
  },
  ar: {
    structureInvestments: 'الاستثمارات الهيكلية',
    referrals: 'الإحالات',
    personalInvestments: 'الاستثمارات الشخصية',
    structureButton: 'اذهب إلى الهيكل',
    dataHidden: 'مخفي',
  },
  de: {
    structureInvestments: 'Strukturelle Investitionen',
    referrals: 'Empfehlungen',
    personalInvestments: 'Persönliche Investitionen',
    structureButton: 'Zur Struktur gehen',
    dataHidden: 'Versteckt',
  },
  es: {
    structureInvestments: 'Inversiones estructurales',
    referrals: 'Referencias',
    personalInvestments: 'Inversiones personales',
    structureButton: 'Ir a la estructura',
    dataHidden: 'Oculto',
  },
  tr: {
    structureInvestments: 'Yapısal Yatırımlar',
    referrals: 'Yönlendirmeler',
    personalInvestments: 'Kişisel Yatırımlar',
    structureButton: 'Yapıya git',
    dataHidden: 'Gizli',
  },
  fr: {
    structureInvestments: 'Investissements structurels',
    referrals: 'Parrainages',
    personalInvestments: 'Investissements personnels',
    structureButton: 'Aller à la structure',
    dataHidden: 'Caché',
  },
  it: {
    structureInvestments: 'Investimenti strutturali',
    referrals: 'Raccomandazioni',
    personalInvestments: 'Investimenti personali',
    structureButton: 'Vai alla struttura',
    dataHidden: 'Nascosto',
  },
  hi: {
    structureInvestments: 'संरचनात्मक निवेश',
    referrals: 'रेफरल',
    personalInvestments: 'व्यक्तिगत निवेश',
    structureButton: 'संरचना पर जाएं',
    dataHidden: 'छुपा हुआ',
  },
  ka: {
    structureInvestments: 'სტრუქტურული ინვესტიციები',
    referrals: 'რეფერალები',
    personalInvestments: 'პირადი ინვესტიციები',
    structureButton: 'გადასვლა სტრუქტურაზე',
    dataHidden: 'დაფარული',
  },
  kk: {
    structureInvestments: 'Құрылымдық инвестициялар',
    referrals: 'Жолдамалар',
    personalInvestments: 'Жеке инвестициялар',
    structureButton: 'Құрылымға өту',
    dataHidden: 'Жасырылған',
  },
  uz: {
    structureInvestments: 'Tuzilma investitsiyalari',
    referrals: 'Tavsiya qilinganlar',
    personalInvestments: 'Shaxsiy investitsiyalar',
    structureButton: "Tuzilmaga o'tish",
    dataHidden: 'Yashirin',
  },
  zh: {
    structureInvestments: '结构性投资',
    referrals: '推荐',
    personalInvestments: '个人投资',
    structureButton: '前往结构',
    dataHidden: '隐藏',
  },
};
