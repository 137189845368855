/** Get Notifications data */
export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';
export const GET_ALL_NOTIFICATIONS = 'GET_ALL_NOTIFICATIONS';
export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const EDIT_NOTIFICATION = 'EDIT_NOTIFICATION';
export const DELETE_NOTIFICATION = 'DELETE_NOTIFICATION';
export const SELECT_NOTIFICATION = 'SELECT_NOTIFICATION';

export const GET_NOTIFICATIONS_SETTINGS = 'GET_NOTIFICATIONS_SETTINGS';

export const SET_NOTIFICATIONS_SETTINGS = 'SET_NOTIFICATIONS_SETTINGS';

export const SET_NOTIFICATION_SETTINGS_LOADING = 'SET_NOTIFICATION_SETTINGS_LOADING';
