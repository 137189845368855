import {
  SEND_CONFIRM_CODE,
  SEND_CONFIRM_CODE_ERROR,
  SEND_CONFIRM_CODE_RESEND,
  SEND_CONFIRM_CODE_SUCCESSFUL,
} from './actionTypes';

export const sendConfirmCode = (payload) => {
  return {
    type: SEND_CONFIRM_CODE,
    payload: payload,
  };
};

export const sendConfirmCodeSuccessful = (payload) => {
  return {
    type: SEND_CONFIRM_CODE_SUCCESSFUL,
    payload: payload,
  };
};

export const sendConfirmCodeError = (payload) => {
  return {
    type: SEND_CONFIRM_CODE_ERROR,
    payload: payload,
  };
};

export const resendConfirmCode = (payload) => {
  return {
    type: SEND_CONFIRM_CODE_RESEND,
    payload: payload,
  };
};
