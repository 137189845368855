import { get } from 'helpers/api_helper';

export const getUserStructure = async (payload) => {
  return await get(
    `users/team/structure/${payload?.partnerId}?take=${payload?.take}&skip=${payload?.skip}&search_str=${payload?.search_str}&order_by=${payload?.order_by}&order=${payload?.order}`,
  );
};

export const getUserPartners = async (payload) => {
  return await get(
    `users/me/team/partners?first_level=${payload?.isFirstLine}&take=${payload?.take}&skip=${payload?.skip}&search_str=${payload?.search_str}&order_by=${payload?.order_by}&order=${payload?.order}`,
  );
};

export const getPartnersRequest = async (id) => {
  return await get(`user/referrals/tree?user_partner_id=${id}`);
};

export const getInvitedUsersAnalyticsRequest = async (id, year) => {
  return await get(`user/referrals/graphic/invited_users?partner_id=${id}&year=${year}`);
};

export const getInvestedFundsAnalyticsRequest = async (
  id,
  projects,
  firstDate,
  lastDate,
  groupBy = 'month',
) => {
  const projectsParams = projects
    ? `&${projects.map((item) => `product_service_description=${item}`).join('&')}`
    : '';

  const dateGt = firstDate ? `&payment_date__gt=${firstDate}` : '';
  const dateLt = lastDate ? `&payment_date__lt=${lastDate}` : '';

  return await get(
    `user/referrals/graphic/invested_funds?partner_id=${id}${dateGt}${dateLt}${projectsParams}&group_by=${groupBy}`,
  );
};

export const getInvestedFundsAnalyticsDetailsRequest = async (
  id,
  firstDate,
  lastDate,
  projects,
) => {
  const projectsParams = projects
    ? `&${projects.map((item) => `product_service_description=${item}`).join('&')}`
    : '';
  const dateLt = lastDate ? `&payment_date__lt=${lastDate}` : '';
  return await get(
    `user/referrals/graphic/invested_funds_detailed?partner_id=${id}&payment_date__gt=${firstDate}${dateLt}${projectsParams}`,
  );
};

export const getInvestedByTypesAnalyticsRequest = async (id, year) => {
  return await get(`user/referrals/graphic/investments_by_types?partner_id=${id}&year=${year}`);
};

export const getUserReferralsRequest = async (id) => {
  return await get(`user/referrers?user_partner_id=${id}`);
};

export const getUserTeamRequest = async (id) => {
  return await get(`user/referrals?referral_partner_id=${id}`);
};
