export function hasDuplicateDate(dates) {
  const dateMap = new Map();

  for (const dateObj of dates) {
    const date = new Date(dateObj.date);
    const monthYear = `${date.getMonth()}-${date.getFullYear()}`;

    if (dateMap.has(monthYear)) {
      return true;
    }

    dateMap.set(monthYear, true);
  }

  return false;
}
