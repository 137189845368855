import axios from 'axios';
import { del, get, post, postFormData, put } from 'helpers/api_helper';

const notificationPath = '/notifications';

export const getNotificationsTypeRequest = async (locale) => {
  return await get(`${notificationPath}/types?locale=${locale}`);
};

export const getAllNotificationsRequest = async (locale) => {
  return await get(`${notificationPath}/all?locale=${locale}`);
};

export const addNotificationRequest = async (data) => {
  return await post(`${notificationPath}`, data);
};

export const editNotificationRequest = async (data) => {
  return await put(`${notificationPath}`, data);
};

export const deleteNotificationRequest = async (data) => {
  return await del(`${notificationPath}`, {
    data,
  });
};

export const getOneNotificationRequest = async (notificationId) => {
  return await get(`${notificationPath}/${notificationId}`);
};

export const deleteNotificationTypeRequest = async (notificationTypeId) => {
  return await del(`${notificationPath}/types/${notificationTypeId}`);
};

export const createNotificationTypeRequest = async (data) => {
  return await post(`${notificationPath}/types`, data);
};

export const getOneNotificationTypeRequest = async (notificationTypeId) => {
  return await get(`${notificationPath}/types/${notificationTypeId}`);
};

export const editNotificationTypeRequest = async (notificationTypeId, data) => {
  return await put(`${notificationPath}/types/${notificationTypeId}`, data);
};

export const getNotificationsSettingsRequest = async (lng) => {
  return await get(`users/me/notification/config?locale=${lng}`);
};

export const setNotificationsSettingsRequest = async (data) => {
  return await postFormData(`users/me/notification/config`, data);
};
