import React, { useCallback, useEffect, useState } from 'react';

import Logo from 'assets/images/logo-black.png';
import cn from 'classnames';
import { useFormik } from 'formik';
import { getUserDefaultCountry } from 'helpers/location/location';
import withAuthRedirect from 'hocs/withAuthRedirect';
import { useGeneratedPassword } from 'hooks/useGeneratedPassword';
import PropTypes from 'prop-types';
import { Trans } from 'react-i18next';
import MetaTags from 'react-meta-tags';
import PhoneInput from 'react-phone-input-2';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import { Form, FormFeedback, Input, Label, Spinner } from 'reactstrap';
import { confirmSchema, registerSchema } from 'yupshema/registerSchema';

import {
  apiError,
  registerUser,
  registerUserSuccessful,
  resendConfirmCode,
  sendConfirmCode,
  sendConfirmCodeError,
} from 'store/actions';

import PasswordInput from 'components/Custom/passwordInput';

import countries from 'constants/countries';
import { SUPPORT_LINK } from 'constants/links';

import { t } from '../../i18n';
import DisplayReferralName from './DisplayReferralName';
import s from './scss/AuthPages.module.scss';

const Register = (props) => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const generatedPassword = useGeneratedPassword(12);
  const partnerId = new URLSearchParams(search).get('partner_id');
  const [registrationStep, setRegistrationStep] = useState('init');
  const [initRegisterData, setInitRegisterData] = useState({});
  const [isActiveSendBtn, setIsActiveSendBtn] = useState(true);
  const [countdownSendBtn, setCountdownSendBtn] = useState(12);

  const validationConfirmForm = useFormik({
    enableReinitialize: false,
    initialValues: {
      email: '',
      confirm_code: '',
    },
    validationSchema: confirmSchema,
    onSubmit: (values) => {
      dispatch(
        registerUser(
          {
            ...values,
            ...initRegisterData,
            email: values?.email?.toLowerCase(),
            agreement: initRegisterData.agreement ? 1 : 0,
            mobile: initRegisterData.mobile ? initRegisterData.mobile?.trim() : null,
            wallet_address: initRegisterData.default_wallet_address.trim(),
            code: values.confirm_code.replace('-', ''),
          },
          props.history,
        ),
      );
    },
  });

  const validation = useFormik({
    enableReinitialize: false,
    initialValues: {
      referrerId: partnerId,
      agreement: true,
      default_wallet_address: '',
      password: generatedPassword,
      fullName: '',
      mobile: '',
      country: '',
      confirm_password: generatedPassword,
    },
    validationSchema: registerSchema,
    onSubmit: (values) => {
      setInitRegisterData({
        ...values,
        agreement: values.agreement ? 1 : 0,
        mobile: values.mobile ? values.mobile?.trim() : null,
      });
      setRegistrationStep('confirm');
    },
  });

  const verificationHandler = useCallback(() => {
    if (validationConfirmForm.values.email && !validationConfirmForm.errors.email) {
      setIsActiveSendBtn(false);
      dispatch(sendConfirmCodeError(''));
      dispatch(
        resendConfirmCode({
          type: 'registration',
          email: validationConfirmForm.values.email,
        }),
      );
    } else {
      validationConfirmForm.setFieldError('email', t('common_email_hint_required'));
    }
  }, [dispatch, validationConfirmForm]);

  const handleGetDefaultCountry = async () => {
    const country = await getUserDefaultCountry();

    const defaultCountryOption = country?.name
      ? { label: country.name, value: country.code }
      : null;

    await validation.setFieldValue('country', defaultCountryOption);
  };

  const { registerSuccessful, registrationError, loading } = useSelector((state) => ({
    registerSuccessful: state.Account.registerSuccessful,
    registrationError: state.Account.registrationError,
    loading: state.Account.loading,
  }));
  const { error: confirmedError } = useSelector((state) => ({
    error: state.Users.confirmedError,
    loading: state.Users.confirmedLoading,
  }));

  useEffect(() => {
    handleGetDefaultCountry();
    dispatch(apiError(''));
  }, []);

  useEffect(() => {
    if (registerSuccessful) {
      props.history.push('/confirm-email');
      localStorage.setItem('verifyEmail', validation.values.email);

      return () => {
        dispatch(registerUserSuccessful(false));
      };
    }
  }, [registerSuccessful]);

  useEffect(() => {
    let interval = null;

    if (!isActiveSendBtn && countdownSendBtn !== 0) {
      interval = setInterval(() => {
        setCountdownSendBtn((prevCountdown) => prevCountdown - 1);
      }, 1000);
    } else if (countdownSendBtn === 0) {
      setIsActiveSendBtn(true);
      setCountdownSendBtn(120);
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [isActiveSendBtn, countdownSendBtn]);

  useEffect(() => {
    if (confirmedError) {
      setIsActiveSendBtn(true);
      setCountdownSendBtn(12);
    }
  }, [confirmedError]);

  return (
    <React.Fragment>
      <MetaTags>
        <title>{t('auth_register_meta_title')}</title>
      </MetaTags>
      <div className={cn(s.wrapper, s.registerWrapper)}>
        <div className={s.formWrapper}>
          <div className={s.logoBlock}>
            <img src={Logo} alt="logo" className={s.logo} />
            <div>
              <h4 className={s.title}>{t('auth_register')}</h4>
            </div>
          </div>

          {registrationStep === 'init' && (
            <Form
              className={s.form}
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
              }}>
              {typeof registrationError === 'string' && (
                <div className="alert-warning">{`${t(registrationError)}`}</div>
              )}
              {!!registrationError?.error && (
                <div className="alert-warning">{registrationError?.error}</div>
              )}

              <div className={s.registerBlock}>
                <div>
                  <Label className={s.formLabel} htmlFor="referrerId">
                    {t('auth_register_inviter_id')}
                  </Label>
                  <Input
                    name="referrerId"
                    placeholder={t('auth_register_inviter_placeholder')}
                    className="auth-input"
                    type="text"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.referrerId || ''}
                    invalid={
                      !!(
                        (validation.touched.referrerId && validation.errors.referrerId) ||
                        (registrationError && registrationError.referrerId)
                      )
                    }
                  />
                  {(validation.touched.referrerId && validation.errors.referrerId) ||
                  (registrationError && registrationError.referrerId) ? (
                    <FormFeedback type="invalid" className="auth-input-warning-hint">
                      {t('auth_register_inviter_error')}
                    </FormFeedback>
                  ) : null}
                  <DisplayReferralName
                    referralId={validation.values.referrerId}
                    setError={validation.setFieldError}
                    setTouched={validation.setFieldTouched}
                  />
                </div>
                <div>
                  <Label className={s.formLabel} htmlFor="fullName">
                    {t('auth_register_full_name')}
                  </Label>
                  <Input
                    id="fullName"
                    name="fullName"
                    className="auth-input"
                    placeholder={t('auth_register_full_name_placeholder')}
                    onChange={validation.handleChange}
                    value={validation.values.fullName || ''}
                    onBlur={validation.handleBlur}
                    invalid={
                      !!(
                        (validation.touched.fullName && validation.errors.fullName) ||
                        (registrationError && registrationError.fullName)
                      )
                    }
                  />
                  {(validation.touched.fullName && validation.errors.fullName) ||
                  (registrationError && registrationError.fullName) ? (
                    <FormFeedback type="invalid" className="auth-input-warning-hint">
                      {t('common_field_error')}
                    </FormFeedback>
                  ) : null}
                </div>
              </div>

              <div className={s.registerBlock}>
                <div>
                  <Label className={s.formLabel} htmlFor="country">
                    {t('auth_register_choose_country')}
                  </Label>
                  <Select
                    id="country"
                    classNamePrefix="react-select"
                    placeholder={t('auth_register_choose_country_placeholder')}
                    name="country"
                    value={validation.values.country || t('auth_register_choose_country')}
                    onChange={(value) => validation.setFieldValue('country', value)}
                    // onBlur={validation.handleBlur}
                    options={countries(t)}
                  />
                </div>

                <div>
                  <Label className={s.formLabel} htmlFor="mobile">
                    {t('common_phone_number')}
                  </Label>
                  <PhoneInput
                    country={validation.values?.country?.value?.toLowerCase() || ''}
                    onChange={(e) => validation.setFieldValue('mobile', `+${e}`)}
                    onBlur={validation.handleBlur}
                    value={validation.values.mobile || ''}
                    enableAreaCodes
                    inputProps={{
                      id: 'mobile',
                      name: 'mobile',
                    }}
                    containerClass={cn('phone', {
                      ['phoneInvalid']: validation.touched.mobile && validation.errors.mobile,
                    })}
                    inputClass={'phoneInput'}
                    buttonClass={'phoneBtn'}
                    dropdownClass={'phoneDropdown'}
                  />
                  {(validation.touched.mobile && validation.errors.mobile) ||
                  (registrationError && registrationError.mobile) ? (
                    <div className="auth-input-warning-hint">{t(validation.errors.mobile)}</div>
                  ) : null}
                </div>
              </div>

              <div className={s.registerBlock}>
                <div>
                  <div className={s.forgotWrapper}>
                    <Label className={s.formLabel} htmlFor="password">
                      {`${t('common_password')}`}
                    </Label>
                  </div>

                  <PasswordInput
                    showPassword={true}
                    validation={validation}
                    name="password"
                    id="password"
                    placeholder={`${t('common_password_placeholder')}`}
                    invalid={!!(validation.touched.password && validation.errors.password)}
                  />
                </div>
                <div>
                  <div className={s.forgotWrapper}>
                    <Label className={s.formLabel} htmlFor="confirm_password">
                      {`${t('auth_register_repeat_password')}`}
                    </Label>
                  </div>

                  <PasswordInput
                    showPassword={true}
                    validation={validation}
                    name="confirm_password"
                    id="confirm_password"
                    placeholder={`${t('common_password_placeholder')}`}
                    invalid={
                      !!(validation.touched.confirm_password && validation.errors.confirm_password)
                    }
                  />
                </div>
              </div>

              <div>
                <Label className={s.formLabel} htmlFor="email">
                  {`${t('set_default_wallet_modal_label')} (BEP-20)`}
                </Label>
                <Input
                  id="default_wallet_address"
                  name="default_wallet_address"
                  className="auth-input"
                  placeholder={t('set_default_wallet_modal_placeholder')}
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.default_wallet_address || ''}
                  invalid={
                    !!(
                      (validation.touched.default_wallet_address &&
                        validation.errors.default_wallet_address) ||
                      (registrationError && registrationError.default_wallet_address)
                    )
                  }
                />
                {(validation.touched.default_wallet_address &&
                  validation.errors.default_wallet_address) ||
                (registrationError && registrationError.default_wallet_address) ? (
                  <FormFeedback type="invalid" className="auth-input-warning-hint">
                    {validation.errors.default_wallet_address}
                  </FormFeedback>
                ) : null}
              </div>

              <div className={s.rememberWrapper}>
                <Input
                  type="checkbox"
                  className="form-check-input"
                  name="agreement"
                  id="agreement"
                  style={{ margin: 0, flexShrink: 0 }}
                  checked={validation.values.agreement}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                />
                <label className={s.checkboxLabel} htmlFor="agreement" style={{ fontSize: 16 }}>
                  {`${t('auth_register_agree_checkbox_label')}`}
                </label>
              </div>

              <div className={s.actions}>
                <button className={s.submitButton} type="submit" disabled={loading}>
                  {loading ? (
                    <div className="d-flex gap-2 justify-content-center align-items-center">
                      <Spinner className="spinner" /> {t('common_continue')}
                    </div>
                  ) : (
                    t('common_continue')
                  )}
                </button>
              </div>

              <div className={s.footerLinks}>
                <p className={s.bottomText}>
                  {t('auth_register_have_account')}{' '}
                  <Link to="login" className={s.bottomTextLink}>
                    {t('auth_register_login')}
                  </Link>
                </p>
                <div className={s.bottomText}>
                  <Trans
                    i18nKey="auth_register_support"
                    components={{
                      supportLink: (
                        <a href={SUPPORT_LINK} target="_blank" style={{ color: '#0c958f' }} />
                      ),
                    }}
                  />
                </div>
              </div>
            </Form>
          )}
          {registrationStep === 'confirm' && (
            <Form
              className={s.form}
              onSubmit={(e) => {
                e.preventDefault();
                validationConfirmForm.handleSubmit();
              }}>
              {confirmedError ? (
                <div className="alert-warning">{`${t(confirmedError)}`}</div>
              ) : null}
              <div>
                <Label className={s.formLabel} htmlFor="email">
                  {t('common_email')}
                </Label>
                <Input
                  id="email"
                  name="email"
                  className="auth-input"
                  placeholder={t('common_email_placeholder')}
                  type="text"
                  onChange={validationConfirmForm.handleChange}
                  onBlur={validationConfirmForm.handleBlur}
                  value={validationConfirmForm.values.email || ''}
                  invalid={
                    !!(
                      validationConfirmForm.errors.email ||
                      (confirmedError &&
                        (confirmedError === 'user_already_activated' ||
                          confirmedError === 'incorrectEmail'))
                    )
                  }
                />
                {validationConfirmForm.touched.email ||
                validationConfirmForm.errors.email ||
                (confirmedError && confirmedError === 'user_already_activated') ? (
                  <FormFeedback type="invalid" className="auth-input-warning-hint">
                    {confirmedError === 'user_already_activated'
                      ? t('user_already_activated')
                      : validationConfirmForm.errors.email}
                  </FormFeedback>
                ) : null}
              </div>

              <div>
                <Label className={s.formLabel}>
                  {t('auth_recover_password_confirm_password_code')}
                </Label>
                <div className={s.confirmCodeWrapper}>
                  <Input
                    id="confirm_code"
                    name="confirm_code"
                    className="auth-input"
                    placeholder="XXX-XXX"
                    type="text"
                    onChange={validationConfirmForm.handleChange}
                    onBlur={validationConfirmForm.handleBlur}
                    value={validationConfirmForm.values.confirm_code || ''}
                    invalid={
                      !!(
                        (validationConfirmForm.touched.confirm_code &&
                          validationConfirmForm.errors.confirm_code) ||
                        (registrationError && registrationError.confirm_code)
                      )
                    }
                  />
                  <button
                    className={s.confirmCodeBtn}
                    type="button"
                    onClick={verificationHandler}
                    disabled={!isActiveSendBtn}>
                    {isActiveSendBtn
                      ? t('common_get_code')
                      : `${countdownSendBtn} ${t('common_seconds')}`}
                  </button>
                </div>
                {validationConfirmForm.touched.confirm_code &&
                validationConfirmForm.errors.confirm_code ? (
                  <div className="auth-input-warning-hint">
                    {validationConfirmForm.errors.confirm_code}
                  </div>
                ) : null}
              </div>

              <div className={s.actions}>
                <button className={s.submitButton} type="submit" disabled={loading}>
                  {loading ? (
                    <div className="d-flex gap-2 justify-content-center align-items-center">
                      <Spinner className="spinner" /> {t('auth_register')}
                    </div>
                  ) : (
                    t('auth_register')
                  )}
                </button>
              </div>

              <div className={s.footerLinks}>
                <p className={s.bottomText}>
                  {t('auth_register_have_account')}{' '}
                  <Link to="login" className={s.bottomTextLink}>
                    {t('auth_register_login')}
                  </Link>
                </p>
                <div className={s.bottomText}>
                  <Trans
                    i18nKey="auth_register_support"
                    components={{
                      supportLink: (
                        <a href={SUPPORT_LINK} target="_blank" style={{ color: '#0c958f' }} />
                      ),
                    }}
                  />
                </div>
              </div>
            </Form>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

Register.propTypes = {
  history: PropTypes.object,
};

export default withAuthRedirect(Register);
