import { get } from 'helpers/api_helper';

export const fetchInvestments = async (payload) => {
  return await get(
    `user/deposit?take=${payload.take}&skip=${payload.skip}&is_closed=${
      payload.isClosed
    }&order_by=${payload.sortField || 'date'}&order=${payload.sortOrder || 'DESC'}&locale=${
      payload.locale || 'en'
    }`,
  );
};

export const fetchInvestmentsSummary = async () => {
  return await get('user/deposit/investment-summary');
};

export const fetchInvestmentsAnalysis = async () => {
  return await get('user/deposit/investments-info');
};
