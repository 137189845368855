import React, { useCallback, useEffect, useState } from 'react';

import { get } from 'helpers/api_helper';
import { t } from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { fetchProfileData } from '../../../../store/auth/profile/actions';
import { INVEST_MODAL_STEPS } from '../../utils';
import s from './WaitingConfirmation.module.scss';

const WaitingConfirmation = ({ transactionId, setActiveSteps }) => {
  const { projectDetail } = useSelector((state) => state.Project);
  const dispatch = useDispatch();
  const [timer, setTimer] = useState(5 * 60 - 1);
  const history = useHistory();

  const checkMainTransaction = useCallback(async () => {
    if (transactionId) {
      const res = await get(`/transactions?transactionId=${transactionId}`);

      if (res?.transaction_status === 'accepted') {
        setActiveSteps(INVEST_MODAL_STEPS.SUCCESS);
        localStorage.removeItem(`MC_DEPOSIT_DISABLED_${projectDetail?.service_name}`);
        dispatch(fetchProfileData());
      }

      if (res?.transaction_status === 'cancelled' || res?.transaction_status === 'scam') {
        setActiveSteps(INVEST_MODAL_STEPS.ERROR);
      }
    }
  }, [transactionId, setActiveSteps, projectDetail?.service_name, dispatch]);

  const timerFormatted = (() => {
    if (timer === 0) return { minutes: '0', seconds: '00' };
    const minutes = Math.floor(timer / 60);
    const seconds = timer - minutes * 60;
    return {
      minutes,
      seconds: seconds >= 10 ? seconds : `0${seconds}`,
    };
  })();

  const goToInvestments = () => {
    history.push('/investments');
  };

  useEffect(() => {
    if (timer > 0) {
      const timerSpeed = 1;
      const timeout = setInterval(() => {
        setTimer((prev) => prev - timerSpeed);
      }, timerSpeed * 1000);
      return () => clearInterval(timeout);
    }
  }, [timer]);

  useEffect(() => {
    checkMainTransaction();
  }, [checkMainTransaction]);

  useEffect(() => {
    if (transactionId) {
      const interval = setInterval(checkMainTransaction, 20000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [checkMainTransaction, transactionId]);

  return (
    <div className={s.wrapper}>
      <div className={s.header}>
        <div className={s.title}>{t('awaiting_confirmation')}</div>
      </div>
      <div className={s.square}>
        <span></span>
        <span></span>
        <span></span>
        <div className={s.content}>
          <div className={s.status}> &#8987; {t('deposit_modal_waiting_payment')} &#8987;</div>
          <div>
            {t('deposit_modal_approximate_time', {
              minutes: timerFormatted.minutes,
              seconds: timerFormatted.seconds,
            })}
          </div>
        </div>
      </div>
      <div className={s.textContent}>
        <div className={s.statusTitle}>{t('awaiting_text')}</div>
        <div>{t('confirm_text')}</div>
      </div>

      <button className={s.buttonContinue} onClick={goToInvestments}>
        {t('to_investment')}
      </button>
    </div>
  );
};

export default WaitingConfirmation;
