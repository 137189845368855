import React from 'react';

import { formatNumber } from 'helpers/formatNumber';
import i18next from 'i18next';
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import YearRangeDatePicker from '../../../../components/YearRangeDatePicker';
import EmptyState from 'components/EmptyState';

import s from './AccrualsGraph.module.scss';
import CustomLegend from './CustomeLegend';

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className={s.tooltip}>
        <div>{label}</div>
        {payload?.map((item) => (
          <div style={{ color: item.color }} key={item.name}>
            {i18next.t(item?.name)}: {formatNumber(item.value, 4)}
          </div>
        ))}
      </div>
    );
  }

  return null;
};

const AccrualsGraph = ({ accrualsGraphData, activeYears, setActiveYear }) => {
  return (
    <div className={s.chartWrapper}>
      <div>
        {activeYears && (
          <YearRangeDatePicker activeYears={activeYears} onChange={(date) => setActiveYear(date)} />
        )}
      </div>
      <div className={s.chartContainer}>
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            data={accrualsGraphData}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip content={CustomTooltip} />
            <Legend
              wrapperStyle={{
                display: 'flex',
                justifyContent: 'center',
                width: '-webkit-fill-available',
              }}
              content={CustomLegend}
            />
            <Line
              type="monotone"
              dataKey="passive_accruals_amount"
              stroke="#556EE6"
              activeDot={{ r: 8 }}
            />
            <Line type="monotone" dataKey="product_accruals_amount" stroke="#F1B44C" />
            <Line type="monotone" dataKey="referral_accruals_amount" stroke="#EB2F96" />
          </LineChart>
        </ResponsiveContainer>
      </div>
      {accrualsGraphData?.length === 0 && (
        <div className={s.emptyState}>
          <EmptyState />
        </div>
      )}
    </div>
  );
};

export default AccrualsGraph;
