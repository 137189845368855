import React, { useEffect, useState } from 'react';

import { useDebounce } from 'hooks/useDebounce';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import { Card, CardBody } from 'reactstrap';

import { getPartners } from 'store/team/actions';

import EmptyState from 'components/EmptyState';
import PaginationComponent from 'components/PaginationComponent';

import '../scss/list.scss';
import List from './List';

export function headerFormatter(column, colIndex, { sortElement }) {
  return (
    <div className="head-column">
      <p>{column.text}</p>
      {sortElement}
    </div>
  );
}

const PartnersList = ({ partners, userPartnerId, fullName, treeRef, localizeFullName }) => {
  const dispatch = useDispatch();
  const [isFirstLine, setIsFirstLine] = useState(false);
  const [queryString, setQueryString] = useState('');
  const [pagination, setPagination] = useState({});
  const [sort, setSort] = useState({ field: 'created_at', order: 'desc' });
  const debounceQueryString = useDebounce(queryString, 500);

  useEffect(() => {
    if (pagination?.pageNumber) {
      dispatch(
        getPartners({
          take: pagination?.size,
          skip: (pagination?.pageNumber - 1) * pagination?.size,
          isFirstLine: isFirstLine,
          search_str:
            debounceQueryString?.charAt(0) === '+'
              ? `%2B${debounceQueryString.slice(1)}`
              : debounceQueryString,
          order_by: sort?.field,
          order: sort?.order?.toUpperCase(),
        }),
      );
    }
  }, [pagination, dispatch, isFirstLine, debounceQueryString, sort]);

  return (
    <Card>
      <CardBody>
        <List
          fullName={fullName}
          localizeFullName={localizeFullName}
          partners={partners?.items || []}
          userPartnerId={userPartnerId}
          isFirstLine={isFirstLine}
          setIsFirstLine={setIsFirstLine}
          searchValue={queryString}
          setSearchValue={setQueryString}
          setSort={setSort}
          treeRef={treeRef}
        />

        {!partners?.items?.length && <EmptyState />}

        <PaginationComponent
          active={pagination?.pageNumber}
          setPagination={setPagination}
          total={partners?.total_pages_count}
        />
      </CardBody>
    </Card>
  );
};

PartnersList.propTypes = {
  partners: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  fullName: PropTypes.any,
  localizeFullName: PropTypes.any,
  userPartnerId: PropTypes.any,
};

const mapStateToProps = (state) => ({
  partners: state.Team.partners,
  userPartnerId: state.Profile.user.partnerId,
  fullName: state.Profile.user.fullName,
  localizeFullName: state.Profile.user.localized_full_name,
});

export default connect(mapStateToProps)(PartnersList);
