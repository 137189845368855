import { Mutex } from 'async-mutex';
import axios from 'axios';

import { API_URL } from './api_helper';

export const createAxiosResponseInterceptorWithRefresh = (axiosApi) => {
  const mutex = new Mutex();

  let isRefreshing = false;
  let refreshPromise = null;

  function createAxiosResponseInterceptor() {
    const interceptor = axiosApi.interceptors.response.use(
      (response) => response,
      async (error) => {
        if (error.response.status !== 401) {
          return Promise.reject(error);
        }

        if (!isRefreshing) {
          isRefreshing = true;

          const release = await mutex.acquire();

          if (!refreshPromise) {
            refreshPromise = axios
              .post(`${API_URL}/auth/refresh`, null, {
                withCredentials: true,
              })
              .then(() => {
                isRefreshing = false;
                refreshPromise = null;
                createAxiosResponseInterceptor();
              })
              .catch((error2) => {
                isRefreshing = false;
                refreshPromise = null;

                if (error2.response && error2.response.status === 401) {
                  if (window.ReactNativeWebView) {
                    window.ReactNativeWebView.postMessage('logout');
                  }

                  window.location = '/login';
                  localStorage.removeItem('authUser');
                  sessionStorage.removeItem('authUser');
                } else {
                  return Promise.reject(error2);
                }
              })
              .finally(() => {
                isRefreshing = false;
                refreshPromise = null;
                release();
              });
          }

          await refreshPromise;
          axiosApi.interceptors.response.eject(interceptor);
          createAxiosResponseInterceptor();

          return axios(error.response.config);
        } else {
          await refreshPromise;

          return axios(error.response.config);
        }
      },
    );
  }

  createAxiosResponseInterceptor();
};
