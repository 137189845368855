export const partnerLevelToDisplayedName = {
  0: 'Начинающий',
  1: 'Партнёр',
  2: 'Консультант',
  3: 'Менеджер',
  4: 'Старший менеджер',
  5: 'Лидер',
  6: 'Директор',
  7: 'Gold - Директор',
  8: 'Platinum - Директор',
  9: 'Diamond - Директор',
};

// export const firstStructureLevels = {
//   0: 0,
//   500: 1,
//   1000: 2,
//   5000: 3,
//   10000: 4,
//   20000: 5,
//   50000: 6,
//   100000: 7,
//   200000: 8,
//   300000: 9,
// }
// export const allStructureLevels = {
//   0: 0,
//   1000: 1,
//   5000: 2,
//   25000: 3,
//   125000: 4,
//   625000: 5,
//   3000000: 6,
//   15000000: 7,
//   75000000: 8,
//   375000000: 9,
// }

export const firstStructureLevels = {
  0: 0,
  1: 500,
  2: 1000,
  3: 5000,
  4: 10000,
  5: 20000,
  6: 50000,
  7: 100000,
  8: 200000,
  9: 300000,
};
export const allStructureLevels = {
  0: 0,
  1: 1000,
  2: 5000,
  3: 25000,
  4: 125000,
  5: 625000,
  6: 3000000,
  7: 15000000,
  8: 75000000,
  9: 375000000,
};
