import cn from 'classnames';

import s from './Payment.module.scss';

const Skeleton = () => {
  return (
    <>
      <div className={cn(s.skeleton, s.description)}></div>
      <div className={cn(s.skeleton, s.qr)}></div>
      {/*<div className={cn(s.skeleton, s.hint)}></div>*/}
      <div className={cn(s.skeleton, s.address)}></div>
      <div className={cn(s.skeleton, s.timer)}></div>
    </>
  );
};

export default Skeleton;
