import { ReactComponent as AdminIcon } from './admin.svg';
import { ReactComponent as DashboardIcon } from './dashboard.svg';
import { ReactComponent as FinanceIcon } from './finance.svg';
import { ReactComponent as NotificationIcon } from './notification.svg';
import { ReactComponent as ProjectIcon } from './project.svg';
import { ReactComponent as PromotionIcon } from './promotion.svg';
import { ReactComponent as TeamIcon } from './team.svg';

const sidebarIcons = {
  admin: AdminIcon,
  dashboard: DashboardIcon,
  finance: FinanceIcon,
  notification: NotificationIcon,
  project: ProjectIcon,
  team: TeamIcon,
  promotion: PromotionIcon,
};

export default sidebarIcons;
