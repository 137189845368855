import * as yup from 'yup';

import { t } from '../../../../i18n';

export const createNotificationSchema = yup.object().shape({
  notificationTitle: yup
    .string()
    .matches(/^[^А-Яа-яЁё]+$/, t('notification_create_name_english_hint'))
    .required(t('notification_message_fill_hint')),
  fullMessage: yup
    .string()
    .matches(/^[^А-Яа-яЁё]+$/, t('notification_create_message_english_hint'))
    .required(t('notification_message_fill_hint')),
  notificationDate: yup.date().required(t('notification_date_fill_hint')),
  notificationType: yup.string().required(t('notification_type_fill_hint')),
  isSite: yup.boolean().test('atLeastOne', t('notification_delivery_type_error'), function (value) {
    return value || this.parent.isEmail;
  }),
  isEmail: yup
    .boolean()
    .test('atLeastOne', t('notification_delivery_type_error'), function (value) {
      return value || this.parent.isSite;
    }),
});
