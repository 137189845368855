import React, { useEffect, useState } from 'react';

import withForbiddenWithoutBuyingPackage from 'hocs/withForbiddenWithoutBuyingPackage';
import { Trans } from 'react-i18next';
import { MetaTags } from 'react-meta-tags';
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardBody, Container, Input, Label } from 'reactstrap';

import { fetchInvestments, fetchInvestmentsSummary } from 'store/investments/actions';

import DepositDetailsModal from './components/DepositDetailsModal/DepositDetailsModal';
import Table from 'components/AnotherTable/Table';
import Breadcrumbs from 'components/Common/Breadcrumb';
import EmptyState from 'components/EmptyState';
import PaginationComponent from 'components/PaginationComponent';

import useTranslation from '../../hooks/useTranslation';
import { columns } from './columns';

const Investments = () => {
  const t = useTranslation();
  const language = useSelector((state) => state.Layout.language);
  const currency = useSelector((state) => state.Currency.currency);
  const items = useSelector((state) => state.Investments.items);
  const investmentSummary = useSelector((state) => state.Investments.investmentSummary);
  const [isOpenId, setIsOpenId] = useState(false);
  const [showClosed, setShowClosed] = useState(false);
  const dispatch = useDispatch();
  const [pagination, setPagination] = useState({});
  const [sort, setSort] = useState({
    field: 'date',
    order: 'desc',
  });

  useEffect(() => {
    dispatch(fetchInvestments());
    dispatch(fetchInvestmentsSummary());
  }, [dispatch]);

  useEffect(() => {
    if (pagination?.pageNumber) {
      dispatch(
        fetchInvestments({
          take: pagination?.size,
          skip: (pagination?.pageNumber - 1) * pagination?.size,
          isClosed: showClosed,
          locale: 'en',
          sortField: sort.field,
          sortOrder: sort.order?.toUpperCase(),
        }),
      );
    }
  }, [dispatch, pagination, showClosed, sort]);

  return (
    <div className="page-content">
      <MetaTags>
        <title>{t('investments_meta_title')}</title>
      </MetaTags>
      <Container fluid className="mobile-no-padding">
        <div className="d-flex align-items-center justify-content-between investments-summary-header">
          <Breadcrumbs
            title="Metrics"
            hasBreadcrumbItem={false}
            breadcrumbItem={t('sidebar_investments_label')}
          />
          <div className="d-flex justify-content-center mobile-full-width">
            <div className="summary mobile-full-width">
              <div className="w-100">
                <div className="summary-item">
                  <Trans
                    i18nKey="investments_summary_total_amount"
                    components={{
                      text: <p className="no-wrap-text" />,
                      value: <p className="text-end no-wrap-text" />,
                    }}
                    values={{
                      amount: investmentSummary?.total_amount || '-',
                      currency,
                    }}
                  />
                </div>
                <div className="summary-item">
                  <Trans
                    i18nKey="investments_summary_total_payed"
                    components={{
                      text: <p className="no-wrap-text" />,
                      value: <p className="text-end no-wrap-text" />,
                    }}
                    values={{
                      amount: investmentSummary?.total_payed_amount || '-',
                      currency,
                    }}
                  />
                </div>
              </div>
              <div className="w-100">
                <div className="summary-item">
                  <Trans
                    i18nKey="investments_summary_current_amount"
                    components={{
                      text: <p className="no-wrap-text" />,
                      value: <p className="text-end no-wrap-text" />,
                    }}
                    values={{
                      amount: investmentSummary?.current_investment_amount || '-',
                      currency,
                    }}
                  />
                </div>
                <div className="summary-item">
                  <Trans
                    i18nKey="investments_summary_pay_ready_amount"
                    components={{
                      text: <p className="no-wrap-text" />,
                      value: <p className="text-end no-wrap-text" />,
                    }}
                    values={{
                      amount: investmentSummary?.available_to_withdraw_balance || '-',
                      currency,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Card>
          <CardBody className="investments-body">
            <div className="d-flex w-100 justify-content-end">
              <div style={{ marginLeft: 'auto' }}>
                <Label>
                  <Input
                    type="checkbox"
                    checked={showClosed}
                    onChange={(event) => setShowClosed(event.target.checked)}
                    className="me-1"
                  />
                  {t('investments_show_closed')}
                </Label>
              </div>
            </div>
            <Table
              keyField="id"
              columns={columns(t, setIsOpenId)}
              data={items?.items}
              custom={{
                classes: 'table__adaptive_bordered_between text-center table-without_padding',
              }}
              handleSort={setSort}
            />
            {items?.items?.length === 0 && <EmptyState />}
            <PaginationComponent
              active={pagination?.pageNumber}
              setPagination={setPagination}
              total={items?.total_pages_count}
            />
          </CardBody>
        </Card>
      </Container>
      <DepositDetailsModal isOpenId={isOpenId} setIsOpenId={setIsOpenId} />
    </div>
  );
};

export default withForbiddenWithoutBuyingPackage(Investments);
