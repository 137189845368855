import React, { useCallback, useEffect, useState } from 'react';

import { ReactComponent as SearchIcon } from 'assets/images/icons/search.svg';
import { ReactComponent as TreeIcon } from 'assets/images/icons/tree.svg';
import { t } from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import ReactFlow, { Controls, Panel, useEdgesState, useNodesState } from 'reactflow';
import 'reactflow/dist/style.css';
import { Input } from 'reactstrap';

import {
  clearUserReferrals,
  getUserReferrals,
  setDetailsInfo,
  setPartnersReferralsTree,
} from '../../../store/team/actions';
import CustomNodes from './CustomNodes';
import s from './Tree.module.scss';

const initBgColor = '#F1F5FA';

const connectionLineStyle = { stroke: '#fff' };

const nodeTypes = {
  customNode: CustomNodes,
};

const Tree = ({ treeWidth, referralTree, setSelectedNode: externalSetSelectedNode }) => {
  const [nodes, setNodes] = useNodesState([]);
  const [edges, setEdges] = useEdgesState([]);
  const [selectedNode, setSelectedNode] = useState(null);
  const [isSearchMode, setIsSearchMode] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [isSearchInvalid, setIsSearchInvalid] = useState(false);
  const fullUserTree = useSelector((state) => state.Team.userReferrals);
  const dispatch = useDispatch();

  const handleAddNodes = useCallback(() => {
    setNodes((nds) =>
      referralTree?.nodes.map((node, index) => {
        if (index === 0) {
          return {
            ...node,
            data: { ...node.data, type: 'target' },
          };
        } else if (node.id === selectedNode?.id) {
          return {
            ...node,
            data: { ...node.data, type: 'target' },
          };
        } else if (index <= referralTree?.nodes.length) {
          return {
            ...node,
            data: node.data,
          };
        } else {
          return node;
        }
      }),
    );
    setEdges(referralTree?.edges);
  }, [
    edges,
    nodes,
    referralTree?.edges,
    referralTree?.nodes,
    selectedNode?.id,
    setEdges,
    setNodes,
  ]);

  const handleSearch = useCallback(
    (e) => {
      setSearchValue(e.target.value);

      if (e.target.value?.length === 0) {
        dispatch(setDetailsInfo({}));
        dispatch(clearUserReferrals([]));
        dispatch(setPartnersReferralsTree());
      }
    },
    [dispatch],
  );

  const handleClick = useCallback(() => {
    setIsSearchMode((prevState) => {
      return !prevState;
    });

    if (isSearchMode) {
      dispatch(clearUserReferrals([]));
      setSearchValue('');
      if (!!fullUserTree?.length) {
        dispatch(setPartnersReferralsTree());
      }
    }
  }, [dispatch, fullUserTree, isSearchMode]);

  const handleNodeClick = (event, nodes) => {
    event.preventDefault();
    event.stopPropagation();

    setSelectedNode(nodes);
    externalSetSelectedNode(nodes);
  };

  useEffect(() => {
    handleAddNodes();
  }, [referralTree?.nodes]);

  useEffect(() => {
    if (searchValue?.length >= 6) {
      dispatch(getUserReferrals(searchValue));
    }
  }, [searchValue, dispatch]);

  useEffect(() => {
    if (fullUserTree?.length <= 1 && searchValue?.length >= 6) {
      setIsSearchInvalid(true);
    } else if (searchValue?.length === 0 || fullUserTree?.length > 1) {
      setIsSearchInvalid(false);
    }
  }, [fullUserTree, searchValue]);

  return (
    <div className={s.tree}>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        style={{ background: initBgColor }}
        nodeTypes={nodeTypes}
        connectionLineStyle={connectionLineStyle}
        defaultViewport={{ zoom: 0.8, x: treeWidth / 2 - 150, y: 150 }}
        nodesConnectable={false}
        nodesDraggable={false}
        nodesFocusable={false}
        attributionPosition={false}
        onNodeClick={handleNodeClick}>
        <Panel position="top-right">
          <button onClick={handleClick} className={s.treeButton}>
            {isSearchMode ? (
              <TreeIcon width="24" height="24" color="#115e5c" />
            ) : (
              <SearchIcon width="24" height="24" color="#115e5c" />
            )}
            {t(!isSearchMode ? 'search_partner_by_id' : 'to_referral_tree')}
          </button>
          {isSearchMode && (
            <>
              <Input
                placeholder={t('common_enter_partner_id')}
                onChange={handleSearch}
                invalid={isSearchInvalid}
                style={{ width: 200 }}
              />
              {isSearchInvalid && (
                <div className="auth-input-warning-hint" style={{ maxWidth: 200 }}>
                  {t('ref_tree_hint')}
                </div>
              )}
            </>
          )}
        </Panel>
        <Controls />
      </ReactFlow>
    </div>
  );
};

export default Tree;
