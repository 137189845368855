const countries = (t) => [
  {
    options: [
      { label: t('countries.RU'), value: 'RU' },
      { label: t('countries.KZ'), value: 'KZ' },
      { label: t('countries.AB'), value: 'AB' },
      { label: t('countries.AT'), value: 'AT' },
      { label: t('countries.AZ'), value: 'AZ' },
      { label: t('countries.AL'), value: 'AL' },
      { label: t('countries.AD'), value: 'AD' },
      { label: t('countries.AM'), value: 'AM' },
      { label: t('countries.AF'), value: 'AF' },
      { label: t('countries.BD'), value: 'BD' },
      { label: t('countries.BH'), value: 'BH' },
      { label: t('countries.BY'), value: 'BY' },
      { label: t('countries.BE'), value: 'BE' },
      { label: t('countries.BG'), value: 'BG' },
      { label: t('countries.BA'), value: 'BA' },
      { label: t('countries.BN'), value: 'BN' },
      { label: t('countries.BT'), value: 'BT' },
      { label: t('countries.HU'), value: 'HU' },
      { label: t('countries.VN'), value: 'VN' },
      { label: t('countries.DE'), value: 'DE' },
      { label: t('countries.GG'), value: 'GG' },
      { label: t('countries.GI'), value: 'GI' },
      { label: t('countries.HK'), value: 'HK' },
      { label: t('countries.GR'), value: 'GR' },
      { label: t('countries.GE'), value: 'GE' },
      { label: t('countries.DK'), value: 'DK' },
      { label: t('countries.JE'), value: 'JE' },
      { label: t('countries.IL'), value: 'IL' },
      { label: t('countries.IN'), value: 'IN' },
      { label: t('countries.ID'), value: 'ID' },
      { label: t('countries.JO'), value: 'JO' },
      { label: t('countries.IQ'), value: 'IQ' },
      { label: t('countries.IR'), value: 'IR' },
      { label: t('countries.IE'), value: 'IE' },
      { label: t('countries.IS'), value: 'IS' },
      { label: t('countries.ES'), value: 'ES' },
      { label: t('countries.IT'), value: 'IT' },
      { label: t('countries.YE'), value: 'YE' },
      { label: t('countries.KH'), value: 'KH' },
      { label: t('countries.QA'), value: 'QA' },
      { label: t('countries.CY'), value: 'CY' },
      { label: t('countries.KG'), value: 'KG' },
      { label: t('countries.CN'), value: 'CN' },
      { label: t('countries.KP'), value: 'KP' },
      { label: t('countries.KR'), value: 'KR' },
      { label: t('countries.KW'), value: 'KW' },
      { label: t('countries.LA'), value: 'LA' },
      { label: t('countries.LV'), value: 'LV' },
      { label: t('countries.LB'), value: 'LB' },
      { label: t('countries.LI'), value: 'LI' },
      { label: t('countries.LT'), value: 'LT' },
      { label: t('countries.LU'), value: 'LU' },
      { label: t('countries.MO'), value: 'MO' },
      { label: t('countries.MY'), value: 'MY' },
      { label: t('countries.MV'), value: 'MV' },
      { label: t('countries.MT'), value: 'MT' },
      { label: t('countries.MD'), value: 'MD' },
      { label: t('countries.MC'), value: 'MC' },
      { label: t('countries.MN'), value: 'MN' },
      { label: t('countries.MM'), value: 'MM' },
      { label: t('countries.NP'), value: 'NP' },
      { label: t('countries.NL'), value: 'NL' },
      { label: t('countries.NO'), value: 'NO' },
      { label: t('countries.AE'), value: 'AE' },
      { label: t('countries.OM'), value: 'OM' },
      { label: t('countries.IM'), value: 'IM' },
      { label: t('countries.PK'), value: 'PK' },
      { label: t('countries.PS'), value: 'PS' },
      { label: t('countries.VA'), value: 'VA' },
      { label: t('countries.PL'), value: 'PL' },
      { label: t('countries.PT'), value: 'PT' },
      { label: t('countries.MK'), value: 'MK' },
      { label: t('countries.RO'), value: 'RO' },
      { label: t('countries.SM'), value: 'SM' },
      { label: t('countries.SA'), value: 'SA' },
      { label: t('countries.RS'), value: 'RS' },
      { label: t('countries.SG'), value: 'SG' },
      { label: t('countries.SY'), value: 'SY' },
      { label: t('countries.SK'), value: 'SK' },
      { label: t('countries.SI'), value: 'SI' },
      { label: t('countries.GB'), value: 'GB' },
      { label: t('countries.TJ'), value: 'TJ' },
      { label: t('countries.TH'), value: 'TH' },
      { label: t('countries.TW'), value: 'TW' },
      { label: t('countries.TL'), value: 'TL' },
      { label: t('countries.TM'), value: 'TM' },
      { label: t('countries.TR'), value: 'TR' },
      { label: t('countries.UZ'), value: 'UZ' },
      { label: t('countries.UA'), value: 'UA' },
      { label: t('countries.FO'), value: 'FO' },
      { label: t('countries.PH'), value: 'PH' },
      { label: t('countries.FI'), value: 'FI' },
      { label: t('countries.FR'), value: 'FR' },
      { label: t('countries.HR'), value: 'HR' },
      { label: t('countries.ME'), value: 'ME' },
      { label: t('countries.CZ'), value: 'CZ' },
      { label: t('countries.CH'), value: 'CH' },
      { label: t('countries.SE'), value: 'SE' },
      { label: t('countries.SJ'), value: 'SJ' },
      { label: t('countries.LK'), value: 'LK' },
      { label: t('countries.AX'), value: 'AX' },
      { label: t('countries.EE'), value: 'EE' },
      { label: t('countries.OS'), value: 'OS' },
      { label: t('countries.JP'), value: 'JP' },
    ],
  },
];

export default countries;

export const countryName = (t) =>
  countries(t)[0].options.reduce((acc, curr) => {
    acc[curr.value] = curr.label;
    return acc;
  }, {});
