import React, { useEffect } from 'react';

import Logo from 'assets/images/logo-black.png';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import MetaTags from 'react-meta-tags';
//redux
import { useDispatch, useSelector } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Form, FormFeedback, Input, Label, Spinner } from 'reactstrap';
// Formik Validation
import * as Yup from 'yup';

import { t } from '../../i18n';
// action
import { userForgetPassword, userForgetPasswordSuccess } from '../../store/actions';
import s from './scss/AuthPages.module.scss';

// import images

const ForgetPasswordPage = (props) => {
  const dispatch = useDispatch();
  const history = props.history;

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().email(t('auth_reset_wrong_email_error')).required(t('common_email_hint')),
    }),
    onSubmit: (values) => {
      dispatch(userForgetPassword(values, props.history));
    },
  });

  const { forgetError, forgetSuccessMsg, forgetLoading } = useSelector((state) => ({
    forgetError: state.ForgetPassword.forgetError,
    forgetSuccessMsg: state.ForgetPassword.forgetSuccessMsg,
    forgetLoading: state.ForgetPassword.forgetLoading,
  }));

  useEffect(() => {
    if (forgetSuccessMsg) {
      validation.resetForm();
      history.push('/password-change');

      return () => {
        dispatch(userForgetPasswordSuccess(null));
      };
    }
  }, [forgetSuccessMsg]);

  return (
    <React.Fragment>
      <MetaTags>
        <title>{t('recover_password_meta_title')}</title>
      </MetaTags>
      <div className={s.wrapper}>
        <div className={s.formWrapper}>
          <img src={Logo} alt="logo" className={s.logo} />
          <div>
            <h4 className={s.title}>{t('auth_recover_password')}</h4>
            <p className={s.subtitle}>{t('auth_recover_password_email_placeholder')}</p>
          </div>

          <Form
            className={s.form}
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
            }}>
            {forgetError ? <div className="alert-warning">{`${t(forgetError)}`}</div> : null}
            <div>
              <Label className={s.formLabel}>{`${t('common_email')}`}</Label>
              <Input
                name="email"
                className="auth-input"
                placeholder={`${t('common_email_placeholder')}`}
                type="text"
                id="email"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.email || ''}
                invalid={!!(validation.touched.email && validation.errors.email)}
              />
              {validation.touched.email && validation.errors.email ? (
                <FormFeedback type="invalid" className="auth-input-warning-hint">
                  {validation.errors.email}
                </FormFeedback>
              ) : null}
            </div>

            <div className={s.actions}>
              <button className={s.submitButton} type="submit">
                {forgetLoading ? (
                  <div className="d-flex gap-2 justify-content-center align-items-center">
                    <Spinner className="spinner" /> {t('common_reset')}
                  </div>
                ) : (
                  t('common_reset')
                )}
              </button>
            </div>

            <p className={s.bottomText}>
              {t('auth_recover_password_remember_password')}
              <Link to="login" className={s.bottomTextLink}>
                {' '}
                {t('auth_register_login')}
              </Link>
            </p>
          </Form>
        </div>
      </div>
    </React.Fragment>
  );
};

ForgetPasswordPage.propTypes = {
  history: PropTypes.object,
};

export default withRouter(ForgetPasswordPage);
