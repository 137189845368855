import React, { useEffect, useState } from 'react';

import { t } from 'i18next';
import { useSelector } from 'react-redux';
import YouTube from 'react-youtube';
import { Container, Modal } from 'reactstrap';

import s from './InvestModal.module.scss';
import {
  ChooseInvestPackage,
  Error,
  ExpiredStep,
  ProductInfo,
  QrCodePayment,
  Success,
  WaitingConfirmation,
} from './components';
import { INVEST_MODAL_STEPS } from './utils';

const InvestModal = ({ isOpen, onClose }) => {
  const [activeStep, setActiveSteps] = useState(INVEST_MODAL_STEPS.QR);
  const [investPackageAmount, setInvestPackageAmount] = useState(0);
  const [isVideoOpen, setIsVideoOpen] = useState(false);
  const [isVideoLoading, setIsVideoLoading] = useState(true);
  const projectCategory = useSelector((state) => state.Project);
  const [transactionId, setTransactionId] = useState('');

  const handleClose = () => {
    if (projectCategory?.projects?.slug === 'investor') {
      setActiveSteps(INVEST_MODAL_STEPS.CHOSE_PACKAGE);
    } else {
      setActiveSteps(INVEST_MODAL_STEPS.QR);
    }
    onClose();
  };

  const handleVideoToggle = () => {
    setIsVideoOpen(!isVideoOpen);
    setIsVideoLoading(true);
  };

  const opts = {
    height: '390',
    width: '640',
    playerVars: {
      autoplay: 1,
    },
  };

  const content = {
    [INVEST_MODAL_STEPS.CHOSE_PACKAGE]: (
      <ChooseInvestPackage
        productDetail={projectCategory?.projectDetail}
        setActiveSteps={setActiveSteps}
        setInvestAmount={setInvestPackageAmount}
      />
    ),
    [INVEST_MODAL_STEPS.QR]: (
      <QrCodePayment
        setActiveSteps={setActiveSteps}
        setTransaction={setTransactionId}
        amount={investPackageAmount}
      />
    ),
    [INVEST_MODAL_STEPS.WAITING]: (
      <WaitingConfirmation transactionId={transactionId} setActiveSteps={setActiveSteps} />
    ),
    [INVEST_MODAL_STEPS.SUCCESS]: <Success />,
    [INVEST_MODAL_STEPS.ERROR]: <Error />,
    [INVEST_MODAL_STEPS.EXPIRED]: <ExpiredStep />,
  };

  useEffect(() => {
    if (isOpen && projectCategory?.projects?.slug === 'investor') {
      setActiveSteps(INVEST_MODAL_STEPS.CHOSE_PACKAGE);
    }

    return () => {
      if (projectCategory?.projects?.slug === 'investor') {
        setActiveSteps(INVEST_MODAL_STEPS.CHOSE_PACKAGE);
      } else {
        setActiveSteps(INVEST_MODAL_STEPS.QR);
      }
      setInvestPackageAmount(0);
    };
  }, [projectCategory?.projects?.slug, isOpen]);

  return (
    <>
      <Modal
        className="position-relative"
        isOpen={isOpen}
        toggle={handleClose}
        centered
        modalClassName={s.modalWrapper}
        contentClassName={s.modal}>
        <span
          onClick={handleClose}
          className={`position-absolute p-8 bg-white rounded-circle d-flex justify-content-center align-items-center ${s.closeButton}`}>
          x
        </span>
        <Container fluid>
          <div className={s.wrapper}>
            <div className={s.content}>
              <div>
                <ProductInfo
                  productDetail={projectCategory?.projectDetail}
                  amount={investPackageAmount}
                />
              </div>
              <div onClick={handleVideoToggle} className={s.videoInstruction}>
                {t('invest_video_instruction')}
              </div>
              <div className={s.payment}>{content[activeStep]}</div>
            </div>
          </div>
        </Container>
      </Modal>

      <Modal isOpen={isVideoOpen} toggle={handleVideoToggle} centered size="lg">
        <div className={s.videoModal}>
          <span
            onClick={handleVideoToggle}
            className={`position-absolute bg-white rounded-circle d-flex justify-content-center align-items-center ${s.closeButton}`}>
            x
          </span>
          {isVideoLoading && (
            <div className={s.spinnerContainer}>
              <span className={s.loader}></span>
            </div>
          )}
          <div className={s.video} style={{ display: isVideoLoading ? 'none' : 'block' }}>
            <YouTube
              videoId="w-d2f40mrZw"
              opts={{ ...opts, width: '100%', height: '100%' }}
              onReady={(event) => setIsVideoLoading(false)}
              onError={(event) => {
                setIsVideoLoading(false);
                console.error('Error loading video:', event);
              }}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default InvestModal;
