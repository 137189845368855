import moment from 'moment';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import {
  getInvestedByTypesAnalyticsRequest,
  getInvestedFundsAnalyticsDetailsRequest,
  getInvestedFundsAnalyticsRequest,
  getInvitedUsersAnalyticsRequest,
  getPartnersRequest,
  getUserPartners,
  getUserReferralsRequest,
} from '../../services/teamService';

import { get } from '../../helpers/api_helper';
import { apiFail, apiSuccess } from '../common/actions';
// Crypto Redux States
import {
  GET_INVESTED_BY_TYPES_ANALYTICS,
  GET_INVESTED_FUNDS_ANALYTICS,
  GET_INVESTED_FUNDS_ANALYTICS_DETAILED,
  GET_INVITED_USERS_ANALYTICS,
  GET_PARTNERS,
  GET_PARTNERS_INCOME,
  GET_PARTNERS_REFERRALS_TREE,
  GET_TEAM_INFO,
  GET_USER_REFERRALS,
  SET_PARTNERS_INCOME_RANGE,
} from './actionTypes';

function* getTeamInfo() {
  try {
    const response = yield get('/users/me/team');
    yield put(apiSuccess(GET_TEAM_INFO, response));
  } catch (error) {
    yield put(apiFail(GET_TEAM_INFO, error));
  }
}

export function* watchGetTeamInfo() {
  yield takeEvery(GET_TEAM_INFO, getTeamInfo);
}

function* getPartners({ payload }) {
  try {
    const response = yield call(getUserPartners, payload);
    yield put(apiSuccess(GET_PARTNERS, response));
  } catch (error) {
    yield put(apiFail(GET_PARTNERS, error));
  }
}

export function* watchGetPartners() {
  yield takeEvery(GET_PARTNERS, getPartners);
}

function* getPartnersIncome({ payload: { level, from, to } }) {
  try {
    yield put({ type: SET_PARTNERS_INCOME_RANGE, level, from, to });
    const f = (x) => moment(x).format('yyyy-MM-DD');
    const response = yield get(`/users/me/team/income?from=${f(from)}&to=${f(to)}&level=${level}`);
    yield put(apiSuccess(GET_PARTNERS_INCOME, response, { level }));
  } catch (error) {
    yield put(apiFail(GET_PARTNERS_INCOME, error));
  }
}

export function* watchGetPartnersIncome() {
  yield takeEvery(GET_PARTNERS_INCOME, getPartnersIncome);
}

function* getPartnersReferralsTree({ payload: { id } }) {
  try {
    const response = yield call(getPartnersRequest, id);
    yield put(apiSuccess(GET_PARTNERS_REFERRALS_TREE, response));
  } catch (error) {
    yield put(apiFail(GET_PARTNERS_REFERRALS_TREE, error));
  }
}

export function* watchGetPartnersReferralsTree() {
  yield takeEvery(GET_PARTNERS_REFERRALS_TREE, getPartnersReferralsTree);
}

function* getInvitedUsersAnalytics({ payload: { id, year } }) {
  try {
    const response = yield call(getInvitedUsersAnalyticsRequest, id, year);
    yield put(apiSuccess(GET_INVITED_USERS_ANALYTICS, response));
  } catch (error) {
    yield put(apiFail(GET_INVITED_USERS_ANALYTICS, error));
  }
}

export function* watchGetInvitedUsersAnalytics() {
  yield takeEvery(GET_INVITED_USERS_ANALYTICS, getInvitedUsersAnalytics);
}

function* getInvestedFundsAnalytics({ payload: { id, projects, firstDate, lastDate, groupBy } }) {
  try {
    const response = yield call(
      getInvestedFundsAnalyticsRequest,
      id,
      projects,
      firstDate,
      lastDate,
      groupBy,
    );
    yield put(apiSuccess(GET_INVESTED_FUNDS_ANALYTICS, response));
  } catch (error) {
    yield put(apiFail(GET_INVESTED_FUNDS_ANALYTICS, error));
  }
}

export function* watchGetInvestedFundsAnalytics() {
  yield takeEvery(GET_INVESTED_FUNDS_ANALYTICS, getInvestedFundsAnalytics);
}

function* getInvestedFundsAnalyticsDetailed({ payload: { id, firstDate, lastDate, projects } }) {
  try {
    const response = yield call(
      getInvestedFundsAnalyticsDetailsRequest,
      id,
      firstDate,
      lastDate,
      projects,
    );
    yield put(apiSuccess(GET_INVESTED_FUNDS_ANALYTICS_DETAILED, response));
  } catch (error) {
    yield put(apiFail(GET_INVESTED_FUNDS_ANALYTICS_DETAILED, error));
  }
}

export function* watchGetInvestedFundsAnalyticsDetailed() {
  yield takeEvery(GET_INVESTED_FUNDS_ANALYTICS_DETAILED, getInvestedFundsAnalyticsDetailed);
}

function* getInvestedByTypesAnalytics({ payload: { id, year } }) {
  try {
    const response = yield call(getInvestedByTypesAnalyticsRequest, id, year);
    yield put(apiSuccess(GET_INVESTED_BY_TYPES_ANALYTICS, response));
  } catch (error) {
    yield put(apiFail(GET_INVESTED_BY_TYPES_ANALYTICS, error));
  }
}

export function* watchGetInvestedByTypesAnalytics() {
  yield takeEvery(GET_INVESTED_BY_TYPES_ANALYTICS, getInvestedByTypesAnalytics);
}

function* getUserReferrals({ payload: { id } }) {
  try {
    const response = yield call(getUserReferralsRequest, id);
    yield put(apiSuccess(GET_USER_REFERRALS, response));
  } catch (error) {
    yield put(apiFail(GET_USER_REFERRALS, error));
  }
}

export function* watchGetUserReferrals() {
  yield takeEvery(GET_USER_REFERRALS, getUserReferrals);
}

function* teamSaga() {
  yield all([
    fork(watchGetTeamInfo),
    fork(watchGetPartners),
    fork(watchGetPartnersIncome),
    fork(watchGetPartnersReferralsTree),
    fork(watchGetInvitedUsersAnalytics),
    fork(watchGetInvestedFundsAnalytics),
    fork(watchGetInvestedFundsAnalyticsDetailed),
    fork(watchGetInvestedByTypesAnalytics),
    fork(watchGetUserReferrals),
  ]);
}

export default teamSaga;
