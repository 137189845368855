import React from 'react';

import i18next from 'i18next';

import s from './AccrualsGraph.module.scss';

const CustomLegend = (props) => {
  const { payload } = props;
  return (
    <ul className={s.legendWrapper}>
      {payload.map((entry, index) => (
        <li key={`item-${index}`} className={s.legend} style={{ color: entry.color }}>
          <div
            style={{
              width: 12,
              height: 12,
              borderRadius: '50%',
              background: entry.color,
            }}></div>{' '}
          {i18next.t(entry.value)}
        </li>
      ))}
    </ul>
  );
};

export default CustomLegend;
