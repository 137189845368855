import SuperPromoImage from 'assets/images/super-promo-image.webp';
import cn from 'classnames';
import { t } from 'i18next';
import { Trans } from 'react-i18next';

import s from './PromotionComponents.module.scss';

const RulesPage = () => {
  return (
    <div className={s.rulesContainer}>
      <h2 className={s.rulesTitle}>{t('promotion_trip_text')}</h2>
      <div className={s.rulesText}>
        <Trans i18nKey={'promotion_trip_first_description'} components={{ break: <br /> }} />
        <br />
        <br />
        <Trans i18nKey={'promotion_trip_second_description'} components={{ break: <br /> }} />
        <br />
        <br />
        <Trans
          i18nKey={'promotion_trip_third_description'}
          components={{ break: <br />, bold: <b /> }}
        />
        <br />
        <br />
        <Trans
          i18nKey={'promotion_trip_fourth_description'}
          components={{ break: <br />, bold: <b /> }}
        />
        <br />
        <br />
        <Trans
          i18nKey={'promotion_trip_fifth_description'}
          components={{ break: <br />, bold: <b /> }}
        />
      </div>
      <div className={s.rulesHint}>
        <h4 className={s.rulesHintTile}>{t('promotion_participation_title')}</h4>
        <div className={s.rulesHintText}>{t('promotion_participation_description')}</div>
      </div>
      <div className={s.rulesDescription}>
        <div>
          <h4 className={s.rulesDescriptionTitle}>
            <span>1</span>
            {t('promotion_trip_terms_first_rule_title')}
          </h4>
          <div className={s.rulesDescriptionTable}>
            <div className={cn(s.rulesDescriptionTableItem)}>
              <div className={s.headerLabel}>{t('promotion_trip_rules_partner_level')}</div>
              <div className={cn(s.headerLabel, s.grey, 'text-end')}>
                {t('promotion_attracted_personal_funds')}
              </div>
            </div>
            <div className={s.rulesDescriptionItem}>
              <div className={s.rulesLevelItem}>
                <span className={s.count}>1-3</span> {t('promotion_levels')}
              </div>
              <div className={cn(s.userAmount, s.lg)}>200 000 $</div>
            </div>
            <div className={s.rulesDescriptionItem}>
              <div className={s.rulesLevelItem}>
                <span className={s.count}>4</span> {t('promotion_levels')}
              </div>
              <div className={cn(s.userAmount, s.lg)}>300 000 $</div>
            </div>
            <div className={s.rulesDescriptionItem}>
              <div className={s.rulesLevelItem}>
                <span className={s.count}>5</span> {t('promotion_levels')}
              </div>
              <div className={cn(s.userAmount, s.lg)}>400 000 $</div>
            </div>
            <div className={s.rulesDescriptionItem}>
              <div className={s.rulesLevelItem}>
                <span className={s.count}>6</span> {t('promotion_levels')}
              </div>
              <div className={cn(s.userAmount, s.lg)}>600 000 $</div>
            </div>
          </div>
        </div>

        <div>
          <h4 className={s.rulesDescriptionTitle}>
            <span>2</span>
            {t('promotion_trip_terms_second_rule_title')}
          </h4>
          <div className={s.rulesDescriptionTable}>
            <div className={s.rulesGrid}>
              <div className={s.headerLabel}>{t('promotion_trip_rules_partner_level')}</div>
              <div className={cn(s.headerLabel, s.grey, s.selfEnd)}>
                {t('promotion_attracted_structural_funds')}
              </div>
              <div className={cn(s.headerLabel, s.grey, 'text-end', s.selfEnd)}>
                {t('promotion_cof')}
              </div>
            </div>
            <div className={cn(s.rulesDescriptionItem, s.rulesGrid)}>
              <div className={s.rulesLevelItem}>
                <span className={s.count}>1-3</span> {t('promotion_levels')}
              </div>

              <div className={cn(s.userAmount, s.lg, s.selfCenter)}>40 000 $</div>
              <span className="text-end">4</span>
            </div>
            <div className={cn(s.rulesDescriptionItem, s.rulesGrid)}>
              <div className={s.rulesLevelItem}>
                <span className={s.count}>4</span> {t('promotion_levels')}
              </div>

              <div className={cn(s.userAmount, s.lg, s.selfCenter)}>60 000 $</div>
              <span className="text-end">6</span>
            </div>
            <div className={cn(s.rulesDescriptionItem, s.rulesGrid)}>
              <div className={s.rulesLevelItem}>
                <span className={s.count}>5</span> {t('promotion_levels')}
              </div>

              <div className={cn(s.userAmount, s.lg, s.selfCenter)}>80 000 $</div>
              <span className="text-end">8</span>
            </div>
            <div className={cn(s.rulesDescriptionItem, s.rulesGrid)}>
              <div className={s.rulesLevelItem}>
                <span className={s.count}>6</span> {t('promotion_levels')}
              </div>

              <div className={cn(s.userAmount, s.lg, s.selfCenter)}>120 000 $</div>
              <span className="text-end">12</span>
            </div>
          </div>

          <div className={s.tableDescription}>
            {t('promotion_trip_terms_second_rule_description')}
          </div>
        </div>

        <div>
          <h4 className={s.rulesDescriptionTitle}>{t('promotion_example_title')}</h4>

          <div className={s.exampleItem}>
            <span className={s.count}>1</span>
            {t('promotion_example_description_first_point')}
          </div>
          <div className={s.exampleItem}>
            <span className={s.count}>2</span>
            {t('promotion_example_description_second_point')}
          </div>
          <div className={s.exampleItem}>
            <span className={s.count}>3</span>
            {t('promotion_example_description_third_point')}
          </div>
          <div className={s.exampleItem}>
            <span className={s.count}>4</span>
            {t('promotion_example_description_fourth_point')}
          </div>
        </div>

        <div>
          <h4 className={s.rulesDescriptionTitle}>{t('promotion_additional_conditions')}</h4>

          <ul style={{ margin: 0 }}>
            <li>
              <div className={cn(s.exampleItem, s.borderLess)}>
                {t('promotion_trip_terms_two_people')}
              </div>
            </li>
            <li>
              <div className={cn(s.exampleItem, s.borderLess)}>
                {t('promotion_trip_terms_partner_level')}
              </div>
            </li>
            <li>
              <div className={cn(s.exampleItem, s.borderLess)}>
                {t('promotion_trip_terms_any_people')}
              </div>
            </li>
            <li>
              <div className={cn(s.exampleItem, s.borderLess)}>
                {t('promotion_trip_terms_cash_equivalent')}
              </div>
            </li>
            <li>
              <div className={cn(s.exampleItem, s.borderLess)}>
                {t('promotion_trip_terms_big_level')}
              </div>
            </li>
            <li>
              <div className={cn(s.exampleItem, s.borderLess)}>
                {t('promotion_trip_terms_not_participants')}
              </div>
            </li>
            <li>
              <div className={cn(s.exampleItem, s.borderLess)}>
                {t('promotion_trip_terms_company_management')}
              </div>
            </li>
            <li>
              <div className={cn(s.exampleItem, s.borderLess)}>
                {t('promotion_trip_terms_voucher_transfer')}
              </div>
            </li>
          </ul>

          <div className={cn(s.exampleItem, s.borderLess)}>{t('promotion_table_info')}</div>
          <h4 className={s.rulesDescriptionTitle}>{t('promotion_trip_good_luck_all')}</h4>
        </div>
      </div>

      <h2 className={s.superPromoTitle}>{t('super_promo_title')}</h2>
      <div className={s.superPromoDesc}>
        <h4 className={s.rulesDescriptionTitle}>{t('super_promo_opportunity')}</h4>

        <ul style={{ margin: 0 }}>
          <li>
            <div className={cn(s.exampleItem, s.borderLess)}>
              <Trans i18nKey={'super_promo_first_description'} components={{ break: <br /> }} />
            </div>
          </li>
          <li>
            <div className={cn(s.exampleItem, s.borderLess)}>
              <Trans i18nKey={'super_promo_second_description'} components={{ break: <br /> }} />
            </div>
          </li>
          <li>
            <div className={cn(s.exampleItem, s.borderLess)}>
              <Trans i18nKey={'super_promo_third_description'} components={{ break: <br /> }} />
            </div>
          </li>
        </ul>
      </div>
      <div className={s.rulesHint}>
        <h4 className={s.rulesHintTile}>{t('promotion_participation_title')}</h4>
        <div className={s.rulesHintText}>{t('super_promo_participation_description')}</div>
      </div>
      <div className={s.rulesDescription}>
        <div>
          <h4 className={s.rulesDescriptionTitle}>
            <span>1</span>
            {t('promotion_trip_terms_first_rule_title')}
          </h4>

          <div className={s.rulesDescriptionTable}>
            <div className={s.rulesGrid}>
              <div className={s.headerLabel}>{t('promotion_trip_rules_partner_level')}</div>
              <div className={cn(s.headerLabel, s.grey, 'text-end')}>
                {t('promotion_attracted_personal_funds')}
              </div>
              <div className={cn(s.headerLabel, s.grey, 'text-end', s.selfEnd)}>
                {t('promotion_cof')}
              </div>
            </div>
            <div className={cn(s.rulesDescriptionItem, s.rulesGrid)}>
              <div className={s.rulesLevelItem}>
                <span className={s.count}>4</span> {t('promotion_levels')}
              </div>

              <div className={cn(s.userAmount, s.lg, s.selfCenter)}>600 000 $</div>
              <span className="text-end">6</span>
            </div>
            <div className={cn(s.rulesDescriptionItem, s.rulesGrid)}>
              <div className={s.rulesLevelItem}>
                <span className={s.count}>5</span> {t('promotion_levels')}
              </div>

              <div className={cn(s.userAmount, s.lg, s.selfCenter)}>800 000 $</div>
              <span className="text-end">8</span>
            </div>
            <div className={cn(s.rulesDescriptionItem, s.rulesGrid)}>
              <div className={s.rulesLevelItem}>
                <span className={s.count}>6</span> {t('promotion_levels')}
              </div>

              <div className={cn(s.userAmount, s.lg, s.selfCenter)}>1 200 000 $</div>
              <span className="text-end">12</span>
            </div>
            <div className={cn(s.rulesDescriptionItem, s.rulesGrid)}>
              <div className={s.rulesLevelItem}>
                <span className={s.count}>7</span> {t('promotion_levels')}
              </div>

              <div className={cn(s.userAmount, s.lg, s.selfCenter)}>2 400 000 $</div>
              <span className="text-end">24</span>
            </div>
            <div className={cn(s.rulesDescriptionItem, s.rulesGrid)}>
              <div className={s.rulesLevelItem}>
                <span className={s.count}>8</span> {t('promotion_levels')}
              </div>

              <div className={cn(s.userAmount, s.lg, s.selfCenter)}>6 000 000 $</div>
              <span className="text-end">60</span>
            </div>
            <div className={cn(s.rulesDescriptionItem, s.rulesGrid)}>
              <div className={s.rulesLevelItem}>
                <span className={s.count}>9</span> {t('promotion_levels')}
              </div>

              <div className={cn(s.userAmount, s.lg, s.selfCenter)}>18 000 000 $</div>
              <span className="text-end">180</span>
            </div>
            <div className={cn(s.rulesDescriptionItem, s.rulesGrid)}>
              <div className={s.rulesLevelItem}>
                <span className={s.count}>10</span> {t('promotion_levels')}
              </div>

              <div className={cn(s.userAmount, s.lg, s.selfCenter)}>54 000 000 $</div>
              <span className="text-end">540</span>
            </div>
          </div>
        </div>

        <div>
          <h4 className={s.rulesDescriptionTitle}>
            <span>2</span>
            {t('promotion_trip_terms_second_rule_title')}
          </h4>
          <div className={s.rulesDescriptionTable}>
            <div className={s.rulesGrid}>
              <div className={s.headerLabel}>{t('promotion_trip_rules_partner_level')}</div>
              <div className={cn(s.headerLabel, s.grey, s.selfEnd)}>
                {t('promotion_attracted_structural_funds')}
              </div>
              <div className={cn(s.headerLabel, s.grey, 'text-end', s.selfEnd)}>
                {t('promotion_cof')}
              </div>
            </div>
            <div className={cn(s.rulesDescriptionItem, s.rulesGrid)}>
              <div className={s.rulesLevelItem}>
                <span className={s.count}>4</span> {t('promotion_levels')}
              </div>

              <div className={cn(s.userAmount, s.lg, s.selfCenter)}>120 000 $</div>
              <span className="text-end">6</span>
            </div>
            <div className={cn(s.rulesDescriptionItem, s.rulesGrid)}>
              <div className={s.rulesLevelItem}>
                <span className={s.count}>5</span> {t('promotion_levels')}
              </div>

              <div className={cn(s.userAmount, s.lg, s.selfCenter)}>160 000 $</div>
              <span className="text-end">8</span>
            </div>
            <div className={cn(s.rulesDescriptionItem, s.rulesGrid)}>
              <div className={s.rulesLevelItem}>
                <span className={s.count}>6</span> {t('promotion_levels')}
              </div>

              <div className={cn(s.userAmount, s.lg, s.selfCenter)}>240 000 $</div>
              <span className="text-end">12</span>
            </div>
            <div className={cn(s.rulesDescriptionItem, s.rulesGrid)}>
              <div className={s.rulesLevelItem}>
                <span className={s.count}>7</span> {t('promotion_levels')}
              </div>

              <div className={cn(s.userAmount, s.lg, s.selfCenter)}>480 000 $</div>
              <span className="text-end">24</span>
            </div>
            <div className={cn(s.rulesDescriptionItem, s.rulesGrid)}>
              <div className={s.rulesLevelItem}>
                <span className={s.count}>8</span> {t('promotion_levels')}
              </div>

              <div className={cn(s.userAmount, s.lg, s.selfCenter)}>1 200 000 $</div>
              <span className="text-end">60</span>
            </div>
            <div className={cn(s.rulesDescriptionItem, s.rulesGrid)}>
              <div className={s.rulesLevelItem}>
                <span className={s.count}>9</span> {t('promotion_levels')}
              </div>

              <div className={cn(s.userAmount, s.lg, s.selfCenter)}>3 600 000 $</div>
              <span className="text-end">180</span>
            </div>
            <div className={cn(s.rulesDescriptionItem, s.rulesGrid)}>
              <div className={s.rulesLevelItem}>
                <span className={s.count}>10</span> {t('promotion_levels')}
              </div>

              <div className={cn(s.userAmount, s.lg, s.selfCenter)}>10 800 000 $</div>
              <span className="text-end">540</span>
            </div>
          </div>

          <div className={s.tableDescription}>
            {t('promotion_trip_terms_second_rule_description')}
          </div>
          <div className={s.tableDescription}>{t('super_promo_hint')}</div>
        </div>

        <div>
          <h4 className={s.rulesDescriptionTitle}>{t('promotion_example_title')}</h4>

          <div className={s.exampleItem}>
            <span className={s.count}>1</span>
            {t('super_promo_example_description_first_point')}
          </div>
          <div className={s.exampleItem}>
            <span className={s.count}>2</span>
            {t('super_promo_example_description_second_point')}
          </div>
          <div className={s.exampleItem}>
            <span className={s.count}>3</span>
            {t('super_promo_example_description_third_point')}
          </div>
          <div className={s.exampleItem}>
            <span className={s.count}>4</span>
            {t('promotion_example_description_fourth_point')}
          </div>

          <div className={s.tableDescription}>{t('super_promo_example')}</div>
        </div>

        <div>
          <h4 className={s.rulesDescriptionTitle}>{t('promotion_additional_conditions')}</h4>

          <ul style={{ margin: 0 }}>
            <li>
              <div className={cn(s.exampleItem, s.borderLess)}>
                {t('promotion_trip_terms_two_people')}
              </div>
            </li>
            <li>
              <div className={cn(s.exampleItem, s.borderLess)}>
                {t('promotion_trip_terms_partner_level')}
              </div>
            </li>
            <li>
              <div className={cn(s.exampleItem, s.borderLess)}>
                {t('promotion_trip_terms_any_people')}
              </div>
            </li>
            <li>
              <div className={cn(s.exampleItem, s.borderLess)}>{t('super_promo_winner')}</div>
            </li>
            <li>
              <div className={cn(s.exampleItem, s.borderLess)}>{t('super_promo_first_promo')}</div>
            </li>
            <li>
              <div className={cn(s.exampleItem, s.borderLess)}>
                {t('super_promo_regular_promo_trip')}
              </div>
            </li>
            <li>
              <div className={cn(s.exampleItem, s.borderLess)}>
                {t('promotion_trip_terms_not_participants')}
              </div>
            </li>
            <li>
              <div className={cn(s.exampleItem, s.borderLess)}>
                {t('promotion_trip_terms_company_management')}
              </div>
            </li>
            {/*<li>*/}
            {/*  <div className={cn(s.exampleItem, s.borderLess)}>{t('super_promo_dont_sell')}</div>*/}
            {/*</li>*/}
          </ul>

          <div className={cn(s.exampleItem, s.borderLess)}>{t('promotion_table_info')}</div>
          <h4 className={s.rulesDescriptionTitle}>{t('promotion_trip_good_luck_all')}</h4>
        </div>
      </div>
    </div>
  );
};

export default RulesPage;
