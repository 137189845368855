import React, { forwardRef, useEffect, useState } from 'react';

import { ReactComponent as Arrow } from 'assets/images/icons/arrow.svg';
import { ReactComponent as DoubleArrow } from 'assets/images/icons/duble-arrow.svg';
import moment from 'moment';
import ExternalDatePicker from 'react-datepicker';
import { Input } from 'reactstrap';

import { subtractMonths } from '../../helpers/subtractMonths';

function isValidDate(dateString) {
  const dateParts = dateString.split('-');
  const day = dateParts[0];
  const month = dateParts[1];
  const year = dateParts[2];

  return year?.length === 4 && month?.length === 2 && day?.length === 2;
}

const DatePicker = ({
  setFilterDate,
  initialDate = [subtractMonths(new Date(), 1), new Date()],
}) => {
  const [dateRange, setDateRange] = useState(initialDate);
  const [startDate, endDate] = dateRange;
  const [isPickerDirty, setIsPickerDirty] = useState(false);

  useEffect(() => {
    if (isPickerDirty && startDate && endDate && setFilterDate) {
      setFilterDate((prevState) => ({ ...prevState, startDate, endDate }));
    }
  }, [startDate, endDate, setFilterDate, isPickerDirty]);

  const ExampleCustomInput = forwardRef((props, ref) => {
    const onChangeHandler = (e) => {
      const dateValue = e?.target?.value.split(' - ');
      const startDate = dateValue[0];
      const endDate = dateValue[1];

      if (isValidDate(startDate) && isValidDate(endDate)) {
        setDateRange([
          new Date(moment(startDate, 'DD-MM-YYYY').format('YYYY/MM/DD')),
          new Date(moment(endDate, 'DD-MM-YYYY').format('YYYY/MM/DD')),
        ]);
      }

      props.onChange(e);
    };

    return (
      <Input onClick={props?.onClick} ref={ref} value={props?.value} onChange={onChangeHandler} />
    );
  });

  return (
    <ExternalDatePicker
      selectsRange={true}
      startDate={startDate}
      endDate={endDate}
      onChange={(update) => {
        setDateRange(update);
        setIsPickerDirty(true);
      }}
      className="picker-date"
      dateFormat="dd-MM-yyyy"
      customInput={<ExampleCustomInput />}
      renderCustomHeader={({ date, decreaseYear, increaseYear, decreaseMonth, increaseMonth }) => {
        return (
          <div className="datepicker-wrapper">
            <div className="actions">
              <button onClick={decreaseYear} className="datepicker-button">
                <DoubleArrow className="arrow" size={16} />
              </button>
              <button onClick={decreaseMonth} className="datepicker-button">
                <Arrow className="arrow" />
              </button>
            </div>
            <div className="datepicker-header-date">{moment(date).format('MMMM YYYY')}</div>
            <div className="actions">
              <button onClick={increaseMonth} className="datepicker-button">
                <Arrow className="arrow" style={{ transform: 'rotate(180deg)' }} />
              </button>
              <button onClick={increaseYear} className="datepicker-button">
                <DoubleArrow className="arrow" style={{ transform: 'rotate(180deg)' }} />
              </button>
            </div>
          </div>
        );
      }}
      showNextYearButton={true}
    />
  );
};

export default DatePicker;
