import {
  SEND_CONFIRM_CODE,
  SEND_CONFIRM_CODE_ERROR,
  SEND_CONFIRM_CODE_SUCCESSFUL,
} from './actionTypes';

const INIT_STATE = {
  confirmedSuccess: false,
  confirmedError: null,
  confirmedLoading: false,
};

const Users = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SEND_CONFIRM_CODE:
      return {
        ...state,
        confirmedLoading: true,
        confirmedError: null,
        confirmedSuccess: false,
      };
    case SEND_CONFIRM_CODE_SUCCESSFUL:
      return {
        ...state,
        confirmedSuccess: action.payload,
        confirmedLoading: false,
        confirmedError: null,
      };
    case SEND_CONFIRM_CODE_ERROR:
      return {
        ...state,
        confirmedSuccess: false,
        confirmedLoading: false,
        confirmedError: action.payload,
      };
    default:
      return state;
  }
};

export default Users;
