import React from 'react';

import PropTypes from 'prop-types';

const CountryFlag = ({ title, emoji = true, countryCode, ...props }) => {
  return (
    <div className="d-flex align-items-center">
      {/*<ReactCountryFlag*/}
      {/*  className={emoji ? 'emojiFlag' : ''}*/}
      {/*  {...props}*/}
      {/*  svg={emoji}*/}
      {/*  style={{*/}
      {/*    width: '2em',*/}
      {/*    height: '1em',*/}
      {/*  }}*/}
      {/*/>*/}
      <span className={`fi fi-${countryCode?.toLowerCase()}`}></span>
      &nbsp;
      {title}
    </div>
  );
};

CountryFlag.propTypes = {
  title: PropTypes.string,
  emoji: PropTypes.bool,
};

export default CountryFlag;
