import React, { FC } from 'react';

import { ReactComponent as SimpleArrowRight } from 'assets/images/icons/simpleArrowRight.svg';
import cn from 'classnames';
import { Link } from 'react-router-dom';

import s from './Breadcrumbs.module.scss';

const LinksBreadcrumbs = (props) => {
  const { divider = <SimpleArrowRight />, routes = [], className = '' } = props;

  const breadcrumbRenderer = (breadcrumb, index) => {
    const key = `breadcrumb-${breadcrumb.name}-${index}`;
    const dividerElem = index !== 0 && divider;
    return !breadcrumb.path ? (
      <div className={cn(s.currentLocation, s.breadcrumbWrapper)} key={key}>
        {dividerElem}
        <p className={s.breadcrumbsLabel}>{breadcrumb.name}</p>
      </div>
    ) : (
      <div className={s.breadcrumbWrapper} key={key}>
        {dividerElem}
        <Link to={breadcrumb.path}>{breadcrumb.name}</Link>
      </div>
    );
  };

  return (
    <div className={cn(s.wrapper, { [className]: className })}>
      <>{routes.map(breadcrumbRenderer)}</>
    </div>
  );
};

export default LinksBreadcrumbs;
