import React, { forwardRef } from 'react';

import DatePicker from 'react-datepicker';

import s from './YearDatePicker.module.scss';

const CustomDatePicker = forwardRef(({ value, onClick }, ref) => (
  <button className={s.customDatePicker} onClick={onClick} ref={ref}>
    {value}
  </button>
));
const YearDatePicker = ({ activeYear, onChange }) => {
  return (
    <>
      <DatePicker
        showYearPicker
        dateFormat="yyyy"
        selected={activeYear}
        onChange={onChange}
        customInput={<CustomDatePicker />}
      />
    </>
  );
};

export default YearDatePicker;
