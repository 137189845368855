import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { connect, useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router, Switch } from 'react-router-dom';

import { getAccessSelector, getRoleSelector } from './store/auth/profile/selectors';

import NonAuthLayout from './components/NonAuthLayout';
// layouts Format
import VerticalLayout from './components/VerticalLayout';
import Modals from 'components/Modals/Modals';

// Import scss
import './assets/scss/theme.scss';
import Preloader from './pages/Authentication/Preloader';
// Import Routes all
import { authProtectedRoutes, publicRoutes } from './routes';
// Import all middleware
import Authmiddleware from './routes/route';
import { setIsDesktop, setIsDesktopLoading } from './store/layout/actions';

const App = () => {
  const dispatch = useDispatch();
  const access = useSelector(getAccessSelector);
  const role = useSelector(getRoleSelector);
  const { isDesktop, isDesktopLoading } = useSelector((state) => {
    return {
      isDesktop: state.Layout.isDesktop,
      isDesktopLoading: state.Layout.isDesktopLoading,
    };
  });
  const isAdmin = access?.includes('notifications') && role?.name === 'admin';
  const getLayout = () => VerticalLayout;

  const Layout = getLayout();

  useEffect(() => {
    if (window.electronEnvironment && window.electronEnvironment.isElectron) {
      dispatch(setIsDesktop(true));
      if (!localStorage.getItem('authUser') && !sessionStorage.getItem('authUser')) {
        dispatch(setIsDesktopLoading(true));
      }
    }
  }, [dispatch]);

  if (isDesktop && isDesktopLoading) {
    return <Preloader />;
  }

  return (
    <React.Fragment>
      <Router>
        <Switch>
          {publicRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={NonAuthLayout}
              component={route.component}
              key={idx}
              isAuthProtected={false}
              exact
            />
          ))}

          {authProtectedRoutes(isAdmin).map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={Layout}
              component={route.component}
              key={idx}
              isAuthProtected={true}
              exact
            />
          ))}
        </Switch>
        <Modals />
      </Router>
    </React.Fragment>
  );
};

App.propTypes = {
  layout: PropTypes.any,
};

const mapStateToProps = (state) => {
  return {
    layout: state.Layout,
  };
};

export default connect(mapStateToProps, null)(App);
