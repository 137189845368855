import React, { useEffect, useState } from 'react';

import { ReactComponent as ArrowIcon } from 'assets/images/icons/duble-arrow.svg';
import PromotionIcon from 'assets/images/promotion-icon.webp';
import cn from 'classnames';
import { getDisplayedUserLevel } from 'helpers/Utils/user';
import withForbiddenWithoutBuyingPackage from 'hocs/withForbiddenWithoutBuyingPackage';
import { MetaTags } from 'react-meta-tags';
import { useSelector } from 'react-redux';

import { t } from '../../i18n';
import s from './Promotion.module.scss';
import { RulesPage, StatsPage } from './components';
import { usePromotion } from './usePromotion';

function Promotion() {
  const { promotionData, fetchPromotionData } = usePromotion();
  const [isRulesShow, setIsRulesShow] = useState(false);
  const [showGoTop, setShowGoTop] = useState(false);
  const [pagination, setPagination] = useState({
    take: 1000,
    skip: 0,
  });

  const language = useSelector((state) => state.Layout.language);

  const displayedUserLevel = getDisplayedUserLevel(promotionData?.promotion_level);

  const handleVisibleButton = () => {
    const position = window.pageYOffset;

    if (position > 100) {
      return setShowGoTop(true);
    } else if (position < 100) {
      return setShowGoTop(false);
    }
  };

  const handleScrollUp = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleVisibleButton);

    return () => window.removeEventListener('scroll', handleVisibleButton);
  });

  useEffect(() => {
    fetchPromotionData(pagination.take, pagination.skip);
  }, [pagination.skip, pagination.take]);

  return (
    <>
      <MetaTags>
        <title>{t('promotion_meta_title')}</title>
      </MetaTags>
      <div className={s.container}>
        <div className={s.wrapper}>
          <div className={cn(s.promotionHeader, { [s.promotionHeaderExpanded]: isRulesShow })}>
            {isRulesShow && (
              <button
                className={cn(s.promotionRules, s.backBtn)}
                onClick={() => setIsRulesShow(false)}>
                {t('common_back')}
              </button>
            )}
            <div
              className={cn(s.promotionTitle, { [s.promotionTitleExpanded]: language !== 'ru' })}>
              <img
                src={PromotionIcon}
                className={cn(s.promotionIcon, { [s.promotionIconExpanded]: language !== 'ru' })}
              />
              <span>{t('promotion_title')}</span>
            </div>
            <div className={s.promotionSubtitle}>{t('promotion_subtitle')}</div>
            <button className={s.promotionRules} onClick={() => setIsRulesShow(!isRulesShow)}>
              {t('promotion_rules_text')}
            </button>
          </div>

          {isRulesShow ? (
            <RulesPage />
          ) : (
            <StatsPage
              level={displayedUserLevel}
              firstGoal={promotionData?.first_condition_goal}
              secondGoal={promotionData?.second_condition_goal}
              secondRating={promotionData?.second_condition_rating}
              firstPromoRating={promotionData?.promo_users_rating}
              firstConditionCompleted={promotionData?.first_condition_completed}
              secondConditionCompleted={promotionData?.second_condition_completed}
              superPromoRating={promotionData?.super_promo_users_rating}
            />
          )}
        </div>

        {isRulesShow && showGoTop && (
          <button className={s.backToTopBtn} onClick={handleScrollUp}>
            <span className={s.backToTopBtnArrow}>
              <ArrowIcon />
            </span>
          </button>
        )}
      </div>
    </>
  );
}

export default withForbiddenWithoutBuyingPackage(Promotion);
