import React, { useEffect } from 'react';

import Logo from 'assets/images/logo-black.png';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { loginUser } from '../../store/auth/login/actions';
import { setIsDesktopLoading } from '../../store/layout/actions';

const Preloader = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { error, loading, isAuth } = useSelector((state) => ({
    error: state.Login.error,
    loading: state.Login.loading,
    isAuth: state.Login.isAuth,
  }));

  useEffect(() => {
    window.api.requestCredentials();

    window.api.onCredentials((credentials) => {
      if (credentials?.username && credentials?.password) {
        dispatch(
          loginUser({ identifier: credentials.username, password: credentials.password }, history),
        );
      } else {
        dispatch(setIsDesktopLoading(false));
        history.push('/login');
      }
    });
  }, [dispatch, history]);

  useEffect(() => {
    if (!loading && !error && isAuth) {
      dispatch(setIsDesktopLoading(false));
    } else if (!loading && error) {
      history.push('/login');
      dispatch(setIsDesktopLoading(false));
    }
  }, [dispatch, error, history, isAuth, loading]);

  return (
    <div className="wrap-auth-preloader">
      <h1 className="container-auth-preloader">
        <img src={Logo} alt="logo" className="logo-auth-preloader" />
      </h1>
    </div>
  );
};

export default Preloader;
