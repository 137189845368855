import React, { useEffect, useMemo, useState } from 'react';

import parse from 'html-react-parser';
import { t } from 'i18next';
import moment from 'moment';
import paginationFactory from 'react-bootstrap-table2-paginator';
import BootstrapTable from 'react-bootstrap-table-next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { Button, Card, CardBody } from 'reactstrap';

import { getAccessSelector, getRoleSelector } from '../../store/auth/profile/selectors';
import { getAllNotifications, getNotifications } from 'store/actions';

import CustomDropdown from './Admin/CustomDropdown/CustomDropdown';
import DeleteModalNotification from './Admin/DeleteModal/DeleteModalNotification';

const paginationOptions = {
  paginationPosition: 'top',
};

function headerFormatter(column, colIndex, { sortElement }) {
  return (
    <div className="head-column">
      <p>{column.text}</p>
      {sortElement}
    </div>
  );
}

const Notifications = () => {
  const language = useSelector((state) => state.Layout.language);
  const adminNotifications = useSelector((state) => state.Notifications.adminNotifications);
  const notifications = useSelector((state) => state.Notifications.notifications);
  const dispatch = useDispatch();
  const role = useSelector(getRoleSelector);
  const isAdminAccess = useMemo(
    () => role?.access?.includes('notifications') && role?.name === 'admin',
    [role],
  );
  const notificationsList = isAdminAccess ? adminNotifications : notifications;
  const history = useHistory();
  const [deleteModal, setDeleteModal] = useState({
    isOpen: false,
    selectedItem: null,
  });

  const sortedNotifications = React.useMemo(() => {
    if (!notificationsList) {
      return [];
    }
    return [...notificationsList].sort((a, b) =>
      moment(b.notification_date).diff(moment(a.notification_date)),
    );
  }, [notificationsList]);

  const editClickHandler = (id) => {
    history.push(`/notifications/edit/${id}`);
  };
  const deleteModalOpenHandler = (id) => {
    setDeleteModal({ isOpen: true, selectedItem: id });
  };

  const closeDeleteModal = () => {
    setDeleteModal((prevState) => ({ ...prevState, isOpen: false }));
  };

  const columns = [
    {
      dataField: 'notifications_type',
      text: t('notification_type'),
      sort: true,
      headerFormatter,
      attrs: {
        'data-label': t('notification_type'),
      },
      formatter: (row) => {
        return <div>{row}</div>;
      },
    },
    {
      dataField: 'notification_title',
      text: t('notification_item_title'),
      sort: true,
      headerFormatter,
      attrs: {
        'data-label': t('notification_item_title'),
      },
      formatter: (row) => <p>{row}</p>,
    },

    {
      dataField: 'notification_text',
      text: t('notification_text'),
      sort: true,
      attrs: {
        'data-label': t('notification_text'),
      },
      headerFormatter,
      formatter(row) {
        return <p>{parse(String(row) || '')}</p>;
      },
    },
    {
      dataField: 'notification_date',
      text: t('notification_date'),
      sort: true,
      headerFormatter,
      attrs: {
        'data-label': t('notification_date'),
      },
    },
    ...(isAdminAccess
      ? [
          {
            dataField: 'delivery',
            text: t('notification_delivery_type'),
            sort: true,
            headerFormatter,
            attrs: {
              'data-label': t('notification_delivery_type'),
            },
            formatter(_cell, row) {
              const displayIsSite = row.isSite ? t('common_site') : '';
              const displayIsEmail = row.isEmail
                ? row.isSite
                  ? `, ${t('common_mail')}`
                  : t('common_mail')
                : '';
              return (
                <>
                  {displayIsSite}
                  {displayIsEmail}
                </>
              );
            },
          },
          {
            dataField: 'buttons',
            text: '',
            sort: false,
            formatter: (_, row) => {
              return (
                <CustomDropdown
                  editClickHandler={() => editClickHandler(row.id)}
                  deleteClickHandler={() => deleteModalOpenHandler(row.id)}
                />
              );
            },
          },
        ]
      : []),
  ];

  useEffect(() => {
    if (isAdminAccess) {
      dispatch(getAllNotifications({ locale: language?.toLowerCase() || 'en' }));
    } else {
      dispatch(getNotifications({ locale: language?.toLowerCase() || 'en' }));
    }
  }, [dispatch, isAdminAccess, language]);

  const notificationsData = useMemo(() => {
    return sortedNotifications.map(
      ({
        id,
        notification_date,
        notifications_type,
        notification_title,
        notification_text,
        isSite,
        isEmail,
      }) => ({
        id,
        notification_date: moment(notification_date).format('DD-MM-YYYY , hh : mm '),
        notifications_type: notifications_type?.title || '',
        notification_title,
        notification_text,
        isSite: isSite,
        isEmail: isEmail,
      }),
    );
  }, [sortedNotifications]);

  useEffect(() => {
    localStorage.removeItem('editNotificationId');
  }, []);

  return (
    <Card>
      <CardBody>
        {!!isAdminAccess && (
          <div className="text-end">
            <Button color="primary" className="notification__add_btn">
              <Link className="button_a" to={'/notifications/create'}>
                {t('notification_create_notification')}
              </Link>
            </Button>
          </div>
        )}
        <div className="mt-4">
          <BootstrapTable
            options={paginationOptions}
            keyField="id"
            data={notificationsData}
            columns={columns}
            pagination={paginationFactory(paginationOptions)}
            headerClasses="table-head"
            wrapperClasses="table-responsive without__padding table text-start table__adaptive_bordered_between"
            responsive
          />
        </div>
      </CardBody>
      <DeleteModalNotification
        closeModal={closeDeleteModal}
        isOpen={deleteModal.isOpen}
        selectedItemId={deleteModal.selectedItem}
      />
    </Card>
  );
};

export default Notifications;
