import React from 'react';

import Pdf from 'assets/GCRYPTON.pdf';
import { Trans } from 'react-i18next';
import { Col, Container, Row } from 'reactstrap';

const Footer = () => {
  return (
    <React.Fragment>
      <footer className="footer">
        <Container fluid={true}>
          <Row>
            <Col md={12}>
              <Trans
                i18nKey="footer_text"
                components={{
                  firstLink: <a href={Pdf} target="_blank" style={{ color: '#0c958f' }} />,
                  secondLink: (
                    <a href="https://enreg.reestri.gov.ge" style={{ color: '#0c958f' }} />
                  ),
                }}
              />
            </Col>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  );
};

export default Footer;
