import React, { useEffect } from 'react';

import classNames from 'classnames';
import { formatNumber } from 'helpers/formatNumber';
import useTranslation from 'hooks/useTranslation';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { getInvestedByTypesAnalytics } from 'store/team/actions';

import EmptyState from '../../../../../components/EmptyState';

import s from '../AnalysisModal.module.scss';
import { CustomTooltip } from '../index';
import CustomLegend from './CustomLegend';

const InvestedByTypesAnalytics = ({ referralId, activeYear }) => {
  const t = useTranslation();
  const dispatch = useDispatch();
  const investedByTypesAnalytics = useSelector((state) => state.Team.investedByTypesAnalytics);
  const investedByTypesAnalyticsLoading = useSelector(
    (state) => state.Team.investedByTypesAnalyticsLoading,
  );
  const investChartData = investedByTypesAnalytics?.map((item) => ({
    structureInvestment: Number(item.structure_investments),
    personalInvestment: Number(item.personal_investments),
    passiveInvestment: Number(item.passive_investments),
    name: moment(item.date).format('DD.MM.YYYY'),
  }));

  useEffect(() => {
    dispatch(getInvestedByTypesAnalytics(referralId, activeYear));
  }, [activeYear, dispatch, referralId]);

  return (
    <>
      <div className={s.chartWrapper}>
        {!!investChartData?.length ? (
          <ResponsiveContainer className={s.chart}>
            <LineChart
              data={investChartData}
              margin={{
                top: 5,
                right: 30,
                left: -10,
                bottom: 5,
              }}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip content={CustomTooltip} />
              <Legend
                wrapperStyle={{
                  display: 'flex',
                  justifyContent: 'center',
                  width: '-webkit-fill-available',
                  left: 'unset',
                }}
                content={CustomLegend}
              />
              <Line
                type="monotone"
                dataKey="personalInvestment"
                stroke="#556EE6"
                activeDot={{ r: 8 }}
              />
              <Line type="monotone" dataKey="structureInvestment" stroke="#F1B44C" />
              <Line type="monotone" dataKey="passiveInvestment" stroke="#EB2F96" />
            </LineChart>
          </ResponsiveContainer>
        ) : (
          <EmptyState />
        )}
      </div>
      <div className={classNames(s.tableWrapper, s.paddingNull)}>
        <div className={classNames(s.table, s.byTypeTable)}>
          <div className={classNames(s.row, s.long)}>
            <div className={s.item}>Date</div>
            <div className={s.item}>
              <span className={classNames(s.circle, s.blue)} />{' '}
              <div className={s.label}>{t('personalInvestment')}</div>
            </div>
            <div className={s.item}>
              <span className={classNames(s.circle, s.yellow)} />
              <div className={s.label}>{t('structureInvestment')}</div>
            </div>
            <div className={s.item}>
              <span className={classNames(s.circle, s.pink)} />
              <div className={s.label}>{t('passiveInvestment')}</div>
            </div>
            <div className={classNames(s.item, s.highlighting)}>
              <div className={s.label}>{t('common_summary')}</div>
            </div>
          </div>
          {!!investedByTypesAnalytics?.length ? (
            investedByTypesAnalytics?.map((item, i) => (
              <div className={classNames(s.row, s.long)} key={`${i}_${item.date}`}>
                <div className={classNames(s.item, s.bold)}>
                  {moment(item.date).format("MMM 'YY")}
                </div>
                <div className={s.item}>{formatNumber(item.personal_investments)}</div>
                <div className={s.item}>{formatNumber(item.structure_investments)}</div>
                <div className={s.item}>{formatNumber(item.passive_investments)}</div>
                <div className={classNames(s.item, s.highlighting)}>
                  {formatNumber(item?.total_investments, 2)}
                </div>
              </div>
            ))
          ) : (
            <EmptyState />
          )}
        </div>
      </div>
      {investedByTypesAnalyticsLoading && (
        <div className={s.loaderWrapper}>
          <span className={s.loader}></span>
        </div>
      )}
    </>
  );
};

export default InvestedByTypesAnalytics;
