import React from 'react';

import { t } from 'i18next';

import Icon from '../../../Icon';
import s from './ExpiredStep.module.scss';

const ExpiredStep = () => {
  return (
    <div className={s.wrapper}>
      <Icon name="expired" width={120} height={120} />

      <div className={s.title}>{t('time_expired')}</div>
      <div className={s.text}>{t('time_expired_text')}</div>
    </div>
  );
};

export default ExpiredStep;
