import React, { useCallback, useEffect, useRef, useState } from 'react';

import { ReactComponent as ArrowDown } from 'assets/images/icons/arrow.svg';

import s from './Multiselect.module.scss';

const Multiselect = (props) => {
  const { options, defaultSelected, placeholder, onChange = () => {} } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);

  const multiselectRef = useRef(null);

  useEffect(() => {
    setSelectedOptions(defaultSelected || []);
  }, [defaultSelected]);

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionSelect = useCallback(
    (option) => {
      if (selectedOptions.includes(option.value)) {
        setSelectedOptions((prev) => {
          const res = prev.filter((item) => item !== option.value);
          onChange(res);

          return prev.filter((item) => item !== option.value);
        });
      } else {
        setSelectedOptions((prev) => {
          const res = [...prev, option.value];
          onChange(res);

          return [...prev, option.value];
        });
      }
    },
    [selectedOptions],
  );

  const handleClickOutside = (event) => {
    if (multiselectRef.current && !multiselectRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  return (
    <div ref={multiselectRef} className={s.multiselectContainer}>
      <div className={s.selectedOptions} onClick={toggleDropdown}>
        {placeholder}
        <span className={isOpen ? s.arrowUp : s.arrowDown}>
          <ArrowDown color="#8c9197" />
        </span>
      </div>
      {isOpen && (
        <div className={s.optionsContainer}>
          {options.map((option, index) => (
            <label key={index} className={s.optionLabel}>
              <input
                type="checkbox"
                className={s.optionCheckbox}
                checked={selectedOptions.includes(option.value)}
                onChange={() => handleOptionSelect(option)}
              />
              {option.label}
            </label>
          ))}
        </div>
      )}
    </div>
  );
};

export default Multiselect;
