import React, { useCallback, useEffect, useState } from 'react';

import { ReactComponent as CloseIcon } from 'assets/images/icons/close.svg';
import classNames from 'classnames';
import useTranslation from 'hooks/useTranslation';
import BootstrapTable from 'react-bootstrap-table-next';
import { Modal } from 'reactstrap';

import { fetchAccurals } from 'services/accrualsService';

import { columns } from '../../../DepositAccruals/columns';
import s from './DepositDetailsModal.module.scss';

const DepositDetailsModal = ({ isOpenId, setIsOpenId }) => {
  const t = useTranslation();
  const [items, setItems] = useState([]);

  const handleClose = useCallback(() => {
    setItems([]);
    setIsOpenId('');
  }, [setIsOpenId]);

  const getDepositDetails = useCallback(async () => {
    const res = await fetchAccurals({
      type: 'deposit',
      filters: {
        product_id: isOpenId,
      },
      orderBy: 'payment_date',
      order: 'ASC',
    });

    if (!!res.total_items_count) {
      setItems(res.items);
    } else {
      setItems([]);
    }
  }, [isOpenId]);

  useEffect(() => {
    if (isOpenId) {
      getDepositDetails();
    }
  }, [getDepositDetails, isOpenId]);

  return (
    <Modal
      isOpen={!!isOpenId}
      centered
      className={classNames(!isOpenId && 'd-none')}
      toggle={handleClose}
      size="xl">
      <div className={s.wrapper}>
        <div className={s.header}>
          <div className={s.title}>Accruals #{isOpenId}</div>
          <div onClick={handleClose} className={s.close}>
            <CloseIcon />
          </div>
        </div>
        {!!items && (
          <BootstrapTable
            keyField="id"
            data={items}
            columns={columns(t, () => {})}
            headerClasses="table-head"
            wrapperClasses="table-responsive"
            classes="table__adaptive_bordered_between text-center"
            responsive
          />
        )}
      </div>
    </Modal>
  );
};

export default DepositDetailsModal;
