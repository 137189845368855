import { ReactComponent as CopyIcon } from './copy.svg';
import { ReactComponent as DropdownIcon } from './dropdown.svg';
import { ReactComponent as ExpiredIcon } from './expired.svg';
import { ReactComponent as LogoutIcon } from './logout.svg';
import { ReactComponent as MenuIcon } from './menu.svg';
import { ReactComponent as ProfileIcon } from './profile.svg';
import { ReactComponent as ResizeIcon } from './resize.svg';
import { ReactComponent as SupportIcon } from './support.svg';

const commonIcons = {
  dropdown: DropdownIcon,
  logout: LogoutIcon,
  menu: MenuIcon,
  profile: ProfileIcon,
  resize: ResizeIcon,
  copy: CopyIcon,
  expired: ExpiredIcon,
  support: SupportIcon,
};

export default commonIcons;
