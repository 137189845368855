import { API_FAIL, API_SUCCESS } from '../common/actionTypes';
import {
  GET_INVESTED_BY_TYPES_ANALYTICS,
  GET_INVESTED_FUNDS_ANALYTICS,
  GET_INVESTED_FUNDS_ANALYTICS_DETAILED,
  GET_INVITED_USERS_ANALYTICS,
  GET_PARTNERS,
  GET_PARTNERS_INCOME,
  GET_PARTNERS_REFERRALS_TREE,
  GET_TEAM_INFO,
  GET_USER_REFERRALS,
  SET_DETAILS_INFO,
  SET_IS_OPENED_REFERRALS_TREE,
  SET_PARTNERS_INCOME_RANGE,
  SET_PARTNERS_REFERRALS_TREE,
  SET_USER_REFERRALS,
} from './actionTypes';

const INIT_STATE = {
  teamInfo: {
    partnerId: '',
    totalReferrals: 0,
    teamIncome: 0,
    referralsIncome: 0,
    firstDeposit: 0,
    firstReferrals: 0,
    firstReferralsIncome: 0,
    teamDeposit: 0,
  },
  partnersIncome: Array.from({ length: 6 }, (_, key) => [
    key,
    {
      referred: 0,
      income: 0,
      dateFrom: new Date(2022, 0, 1),
      dateTo: new Date(2022, 11, 31),
    },
  ]).reduce((acc, [key, value]) => {
    acc[key] = value;
    return acc;
  }, {}),
  partners: [],
  partnersReferralsTree: [],
  invitedUsersAnalytics: [],
  investedFundsAnalytics: [],
  investedFundsAnalyticsDetails: [],
  investedByTypesAnalytics: [],
  investedFundsAnalyticsLoading: false,
  invitedUsersAnalyticsLoading: false,
  investedByTypesAnalyticsLoading: false,
  partnersReferralsTreeLoading: false,
  detailsInfo: {
    photo: false,
    investInfo: false,
  },
  userReferrals: [],
  isOpenedReferralsTree: false,
};

const Team = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_PARTNERS_INCOME_RANGE:
      return {
        ...state,
        partnersIncome: {
          ...state.partnersIncome,
          [action.level]: {
            ...state.partnersIncome[action.level],
            dateFrom: action.from,
            dateTo: action.to,
          },
        },
      };
    case GET_PARTNERS_REFERRALS_TREE:
      return {
        ...state,
        partnersReferralsTreeLoading: true,
      };
    case SET_DETAILS_INFO:
      return {
        ...state,
        detailsInfo: action.payload,
      };
    case SET_USER_REFERRALS:
      return {
        ...state,
        userReferrals: action.payload.data,
        // partnersReferralsTree: [],
      };
    case SET_PARTNERS_REFERRALS_TREE:
      return {
        ...state,
        partnersReferralsTree: [],
      };
    case SET_IS_OPENED_REFERRALS_TREE:
      return {
        ...state,
        isOpenedReferralsTree: action.payload,
      };
    case GET_INVESTED_FUNDS_ANALYTICS:
      return {
        ...state,
        investedFundsAnalyticsLoading: true,
      };
    case GET_INVITED_USERS_ANALYTICS:
      return {
        ...state,
        invitedUsersAnalyticsLoading: true,
      };
    case GET_INVESTED_BY_TYPES_ANALYTICS:
      return {
        ...state,
        investedByTypesAnalyticsLoading: true,
      };
    case API_SUCCESS:
      switch (action.payload.actionType) {
        case GET_TEAM_INFO:
          return {
            ...state,
            teamInfo: action.payload.data,
          };
        case GET_PARTNERS_INCOME:
          return {
            ...state,
            partnersIncome: {
              ...state.partnersIncome,
              [action.payload.level]: {
                ...state.partnersIncome[action.payload.level],
                ...action.payload.data,
              },
            },
          };
        case GET_PARTNERS:
          return {
            ...state,
            partners: action.payload.data,
          };
        case GET_PARTNERS_REFERRALS_TREE:
          return {
            ...state,
            partnersReferralsTree: action.payload.data,
            partnersReferralsTreeLoading: false,
            userReferrals: [],
          };
        case GET_INVITED_USERS_ANALYTICS:
          return {
            ...state,
            invitedUsersAnalytics: action.payload.data,
            invitedUsersAnalyticsLoading: false,
          };
        case GET_INVESTED_FUNDS_ANALYTICS_DETAILED:
          return {
            ...state,
            investedFundsAnalyticsDetails: action.payload.data,
          };
        case GET_INVESTED_FUNDS_ANALYTICS:
          return {
            ...state,
            investedFundsAnalytics: action.payload.data,
            investedFundsAnalyticsLoading: false,
          };
        case GET_INVESTED_BY_TYPES_ANALYTICS:
          return {
            ...state,
            investedByTypesAnalytics: action.payload.data,
            investedByTypesAnalyticsLoading: false,
          };
        case GET_USER_REFERRALS:
          return {
            ...state,
            userReferrals: action.payload.data,
          };
        default:
          return state;
      }
    case API_FAIL:
      switch (action.payload.actionType) {
        case GET_TEAM_INFO:
          return {
            ...state,
            teamInfoError: action.payload.error,
          };
        case GET_PARTNERS:
          return {
            ...state,
            partnersError: action.payload.error,
          };
        case GET_PARTNERS_REFERRALS_TREE:
          return {
            ...state,
            partnersReferralsTreeError: action.payload.error,
            partnersReferralsTreeLoading: false,
          };
        case GET_INVITED_USERS_ANALYTICS:
          return {
            ...state,
            invitedUsersAnalyticsError: action.payload.error,
          };
        case GET_INVESTED_FUNDS_ANALYTICS:
          return {
            ...state,
            investedFundsAnalyticsError: action.payload.error,
            investedFundsAnalyticsLoading: false,
          };
        case GET_INVESTED_FUNDS_ANALYTICS_DETAILED:
          return {
            ...state,
            investedFundsAnalyticsDetailsError: action.payload.error,
          };
        case GET_INVESTED_BY_TYPES_ANALYTICS:
          return {
            ...state,
            investedByTypesAnalyticsError: action.payload.error,
          };
        case GET_USER_REFERRALS:
          return {
            ...state,
            userReferralsError: action.payload.error,
          };
        default:
          return state;
      }
    default:
      return state;
  }
};

export default Team;

export const getTeamInfoSelector = (state) => state.Team.teamInfo;
