import React, { useRef } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';

import { MetaTags } from 'react-meta-tags';
import { useParams, withRouter } from 'react-router-dom';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';

import { getUserStructure } from 'services/teamService';

import AllStructureInfo from 'pages/Team/components/AllStructureInfo';
import FirstStructureInfo from 'pages/Team/components/FirstStructureInfo';

import Breadcrumbs from '../../components/Common/Breadcrumb';
import PaginationComponent from '../../components/PaginationComponent';
import ReferralsTree from '../../components/ReferralsTree';
import List from '../Team/components/List';

import { useDebounce } from '../../hooks/useDebounce';
import { t } from '../../i18n';

const TeamStructure = () => {
  const [referrals, setReferrals] = useState({});
  const [queryString, setQueryString] = useState('');
  const [pagination, setPagination] = useState({});
  const [sort, setSort] = useState({ field: 'created_at', order: 'desc' });
  const debounceQueryString = useDebounce(queryString, 500);
  const [teamInfo, setTeamInfo] = useState({
    firstDeposit: '0',
    firstReferrals: 0,
    firstReferralsIncome: '0',
    referralsIncome: '0',
    teamDeposit: '0',
    totalReferrals: 0,
  });
  const {
    firstDeposit,
    firstReferrals,
    firstReferralsIncome,
    referralsIncome,
    teamDeposit,
    totalReferrals,
  } = teamInfo;

  const [userData, setUserData] = useState({
    fullName: '',
    partnerId: '',
    avatarUrl: '',
    refLevel: 0,
    personal_investments: 0,
  });
  const [error, setError] = useState(null);
  const { partnerId } = useParams();
  const treeRef = useRef(null);

  useEffect(() => {
    if (pagination?.pageNumber) {
      const fetchUserStructure = async () => {
        try {
          const data = await getUserStructure({
            partnerId,
            take: pagination?.size,
            skip: (pagination?.pageNumber - 1) * pagination?.size,
            search_str:
              debounceQueryString?.charAt(0) === '+'
                ? `%2B${debounceQueryString.slice(1)}`
                : debounceQueryString,
            order_by: sort?.field,
            order: sort?.order?.toUpperCase(),
          });

          setUserData({
            fullName: data?.structure.fullName,
            partnerId: data?.structure.partnerId,
            avatarUrl: data?.structure.avatar_url || '',
            refLevel: data?.structure.ref_level || 0,
            personal_investments: data?.structure.personal_investments || 0,
          });
          setReferrals({
            structure: data?.structure.referrals,
            total_pages_count: data?.total_pages_count,
          });
          setTeamInfo(data?.structure.teamInfo);
        } catch (error) {
          if (error.response.status === 403) {
            setError(t('common_access_denied'));
          }
        }
      };
      fetchUserStructure();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination, debounceQueryString, sort]);

  return (
    <div className="page-content">
      <MetaTags>
        <title>{t('partner_structure_meta_title')}</title>
      </MetaTags>
      <Container fluid>
        {!error && (
          <>
            <Breadcrumbs
              title={t('partner_structure_title')}
              hasBreadcrumbItem={false}
              breadcrumbItem={t('partner_structure_title_with_name', {
                fullName: userData.fullName,
              })}
            />
            <Row>
              <Col lg={6}>
                <AllStructureInfo
                  totalReferrals={totalReferrals}
                  referralsIncome={referralsIncome}
                  teamDeposit={teamDeposit}
                />
              </Col>
              <Col lg={6}>
                <FirstStructureInfo
                  firstDeposit={firstDeposit}
                  firstReferrals={firstReferrals}
                  firstReferralsIncome={firstReferralsIncome}
                />
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <ReferralsTree
                  currentUser={{
                    fullName: userData.fullName,
                    partnerId: userData.partnerId,
                    photo: {
                      path: userData?.avatarUrl,
                    },
                    level: userData?.refLevel,
                    structure_investments: Number(teamDeposit),
                    personal_investments: userData?.personal_investments,
                  }}
                  referralsCount={totalReferrals}
                  ref={treeRef}
                />
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <Card>
                  <CardBody>
                    <List
                      fullName={userData.fullName}
                      partners={referrals?.structure || []}
                      userPartnerId={userData.partnerId}
                      searchValue={queryString}
                      setSearchValue={setQueryString}
                      setSort={setSort}
                      treeRef={treeRef}
                      isHideFirstLineCheckbox
                    />
                    <PaginationComponent
                      active={pagination?.pageNumber}
                      setPagination={setPagination}
                      total={referrals?.total_pages_count}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </>
        )}
        {error && <h5>{error}</h5>}
      </Container>
    </div>
  );
};

export default withRouter(TeamStructure);
