import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';

import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { Button, Modal } from 'reactstrap';

import userService from 'services/userService';
import { setAgreement } from 'store/actions';

import { t } from '../../i18n';

const AgreementModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const agreement = useSelector((state) => state.Profile.user?.agreement ?? null);
  const isSetDefaultWalletOpened = useSelector((state) => state.Profile.isSetDefaultWalletOpened);

  const submitHandler = (value) => async () => {
    await userService.setAgreement(value);
    dispatch(setAgreement(value));
    setIsOpen(false);
  };

  useEffect(() => {
    if (agreement === 2 && !isSetDefaultWalletOpened) {
      setIsOpen(true);
    }
    if (isOpen && isSetDefaultWalletOpened) setIsOpen(false);
  }, [agreement, isOpen, isSetDefaultWalletOpened]);

  return (
    <Modal isOpen={isOpen} centered className={`${!isOpen ? 'd-none' : ''}`}>
      <div className="p-4 text-center">
        <h5>{t('agreement_modal_title')}</h5>
        <div className="d-flex gap-2 justify-content-center mt-4">
          <Button color="danger" className="px-k" onClick={submitHandler(0)}>
            {t('agreement_modal_disagree')}
          </Button>
          <Button color="primary" className="px-4" onClick={submitHandler(1)}>
            {t('agreement_modal_agree')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default AgreementModal;
