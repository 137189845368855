import arFlag from '../assets/images/flags/ar.png';
import frFlag from '../assets/images/flags/french.jpg';
import deFlag from '../assets/images/flags/germany.jpg';
import hiFlag from '../assets/images/flags/hi.png';
import itFlag from '../assets/images/flags/italy.jpg';
import kaFlag from '../assets/images/flags/ka.png';
import kkFlag from '../assets/images/flags/kk.png';
import russia from '../assets/images/flags/russia.jpg';
import esFlag from '../assets/images/flags/spain.jpg';
import trFlag from '../assets/images/flags/tr.png';
import usFlag from '../assets/images/flags/us.jpg';
import uzFlag from '../assets/images/flags/uz.png';
import zhFlag from '../assets/images/flags/zh.png';

const getLanguages = (t) => ({
  en: {
    label: t('languages.en'),
    flag: usFlag,
  },
  de: {
    label: t('languages.de'),
    flag: deFlag,
  },
  ru: {
    label: t('languages.ru'),
    flag: russia,
  },
  tr: {
    label: t('languages.tr'),
    flag: trFlag,
  },
  zh: {
    label: t('languages.zh'),
    flag: zhFlag,
  },
  ka: {
    label: t('languages.ka'),
    flag: kaFlag,
  },
  fr: {
    label: t('languages.fr'),
    flag: frFlag,
  },
  es: {
    label: t('languages.es'),
    flag: esFlag,
  },
  it: {
    label: t('languages.it'),
    flag: itFlag,
  },
  kk: {
    label: t('languages.kk'),
    flag: kkFlag,
  },
  ar: {
    label: t('languages.ar'),
    flag: arFlag,
  },
  hi: {
    label: t('languages.hi'),
    flag: hiFlag,
  },
  uz: {
    label: t('languages.uz'),
    flag: uzFlag,
  },
});

export default getLanguages;
