import React from 'react';

import { ReactComponent as EmptyIcon } from 'assets/images/icons/empty.svg';
import cn from 'classnames';

import useTranslation from '../../hooks/useTranslation';

const EmptyState = ({ withBg = false }) => {
  const t = useTranslation();

  return (
    <div className={cn('empty-state-wrapper', withBg && 'empty-state-with-bg')}>
      <EmptyIcon />
      <p className="empty-state__text">{t('common_data_not_found')}</p>
    </div>
  );
};

export default EmptyState;
