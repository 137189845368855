import { useCallback, useEffect, useState } from 'react';

import { get } from 'helpers/api_helper';
import useTranslation from 'hooks/useTranslation';
import BootstrapTable from 'react-bootstrap-table-next';

import { projectsTable } from '../MetricsContstants';

const ProjectsMetricsTable = ({ filterBy }) => {
  const t = useTranslation();
  const [projectMetrics, setProjectMetrics] = useState({});

  const handleProjectMetrics = useCallback(async () => {
    const response = await get(`metrics/projects?filter_by=${filterBy}`);
    setProjectMetrics(response);
  }, [filterBy]);

  useEffect(() => {
    handleProjectMetrics();
  }, [handleProjectMetrics]);

  if (!projectMetrics?.length) return null;

  return (
    <div>
      <BootstrapTable
        keyField="id"
        responsive
        classes={'table table-bordered th__pointer table__adaptive_bordered_between'}
        headerWrapperClasses={'thead-light table-head'}
        data={projectMetrics}
        columns={projectsTable(t)}
      />
    </div>
  );
};

export default ProjectsMetricsTable;
