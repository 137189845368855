export const EDIT_PROFILE = 'EDIT_PROFILE';
export const PROFILE_SUCCESS = 'PROFILE_SUCCESS';
export const PROFILE_ERROR = 'PROFILE_ERROR';
export const RESET_PROFILE_FLAG = 'RESET_PROFILE_FLAG';
export const SET_PROFILE_DATA = 'SET_PROFILE_DATA';
export const RESET_PROFILE_DATA = 'RESET_PROFILE_DATA';
export const EDIT_DEFAULT_WALLET_NUMBER = 'EDIT_DEFAULT_WALLET_NUMBER';
export const SET_AGREEMENT = 'SET_AGREEMENT';
export const FETCH_PROFILE_DATA = 'FETCH_PROFILE_DATA';

export const IS_SET_DEFAULT_WALLET_OPENED = 'IS_SET_DEFAULT_WALLET_OPENED';
